import React from 'react';
import styles from './Product.module.css';

const Product = ({ name, image, price, addToCart }) => {
  return (
    <div className={styles.product}>
      <h3>{name}</h3>
      <img src={image} alt={name} className={styles.image} />
      <p>{price}</p>
      <button onClick={addToCart} className={styles.addButton}>Add to Cart</button>
    </div>
  );
};

export default Product;
