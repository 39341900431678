import React from "react";
import style from "./Learn.module.css"
import Button from "../button/button";
const Learn = (props) =>{
    return(
        <div className={`pt50 pb50 ${style.learnDis}`}>
             <div className={style.learnImage}>
                <img src={props.image}/>
             </div>
             <div className={style.learnContent}>
                <h2>
                 {props.title}
                </h2>
                <p>
                   {props.description}
                </p>
                {props.options.map((item,i) =>{
                    return(
                        <div key={i}>
                        <img src={props.tick}/>
                        <p>{item.option}</p>
                    </div>
                    )
                })}
              
                <Button dynamicClass={props.color} text={props.text}    link={props.link} />
             </div>
        </div>
    )
}

export default Learn;