import React from "react";
import style from "./SelectPlans.module.css"
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { Helmet } from "react-helmet";
const SelectPlans = () =>{
    const box = [
        {
            bigImage:"/image/bigCub.webp",
            changeBck:"changeCub",
            labelOne:"3-5 Year Olds",
            labelTwo:"Designed for Preschoolers",
            title:"Spark a Love for Learning at a Young Age!",
            description:"Your preschooler will learn about ABCs, number sense, social skills, life skills and a lot about animals through PLAY!",
            linkOne:"/cub-plan",
            linkTwo:"/cub-club",
            icon:"/image/icon/cubb.svg",
            textIcon:"Cub Club",
        },
        {
            bigImage:"/image/bigZoo.webp",
            changeBck:"changeZoo",
            labelOne:"5-12 Year Olds",
            labelTwo:"",
            title:"The #1 Zoology Subscription for Kids!",
            description:"Fuel their love of animals and learning with STEM and life skill focused hands-on activities and FUN!",
            linkOne:"/zoologist-plan",
            linkTwo:"/zoologist-club",
            icon:"/image/icon/zooo.svg",
            textIcon:"Zoologist Club",
        },
    ]
    return(
        <div className="container">
               <Helmet>
        <title> Kids’ Zoology Subscription Boxes | My ZOO Box</title>
        <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
      </Helmet>
           <div className={style.bigBoxDis}>
            {box.map((item) =>{
                return(
                    <div className={style.bigBoxes}>
                        <div className={`${style.labelTop} ${style[item.changeBck]}`}>
                            <img src={item.icon}/>
                            <p>{item.textIcon}</p>
                            </div>
                    <img src={item.bigImage} className={style.mainImage}/>
                    <div className={`${style.bigBoxesContent }  ${style[item.changeBck]}`}>
                        <div className={style.bigBoxesLabel}>   <span>{item.labelOne}</span>{item.labelTwo && <span>{item.labelTwo}</span>}</div>
                        <h2>{item.title} </h2>
                       <p>{item.description}</p>
                       <div className={style.bigBoxButton}>
                           
                           <Link to={item.linkOne} onClick={() => animateScroll.scrollToTop()}>Get Started <img src="/image/icon/arrow.svg"/></Link>
                           
   
                         
                           <Link to={item.linkTwo} onClick={() => animateScroll.scrollToTop()}>Learn More  <img src="/image/icon/arrow.svg"/></Link>
                          
                               
                         
                       </div>
                    </div>
               </div>
                )
            })}
       
          
           </div>
        </div>
    )
}

export default SelectPlans;