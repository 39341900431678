import React, { useState } from "react";
import style from "./StepForm.module.css"


const StepName = (props) =>{
    const [childName, setChildName] = useState(props.data.childName);
    const [childLastName, setchildLastName] = useState(props.data.childSurname);
    const [selectedLabelId, setSelectedLabelId] = useState(props.data.clubSelect);
    const [childNameError, setChildNameError] = useState("");
    const [tab,setTab] = useState(false)
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "childName") {
          setChildName(value);
          props.updateAddName(value); 
          console.log("props.dataasd1",value)
        } else if (name === "childLastName") {
          setchildLastName(value);
          console.log("props.dataasd12",value)
        }
      };
  
      const validateForm = () => {
 
        let isValid = true;
      
        if (childName === "" && childLastName === "") {
            setChildNameError("Please enter a childName");
          isValid = false;
        } else if( childName === "" ) {
            setChildNameError("First name is a required field");
        } else if( childLastName === "" ) {
            setChildNameError("Last name is a required field");
        }else {
            setChildNameError("");
            return isValid ;
        }
    
     
      };
    
      const handleContinueClick = () => {
 

        if (validateForm()) {
            props.data.childName = childName
            props.data.childSurname = childLastName
          
      setTab(true)
        console.log("aaa")
        }
      };

      const handleLabelClick = (id) => {
        setSelectedLabelId(id);
        props.data.clubSelect = id
        props.next()
        
    };

    return(
        <>
        <img src="/image/koala.png" className={style.koala}/>
        
          <div className={style.stepsHeading}>
              <h2>Who is this for?</h2>
              <p>EDventures are addressed to the child or recipient.</p>
          </div>
          <div className={style.StepOneInput}>
             <div>
                <input type="text" name="childName" id="childName" placeholder="First Name*"  value={childName} onChange={handleInputChange}/>
                <input type="text" name="childLastName" placeholder="Last Name*" value={childLastName} onChange={handleInputChange}/>
             </div>
             <span className={style.error}>{childNameError}</span>
         {!tab &&  <p className={style.stepsBtn} onClick={handleContinueClick}>Next</p>}   
             <span><b>Buying for multiple children?</b> You'll be able to add them later.</span>

             
          </div>
         {tab && 
          <div className={style.stepClub}>
             <div className={style.stepsHeading}>
              <h2>How old is {props.data.childName}</h2>
              <p>We'll recommend the most age-appropriate EDventures!</p>
             </div>
             <div className={style.cubDis}>
                <label htmlFor="cubclub"  onClick={() => handleLabelClick("cubclub")}>
                    <input type="radio" name="cub" id="cubclub"/>
                    <img src="/image/cub.png"/>
                </label>
                <label htmlFor="zooclub"  onClick={() => handleLabelClick("zooclub")}>
                    <input type="radio" name="cub" id="zooclub"/>
                    <img src="/image/zoo.png"/>
                </label>
               
             </div>
             </div>
         }
        </>
    )
}

export default StepName