import React, { useRef, useState } from "react";
import style from "./Faq.module.css"


const FAQ = ({data,section}) => {
    const [activeIndex, setActiveIndex] = useState();
    const answerRefs = useRef([]);
    const [showLoader, setShowLoader] = useState(true);
  
    const handleQuestionClick = (index) => {
      if (index === activeIndex) {
        setActiveIndex(null); // Close the currently open question
      } else {
        setActiveIndex(index); // Open the clicked question
      }
    };
  
  
    return(
        <>
        
 <h1 className={style.faqHeading}>FAQs</h1>
     <div className={` ${style.faqBck}`}>
 
      {data.map((q, index) => (
        <div
         
          className={`${style.faqItem} ${
            index === activeIndex ? style.active : ""
          }`}
        >
          <div
            className={style.question}
            onClick={() => handleQuestionClick(index)}
          >
            <div className={style.questionText}>{q.question}</div>
            <span
              className={`${style.arrow} ${
                index === activeIndex ? style.arrowUp : style.arrowDown
              }`}
            >
              {/* {index === activeIndex ? "▼" : "▲"} */}
            </span>
          </div>
          <div
            className={`${style.answer} ${index === activeIndex ? "open" : ""}`}
            ref={(element) => (answerRefs.current[index] = element)}
            style={{
              maxHeight:
                index === activeIndex
                  ? `${answerRefs.current[index]?.scrollHeight}px`
                  : "0",
            }}
          >
            <div className={style.answerText}>{q.answer}</div>
          </div>
        </div>
      ))}
    </div>
        </>
    )
}

export default FAQ;