import Hamburger from "hamburger-react";
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink ,useLocation} from "react-router-dom";
import { animateScroll } from "react-scroll";
import style from "./Header.module.css";
import disableScroll from "disable-scroll";
// import CountdownTimer from "../../component/countdown/CountDown";

// import { RiArrowDropDownFill } from "react-icons/ri";

const Header = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [activeLi, setActiveLi] = useState(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [isActive, setIsActive] = useState(false); // State to manage the active class
  const [isActiveTwo, setIsActiveTwo] = useState(false); // State to manage the active class

  // Function to toggle isActive state
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  const toggleActiveTwo = () => {
    setIsActiveTwo(!isActiveTwo);
  };
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos,disableScroll]);

  isOpen ? disableScroll.on() : disableScroll.off();
 
  const handleLiClick = (index) => {
    // Scroll to top
    animateScroll.scrollToTop();

    if (activeLi === index) {
      // If the clicked li is already active, close it
      setActiveLi(null);
    } else {
      // Scroll to top
      animateScroll.scrollToTop();
      // Set the clicked li as active
      setActiveLi(index);
    }
  };
  const location = useLocation();
  return (
       <>
         {!["/cookie-policy", "/terms-of-service", "/privacy-policy","/zoo-store/all"].includes(location.pathname) ? (
  <div className="offerBck">
    <div className={`offer ${isOpen ? style.changeOffer : ""}`}>
      <p>
        Get Your First Box for Free with code <span>FREEBOX</span> 
        <Link to="/first-box-free-promotion">View Promo Details</Link>
      </p>
      {/* <CountdownTimer/> */}
    </div>
  </div>
) : location.pathname === "/zoo-store/all" ? (
  <div className="offerBck offerTwo">
    <div className={`offer ${isOpen ? style.changeOffer : ""}`}>
      <p>ALL ORDERS OVER $99 QUALIFY FOR FREE SHIPPING!</p>
    </div>
  </div>
) : null}

  
   
         <div  className={`${style.header}  ${props.color} ${visible ? style.visible : style.hidden} `}>
      
      <div className={`container flex ${style.desktopHeader}`}>
        <div>
          <Link
            to="/"
            className={style.logo}
            onClick={() => animateScroll.scrollToTop()}
          >
            <img src={"/image/icon/l.webp"} alt="logo" loading="lazy" />
            
          </Link>
        </div>
        {location.pathname === "/v2" ?   
           <ul className={style.landingList}>
           <li   onClick={props.scroll} className={style.landingButton}>
            {/* <p>Start Now</p> */}
           
            Start Now
                
               
          </li> 
          
          <li onClick={() => animateScroll.scrollToTop()}>
          <a
                href="https://checkout.myzoobox.com/account/auth/login?store=my-zoo-box&redirect_url=https%3A%2F%2Fwww.myzoobox.com"
              
              >
             Login
              </a>
          </li>
           </ul>
          : 
        <ul>
          <li className={style.firstDropdown} >
            <p to="/">Subscription Clubs <img src="/image/icon/icon.svg" className={`${style.noRotate}`}/></p>
            <ul className={`${style.hoverOne} `}>
              <li  onClick={() => animateScroll.scrollToTop()}>
                <NavLink to="/cub-club">
                  <img src="/image/icon/ch1.svg" />
                  <div>
                    <p>Cub Club</p>
                    <span>3 - 5 years old</span>
                  </div>
                
                </NavLink>
              </li>
              <li  onClick={() => animateScroll.scrollToTop()}>
                <NavLink to="/zoologist-club">
                  <img src="/image/icon/ch2.svg" />
                  <div>
                    <p>Zoologist Club</p>
                    <span>5 - 12 years old</span>
                  </div>
                
                </NavLink>
              </li>
              <li  onClick={() => animateScroll.scrollToTop()} className={style.schoolCamp}>
                <NavLink to="/summer" >
                    <span>NEW!</span>
                    <h3>My Zoo Box</h3>
                    <h3>Summer Camp</h3>
                    <p>Ocean & Rainforest</p>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={style.firstDropdown} onClick={() => animateScroll.scrollToTop()}>
          <Link to="/schools"   
                onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}
                className="plans">School Orders</Link>
        
            {/* <ul className={`${style.hoverTwo} `}>
              <li  onClick={() => animateScroll.scrollToTop()}>
                <NavLink to="/plans">
                Gift a subscription
                </NavLink>
              </li>
              <li  onClick={() => animateScroll.scrollToTop()}>
                <NavLink to="/gift">
                 
                Gift guide
                </NavLink>
              </li>
              <li  onClick={() => animateScroll.scrollToTop()}>
                <NavLink to="/">
                Special requests
                
                </NavLink>
              </li>
              
            </ul> */}
            
          </li>

          <li className={`${style.firstDropdown} ${style.spookySeasonBox}`} >
            <Link to="/zoo-store">  The ZOO Store <img src="/image/icon/icon.svg" className={`${style.noRotate}`}/></Link>
            <ul className={`${style.hoverOne} `}>
              <li  onClick={() => animateScroll.scrollToTop()}>
                <NavLink to="/zoo-store/all?category=One-time+Boxes">
                EDventures

                
                </NavLink>
              </li>
              <li  onClick={() => animateScroll.scrollToTop()}>
              <NavLink to="/zoo-store/all?category=Digital+Unit+Studies">
                Digital Studies

                </NavLink>
              </li>
              {/* <li  onClick={() => animateScroll.scrollToTop()} className={style.spookySeason}>
                <NavLink to="/zoo-store/one-time-boxes?spooky-sale" >
                    <span>NEW!</span>
                    <h3>SPOOKY SALE</h3>
                   
                </NavLink>
              </li> */}
            </ul>
          </li>
          {/* <li onClick={() => animateScroll.scrollToTop()}>
            <Link to="/zoo-store">       The ZOO Store</Link>
            </li> */}
          <li   onClick={() => animateScroll.scrollToTop()} className={style.navButton}>
            {/* <p>Start Now</p> */}
            <NavLink to="/select-plans">
            Start Now
                
                </NavLink>
          </li>
          <li className={style.firstDropdown} >
            <p to="/">Member Area <img src="/image/icon/icon.svg" className={`${style.noRotate}`}/></p>
            <ul className={`${style.hoverOne} ${style.hoverSecond}`}>
              <li  onClick={() => animateScroll.scrollToTop()}>
                <NavLink to="/your-next-edventure">
                Your Next EDventure 
                </NavLink>
              </li>
              <li onClick={() => animateScroll.scrollToTop()}>
          <a
                href="https://checkout.myzoobox.com/account/auth/login?store=my-zoo-box&redirect_url=https%3A%2F%2Fwww.myzoobox.com"
              
              >
             Login
              </a>
          </li>
            </ul>
          </li>
        
        </ul>
          }
      </div>
      <div className={`${style.mobileNav} container`}>
        <div className={style.mobileDisplay}>
          <Link
            to="/"
            className={style.logo}
            onClick={() => {
              setOpen(false);
              animateScroll.scrollToTop();
            }}
          >
        
              {" "}
              <Link
            to="/"
            className={style.logo}
            onClick={() => {
              setOpen(false);
              animateScroll.scrollToTop();
            }}
          >
            <img src={"/image/icon/l.webp"} alt="logo" loading="lazy" />
            
          </Link>
         
          </Link>
          <div className={style.mobileList}>
            <div className={style.mobileHeaderData}>
              <div className={`hamMenu ${style.hamburgerMenu}`}>
                <Hamburger toggled={isOpen} toggle={setOpen} size={23} />
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
    <div
          className={`${style.mobileMenuContainer} ${isOpen && style.opened}`}
        >
          <ul className="w-100 ">
         
        
              <li onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }} className={style.mobileClubs}>
                <NavLink to="/cub-club">
                  <img src="/image/cc.png" />
                  <div>
                    <p>Cub Club</p>
                    <span>3 - 5 years old</span>
                  </div>
                
                </NavLink>
              </li>
              <li     onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }} className={style.mobileClubs}>
                <NavLink to="/zoologist-club">
                  <img src="/image/zz.png" />
                  <div>
                    <p>Zoologist Club</p>
                    <span>5 - 12 years old</span>
                  </div>
                
                </NavLink>
              </li>
              <li     onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }} 
                className={style.summerMob}>
                <NavLink to="/summer">
                 <span>NEW!</span>
                  <h3>My Zoo Box Summer Camp</h3>
                 <img src="/image/icon/bl.svg"/>
                </NavLink>
              </li>
              
               <li className={style.secondDrop} >
            <p onClick={toggleActiveTwo}>The Zoo Store <img src="/image/icon/bl.svg" className={`${style.noRotate} ${isActiveTwo ? style.activeRotate : ''}`}/></p>
            <ul className={`${style.hoverMob} ${isActiveTwo ? style.activeMob : ''}`}>
              <li      onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}>
                <NavLink to="/zoo-store/one-time-boxes">
                EDventures
                </NavLink>
              </li>
              <li      onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}>
                <NavLink to="/zoo-store/digital-studies">
                Digital Studies

                </NavLink>
              </li>
              {/* <li nClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }} className={style.spookySeason}>
                <NavLink to="/zoo-store/one-time-boxes?spooky-sale" >
                    <span>NEW!</span>
                    <h3>SPOOKY SALE</h3>
                   
                </NavLink>
              </li> */}
            </ul>
          </li>
              <li>
            
              <Link to="/schools"   
                onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}
                className="plans">School Orders</Link>
            </li>
            <li className={style.secondDrop} >
            <p onClick={toggleActive}>Member Area <img src="/image/icon/bl.svg" className={`${style.noRotate} ${isActive ? style.activeRotate : ''}`}/></p>
            <ul className={`${style.hoverMob} ${isActive ? style.activeMob : ''}`}>
              <li      onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}>
                <NavLink to="/your-next-edventure">
                Your Next EDventure 
                </NavLink>
              </li>
              <li     onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}>
          <a
                href="https://checkout.myzoobox.com/account/auth/login?store=my-zoo-box&redirect_url=https%3A%2F%2Fwww.myzoobox.com"
              
              >
             Login
              </a>
          </li>
            </ul>
          </li>
         
            <li className={style.mobButton}>
              <Link
                to="/select-plans"
                onClick={() => {
                  setOpen(false);
                  animateScroll.scrollToTop();
                }}
              >
              Start Now


              </Link>
            </li>
          </ul>
          {/* <img src="/images/general/circlem.svg"/> */}
        </div>
   
      </>
  );
};

export default Header;
