import React from "react";
import style from "./Journey.module.css"
import { Link } from "react-router-dom";
import Button from "../button/button";

const Journey = () =>{
    return(
        <div className={style.journeyBck}>
            <div className="subContainer">
            <h1>Add STEM, Creativity and Fun to Your Kids Learning Journey!</h1>


            <div className={style.journeyDis}>
                <div className={style.journeyBox}>
                   <img src="/image/prof1.webp"/>
                   <div>
                     <div>
                     <span>3-5 Year Olds</span>     <span>Designed for Preschoolers</span>
                     </div>
                     <h1>Spark a Love for Learning at a Young Age! </h1>
                     <Button dynamicClass="fillCubColor" text="Get Started" link="/gifting-cub-club
" />
                   </div>
          
                </div>
                <div className={style.journeyBox}>
                   <img src="/image/prof2.webp"/>
                   <div>
                     <div>
                     <span>5-12 Year Olds</span>  
                     </div>
                     <h1>The #1 Zoology
Subscription for Kids!</h1>
<Button dynamicClass="fillZooColor" text="Get Started" link="/gifting-zoologist-club
"/>
                   </div>
          
                </div>
            </div>
            </div>
        </div>
    )
}

export default Journey