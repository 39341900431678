import React from "react";
import style from "./Privacy.module.css"

const PrivacyHeading = (props) => {
    return(
         <div className={`${style.cartBck} ${style[props.changeBck]}`} >
            <h1>{props.title}</h1>
            <p>{props.text}</p>
         </div>
    )
}

export default PrivacyHeading;