import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { animateScroll } from "react-scroll";
import queryString from 'query-string';
import style from "./shopV3.module.css";

const ProductCard = ({ product }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Construct the product URL with query parameters if available
  const productUrl = `/product/${product.id}${Object.keys(queryParams).length ? `?${queryString.stringify(queryParams)}` : ''}`;
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      window.subblyConfig = {
          apiKey: '52d2a9b4-a884-42cb-83a9-cfb67f347926',
          settings: {
              interceptProductLinks: true,
              cartCounterEl: '.subbly-cart-product-count',
              cartToggleEl: '.subbly-cart',
              cartButton: true,
          },
      };
    `;
    document.body.appendChild(script);

    const subblyScript = document.createElement('script');
    subblyScript.type = 'module';
    subblyScript.src = 'https://assets.subbly.co/cart/cart-widget.js';
    document.body.appendChild(subblyScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(subblyScript);
    };
  }, []);
  return (
    <div 
     
      key={product.id} 
      className={style.shopBoxVThree} 
     
    > 
    <Link  to={productUrl}  onClick={() => animateScroll.scrollToTop()} className={style.priceDis}>
      {/* Stock Display */}
      {product.stock && <span className={style.productStock}>{product.stock}</span>}
      
      {/* Product Image */}
      <img src={product.image} alt={product.name} className={style.mainImage} />

      {/* Product Details */}
     
       {product.unit && ( <span className={`${style.productAge} ${product.age === '3-5' ? style.cubAge : ""}`}>
          {product.age === '3-5' ? (
            <>
              <img src="/image/icon/cubb.svg" alt="Cub Club Icon" /> Cub Club
            </>
          ) : (
            <>
              <img src="/image/icon/zooo.svg" alt="Zoologist Club Icon" /> Zoologist Club
            </>
          )}
        </span>)}

        {/* Product Name */}
        <h3>{product.name}</h3>

        {/* Price and Rating */}
        <div className={style.starContainer}>
          <p>{product.price}</p>
          {/* <div>
            <img src='/image/star.png' alt="Star Rating" />
            <p>4.8</p>
          </div> */}
        </div>
        </Link>
        <a href={product.link} className={style.addToCart}>Add to Cart</a>
      
    </div>
  );
};

export default ProductCard;
