import React, { useState } from "react";
import style from "./StepForm.module.css"

const StepPlans = (props) =>{
    const [plan, setPlan] = useState(props.data.plan);
    const zooPlan = [
        {
            name:"Monthly",
            price:"$37.95/mo",
            text:"",
            save:"SAVE $9.95*"
        },
        {
            name:"6 Month",
            price:"$36.95/mo",
            text:"Renews monthly after the initial 6 month commitment",
            save:"SAVE $65.70*"
        },
        {
            name:"12 Month",
            price:"$35.95/mo",
            text:"Renews monthly after the initial 12 month commitment",
            save:"SAVE $143.40*"
        }
        ,
        {
            name:"Monthly Mini Zoo",
            price:"$23.95/mo",
            text:"Includes: Stuffed Animal Friend, Book, Sticker, and Postcard from the Animal",
            save:""
        }
    ]

    
    const handlePlanChange = (event) => {
        const { name, value } = event.target;
     if(name){
        setPlan(value);
        console.log("props.data123",value)
        props.data.plans = value
        props.next()
     }
          
    
    
      };

    //   const handleNextClick = (id) => {
       
    //     if(plan){
    //         props.data.plans = plan
    //         props.next()
    //     }
            
    
      
    // };
    return(
        <>
             <img src="/image/ora.png" className={style.orongutan}/>
        <div className={style.plansHeading}>
            <h2>Pick a plan for {props.data.childName}</h2>
            <p>Psst! Shipping is FREE on subscriptions in the U.S. International shipping rates vary.</p>
        </div>
        <div className={style.plansFlex}>
            {zooPlan.map((item) =>{
                return(
                    <div class={style.plan}>
                    <label>
                        <div class={style.colorPlan}>
                            <input
                                type="radio"
                                name={item.name}
                                value={item.name}
                                onChange={handlePlanChange}/>
                            <div>
                                <h2>{item.name}</h2>
                            </div>
                        </div>

                        <div class={style.planDis}>
                            <div>
                                <div class={style.comb}>
                                    <h2>{item.price} +</h2>
                                    <img
                                        src="https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/fast-delivery-ornage-16914498107213-16969308967713.png?1696931017343" />
                                    <p>FREE SHIPPING</p>
                                </div>
                                <p>
                                    {item.text}
                                </p>
                                {   item.save && 
                                <p class={style.planSave}>{item.save}</p>}
                            </div>
                        </div>
                    </label>
                </div>
                )
            })}
      
        </div>
        </>
    )
}

export default StepPlans