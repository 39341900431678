import React from "react";
import style from "./Toggle.module.css"
const Addon = (props) =>{
    const handleAddonClick = (add) =>{
        props.data.addon = add;
        const urlMappings = {
            "Monthly-zoo": {
                "1 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/260475",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/260495"
                },
                "2 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/260485",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/260491"
                },
                "No thanks": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/261977",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/260388"
                }
            },
            "6Months-zoo": {
                "1 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/264722",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/264730"
                },
                "2 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/264714",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/264706"
                },
                "No thanks": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/264698",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/264688"
                }
            },
            "12Months-zoo": {
                "1 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/264726",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/264734"
                },
                "2 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/264718",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/264710"
                },
                "No thanks": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/264702",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/264692"
                }
            },
            "6Prepaid-zoo": {
                "1 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265523",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265301"
                },
                "2 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265307",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265303"
                },
                "No thanks": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265297",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265299"
                }
            },
            "12Prepaid-zoo": {
                "1 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265523",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265525"
                },
                "2 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265529",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265527"
                },
                "No thanks": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265516",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265521"
                }
            },
            "6Prepaid-zooGift": {
                "1 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265523",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265301"
                },
                "2 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265307",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265303"
                },
                "No thanks": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265297",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265299"
                }
            },
            "12Prepaid-zooGift": {
                "1 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265523",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265525"
                },
                "2 Sibling": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265529",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265527"
                },
                "No thanks": {
                    "No": "https://checkout.myzoobox.com/checkout/buy/265516",
                    "Yes": "https://checkout.myzoobox.com/checkout/buy/265521"
                }
            },
           
        };
      const urlMappingsMini = {
        "Mini-zoo": {
            "No": "https://checkout.myzoobox.com/checkout/buy/143404",
            "Yes": "https://checkout.myzoobox.com/checkout/buy/272426"
    }
      }
        const url = urlMappings[props.data.plan]?.[props.data.siblings]?.[add];
         const urlTwo =  urlMappingsMini[props.data.plan]?.[add];
        if (url) {
            window.location.href = url;
        }else if(urlTwo){
            window.location.href = urlTwo;
        }
    

      



       
   }
    return(
        <div>
              <div className={style.addon}>
             <div className={style.sibImage}>
              <img src="https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/new-book-addon-image-16962812168155.png?1696281222449"/>
             </div>
             <div className={style.sibContent}>
                <h1>Boost the learning!</h1>
                <p>Add a SECOND BOOK to your monthly EDventure! An easy reader all about the animal of the month to encourage your new reader to love reading and learning for $7.95</p>
                <div className={style.buttonDis}>
                <button onClick={() => handleAddonClick("Yes")}>YES! Add the Book</button>     
                <button onClick={() => handleAddonClick("No")}>No Thanks</button></div>           
             </div>
        </div>
        </div>
    )
}

export default Addon;