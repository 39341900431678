import React, { useEffect } from "react";
import Hub from "../../component/hub/Hub";
import Trusted from "../../component/trusted/Trusted";
import Fan from "../../component/fan/Fan";
import { Link } from "react-router-dom";


const FirstEdventure = () =>{
    const hub = {
        header:"Welcome to the Animal of the Month Hub 👋        ",
        link:"/your-next-edventure",
      
            nav: (
                <span>
                NOTE: If you are expecting your next My ZOO Box, please <Link to="/your-next-edventure">Click Here</Link>  to view what EDventure ships next!
                </span>
            ),
        box:[
            {
                icon:"/image/icon/cubb.svg",
                question:"What Animal EDventure Ships with Your First Cub Club Box?",
                name:"The Pangolin",
                // description:"Get the corresponding unit study plus 4 short videos to extend the learning for only $7",
                image:"/image/hub3.png",
                linkText:"Get Started with Cub Club",
                link:"/cub-plan "
                
            },
            {
                icon:"/image/icon/zooo.svg",
                question:"What Animal EDventure Ships with Your First Zoologist Club Box?",
                name:"The Arctic Fox",
                // description:"New Zoologist Club members will be receiving the Gorilla EDventure until it's sold out. Expected to SELL OUT FAST!",
                image:"/image/artic.png",
                reverse:"reverse",
                linkText:"Get Started with Zoologist Club",
                link:"/zoologist-plan"
            },
        ]
    }


    const zooStore = [
        // {
        //          image:"/image/fan.png",
        //          price:"$39.95",
        //          name:"Deer EDventure",
        //         cart:"https://checkout.myzoobox.com/checkout/add/119420",
        // },
        {
            image:"/image/fan2.png",
            price:"$39.95",
            name:"Hammerhead Shark EDventure",
            // stock:"Low Stock", 
            price: "$29.96",
            cart:"https://checkout.myzoobox.com/checkout/add/257095",
   },
   {
    image:"/image/fan3.png",
    price:"$39.95",
    name:"Sea Lion EDventure    ",
    // stock:"Low Stock", 
    price: "$29.96",
    cart:"https://checkout.myzoobox.com/checkout/add/247026",
},
{
    image:"/image/moose.jpg",

    // stock:"Low Stock Less Than 10", 
    price: "$23.97",
    name:"Moose EDventure",
    cart:"https://checkout.myzoobox.com/checkout/buy/310564",

},
    ]

    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
          window.subblyConfig = {
              apiKey: '52d2a9b4-a884-42cb-83a9-cfb67f347926',
              settings: {
                  interceptProductLinks: true,
                  cartCounterEl: '.subbly-cart-product-count',
                  cartToggleEl: '.subbly-cart',
                  cartButton: true,
              },
          };
        `;
        document.body.appendChild(script);
    
        const subblyScript = document.createElement('script');
        subblyScript.type = 'module';
        subblyScript.src = 'https://assets.subbly.co/cart/cart-widget.js';
        document.body.appendChild(subblyScript);
    
        return () => {
          document.body.removeChild(script);
          document.body.removeChild(subblyScript);
        };
      }, []);
    
    return(

         <>
            <Hub hub={hub}/>
            <div className="container">
            <Fan boxes={zooStore}/>
            <Trusted />
        </div>
         </>
    )
}

export default FirstEdventure;