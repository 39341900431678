import React from "react";
import style from "./MainShop.module.css"
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
const ProductType = () =>{
    const box = [
        {
          image:"/image/el.png",
          name:"African Animals",
          add:"afr",
          link:"/product-list?type=African%20Animals"
        //   scroll:section2Ref
        },
        {
          image:"/image/e2.png",
          name:"Predators ",
          add:"pred",
          link:"/product-list?type=Predators"
        //   scroll:section3Ref
        },
        {
          image:"/image/e3.png",
          name:"Rainforest Animals",
          add:"rain",
          link:"/product-list?type=Rainforest%20Animals"
        //   scroll:section4Ref
        },
        {
          image:"/image/e4.png",
          name:"Animals that Fly",
          add:"fly",
          link:"/product-list?type=Animals%20That%20Fly"
        //   scroll:section5Ref
        },
        {
          image:"/image/shark.png",
          name:"Ocean Animals",
          add:"ocean",
          link:"/product-list?type=Ocean%20Animals"
        //   scroll:section6Ref
        },
      ]
    return(
        <div className={style.type}>
             <div className={style.fanHeader}>
            <h2>Browse by type</h2>
            <Link to='/product-list'  onClick={() => animateScroll.scrollToTop()}>View All</Link>
           
           </div>

           <div className={style.typeDis}>
            {box.map((item => {
              return(
             <Link className={style.sectionBox}  to={item.link}  onClick={() => animateScroll.scrollToTop()}>
                <img src={item.image}/>
                <div className={`${style.sectionCont} ${style[item.add]}`}>
                     <h3>{item.name}</h3>
                     <div>
                     <p>View EDventures</p>
                     <img src="/image/icon/arrow.svg"/>
                      </div>
                   
                </div>
              </Link>
              )
            }))}
              
            </div>
        </div>
    )
}

export default ProductType;