import React from "react";
import style from "./Tabs.module.css"

const Guide = (props) =>{
  
    return(
        <div className={style.guide}>
               {props.boxTwo.remove === "remove" ? "" :
            <div className={style.bookDis}>
             
                <img src={props.boxTwo.image}/>
                <div>
                    <h3>{props.boxTwo.heading}</h3>
                    <p>{props.boxTwo.description}</p>
                </div>
                </div>
                 }
                <div className={style.guideDis}>
                    {props.boxTwo.guide.map(((item,i) =>{
                        return(
                            <div className={style.guideBox} key={i}>
                            <img src={item.icon} alt="icon"/>
                            <div>
                                <h2>{item.title}</h2>
                                <p>{item.desciption}</p>
                            </div>
                         </div>
                        )
                    }))}
                  
                </div>
           
        </div>
    )
}

export default Guide;