import React from "react";
import style from "./Plans.module.css"
import PlanToggle from "../../component/toggle/planToggle";
import StepToggle from "../../component/toggle/stepToggle";
import KidsTestimonials from "../../component/kidTestimonials/KidTestimonials";
import Trusted from "../../component/trusted/Trusted";
import Newsletter from "../../component/newsletter/Newsletter";


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation } from 'swiper/modules';
const Plans = (props) =>{
    return(
         <div className={style.plansBck}>
    
              <div className="container">
            <div className={style.plans}>
                <div className={style.plansCollage}>
                    <img src={props.image.bigImage} alt="Description of the image"/>
                    <div>
                      {props.image.small.map((item) =>{
                           return(
                            <img src={item.image} alt="Description of the image"/>
                           )
                      })}
               
                    
                    </div>
                </div>
                 <div className={style.plansMobile}>
                 <Swiper
      pagination={{   
        clickable: true,
      }}
      navigation={true}
        // navigation={true}
        modules={[ Navigation]}
        className="mySwiper"
      >
         <SwiperSlide><img src={props.image.bigImage}/></SwiperSlide>

           {props.image.small.map((item) =>{
                           return(
                            
                            <SwiperSlide><img src={item.image}/></SwiperSlide>
                           )
                      })}
       
      
      </Swiper>
                 </div>
                <div className={style.plansBox}>
                    <StepToggle box={props.box} boxTwo={props.boxTwo} header={props.header} gift={props.gift} giftPlans={props.giftPlans}/>
                </div>
            </div>
        
        </div>
        <Trusted trustedBck="white"/>
             <KidsTestimonials/>
             <Newsletter increase="increase"/> 
         </div>
    )
}

export default Plans