// In your React component file

import React, { useState, useEffect } from 'react';
import style from "./CopyStore.module.css"
function Product(props) {
  const [subblyInitialized, setSubblyInitialized] = useState(false);

  // Function to initialize Subbly
  function initializeSubbly() {
    if (!window.Subbly || !window.Subbly.openCart) {
      console.log('Initializing Subbly...');
      window.Subbly = {
        openCart: () => {
          console.log('Opening Subbly cart...');
          // Example: Dispatch an event to open the Subbly cart
          if (window.Subbly && typeof window.Subbly.openCart === 'function') {
            window.Subbly.openCart();
          } else {
            console.error('Subbly openCart function not available.');
          }
        }
      };
    }
    // Indicate that Subbly is initialized
    setSubblyInitialized(true);
  }

  // Function to handle button click
  function handleClick() {
    if (subblyInitialized) {
      // Subbly is initialized, attempt to open the cart
      if (window.Subbly && window.Subbly.openCart) {
        window.Subbly.openCart();
      } else {
        console.error('Subbly openCart function not available.');
      }
    } else {
      console.error('Subbly is not initialized yet.');
    }
  }

  // Initialize Subbly when the component mounts
  useEffect(() => {
    initializeSubbly();
  }, []);

  return (
        <>
        {/* <div className={`${style.plansBox} ${style.storeEgg}`} ref={props.refOffer}>
                <div className={style.customer}>
                <p>USA Customers: Flat Rate Shipping of $7.95<br/>
                   Canada Customers: Flat Rate Shipping of $12.95usd</p>
                </div>
        <h1>{props.discountProduct.title}</h1>
        <h2>{props.discountProduct.subTitle}</h2>
        <div className="container">
        <div className={style.storeDis}>
          {props.discountProduct.box.map((item =>{
            return(
              <div>
                <img src='/image/40.svg' className={style.egg}/>
              <img src={item.image}/>
              <h3>{item.name}</h3>
              <div className={style.dicount}>
              <span >$39.95</span>
              <p>{item.price}</p>
              {item.stock ? <i className={style.stock}>{item.stock}</i>  :    <i>In Stock</i>}                </div>
         
             
              <a href={item.link}>Add to Cart</a>
            </div>
            )
          }))}
          
        </div>
      </div>
  

        </div>


        <div className={`${style.plansBox} ${style.storeEgg}`} ref={props.refOfferTwo}>
        <h1>{props.discountProductTwo.title}</h1>
        <h2>{props.discountProductTwo.subTitle}</h2>
        <div className="container">
        <div className={style.storeDis}>
          {props.discountProductTwo.box.map((item =>{
            return(
              <div>
                <img src='/image/egg2.svg' className={style.egg}/>
              <img src={item.image}/>
              <h3>{item.name}</h3>
              <div className={style.dicount}>
              <span >$39.95</span>
              <p>{item.price}</p>
              {item.stock ? <i className={style.stock}>{item.stock}</i>  :    <i>In Stock</i>}   
                </div>
              <a href={item.link}>Add to Cart</a>
            </div>
            )
          }))}
          
        </div>
      </div>
  

        </div>




        <div className={`${style.plansBox} ${style.storeEgg}`} ref={props.refOfferThree}>
        <h1>{props.discountProductThree.title}</h1>
        <h2>{props.discountProductThree.subTitle}</h2>
        <div className="container">
        <div className={style.storeDis}>
          {props.discountProductThree.box.map((item =>{
            return(
              <div>
                <img src='/image/egg3.svg' className={style.egg}/>
              <img src={item.image}/>
              <h3>{item.name}</h3>
              <div className={style.dicount}>
              <span >$39.95</span>
              <p>{item.price} </p>
              {item.stock ? <i className={style.stock}>{item.stock}</i>  :    <i>In Stock</i>}
           
              
                </div>
              <a href={item.link}>Add to Cart</a>
            </div>
            )
          }))}
          
        </div>
      </div>
  

        </div> */}
           <div className={style.plansBox} ref={props.refOne}>
        <h1>{props.product.title}</h1>
        <div className="container">
        <div className={style.storeDis}>
          {props.product.box.map((item =>{
            return(
              <div>
              <img src={item.image}/>
              <h3>{item.name}</h3>
              <div className={style.dicount}>

              <p>{item.price}</p>
              {item.stock ? <i className={style.stock}>{item.stock}</i>  :    <i>In Stock</i>}                </div>
              <a href={item.link}>Add to Cart</a>
            </div>
            )
          }))}
          
        </div>
      </div>
  

  
    </div> 



    <div className={`${style.plansBox} ${style.storeSix}`} ref={props.refFour}>
<h1>{props.productFive.title}</h1>
<div className="container">
<div className={style.storeDis}>
  {props.productFive.box.map((item =>{
    return(
      <div>
      <img src={item.image}/>
      <h3>{item.name}</h3>
      <p>{item.price} <i>In Stock</i></p>
      <a href={item.link}>Add to Cart</a>
    </div>
    )
  }))}
  
</div>
</div>
</div> 

 <div className={`${style.plansBox} ${style.storeSix}`} ref={props.refFive}>
<h1>{props.productSix.title}</h1>
<div className="container">
<div className={style.storeDis}>
  {props.productSix.box.map((item =>{
    return(
      <div>
      <img src={item.image}/>
      <h3>{item.name}</h3>
      <p>{item.price} <i>In Stock</i></p>
      <a href={item.link}>Add to Cart</a>
    </div>
    )
  }))}
  
</div>
</div>
</div> 



<div className={`${style.plansBox} ${style.storeSix}`} ref={props.refSix}>
<h1>{props.productEight.title}</h1>
<div className="container">
<div className={style.storeDis}>
  {props.productEight.box.map((item =>{
    return(
      <div>
      <img src={item.image}/>
      <h3>{item.name}</h3>
      <p>{item.price} <i>In Stock</i></p>
      <a href={item.link}>Add to Cart</a>
    </div>
    )
  }))}
  
</div>
</div>
</div> 




        </>
  );
}

export default Product;
