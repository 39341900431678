import React, { useState } from "react";
import style from "./StepForm.module.css"

const StepEmail = (props) =>{
    const [enterEmail, setEnterEmail] = useState(props.data.email);
    const [emailError, setEmailError] = useState("");
    const validateForm = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        let isValid = true;
      
        if (!enterEmail.trim()) {
            setEmailError("Email is required");
          isValid = false;
        } else if (!emailRegex.test(enterEmail)){
            setEmailError("Please enter a Please enter a valid email address");
            isValid = false;
          
        }else{
            setEmailError("");
            return isValid ;
        }
    
     
      };

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "email") {
          setEnterEmail(value);
          console.log("props.data123",name)
        } 
    
      };
    const handleNextClick = (id) => {
       
        if (validateForm()) {
            props.data.email = enterEmail
            props.next()
        }
      
    };

    return(
        <>
                <img src="/image/gji.png" className={style.girafe}/>
        
         <div className={style.stepsHeading}>
              <h2>What’s your email address?</h2>
              <p>We love to send birthday surprises, sneak peeks, exclusive deals, animal facts and more! Let us know where to send the fun!</p>
          </div>
          <div className={style.emailInput}>
            <input type="email" name="email" placeholder="Your Email address*"  value={enterEmail} onChange={handleInputChange}/>
            <span className={style.error}>{emailError}</span>
            <span className={style.policy}>By clicking "Continue" you agree to our Privacy Policy and Terms of Conditions</span>
            <p className={style.stepsBtn} onClick={handleNextClick}>Next</p>
          </div>
        </>
    )
}

export default StepEmail