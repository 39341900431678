import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate for navigation
import style from './shopV3.module.css'; // Import CSS module
import { animateScroll } from "react-scroll";

const SearchBar = ({ productsData }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAge, setSelectedAge] = useState('Select Club');
  const [selectedType, setSelectedType] = useState('Select Category');
  const [showAgeOptions, setShowAgeOptions] = useState(false);
  const [showTypeOptions, setShowTypeOptions] = useState(false);

  const navigate = useNavigate(); // Initialize useNavigate

  // Handle the custom age dropdown
  const handleAgeSelect = (age) => {
    setSelectedAge(age);
    setShowAgeOptions(false);
  };

  // Handle the custom type dropdown
  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setShowTypeOptions(false);
  };

  // Handle the search click and redirect to shopV3 with query parameters
  const handleSearchClick = () => {
    const queryParams = new URLSearchParams();

    if (selectedAge !== 'Select Club') {
      queryParams.append('age', selectedAge);
    }
    if (selectedType !== 'Select Category') {
      queryParams.append('type', selectedType);
    }
    if (searchTerm) {
      queryParams.append('searchTerm', searchTerm);
    }
    animateScroll.scrollToTop()
    navigate(`/zoo-store/all?${queryParams.toString()}`);
  };

  return (
    <div className={`container ${style.searchProduct}`}>
      {/* <h2>Match with their perfect EDventure</h2> */}
      <div className={style.container}>
        <div className={style.searchDis}>
          <p>I am shopping for a</p>
          <div
            className={style.customDropdown}
            onClick={() => setShowAgeOptions(!showAgeOptions)}
          >
            <div className={style.selectedOption}>
              {selectedAge === 'Select Club' ? (
                <>Select Club</>
              ) : selectedAge === 'All Ages' ? (
                <>All Ages</>
              ) : selectedAge === '3-5' ? (
                <>
                  <img src="/image/icon/cubb.svg" alt="Cub" /> 3-5 Year Old
                </>
              ) : (
                <>
                  <img src="/image/icon/zooo.svg" alt="Zoo" /> 5-12 Year Old
                </>
              )}
              <span className={style.arrowAll}></span>
            </div>
            {showAgeOptions && (
              <div className={style.optionsList}>
                <div onClick={() => handleAgeSelect('Select Club')} className={style.option}>
                  Select Club
                </div>
                <div onClick={() => handleAgeSelect('3-5')} className={style.option}>
                  <img src="/image/icon/cubb.svg" alt="Cub" /> 3-5 Year Old
                </div>
                <div onClick={() => handleAgeSelect('5-12')} className={style.option}>
                  <img src="/image/icon/zooo.svg" alt="Zoo" /> 5-12 Year Old
                </div>
              </div>
            )}
          </div>

          <p>looking to explore </p>
          <div
            className={style.customDropdown}
            onClick={() => setShowTypeOptions(!showTypeOptions)}
          >
            <div className={style.selectedOption}>
              {selectedType === 'Select Category' ? (
                <>Select Category</>
              ) : selectedType === 'Ocean Animals' ? (
                <>
                  <img src='/image/ocean.png' alt='Ocean' /> Ocean Animals
                </>
              ) : selectedType === 'African Animals' ? (
                <>
                  <img src='/image/african.png' alt='African' /> African Animals
                </>
              ) : selectedType === 'Predators' ? (
                <>
                  <img src='/image/predator.png' alt='Predator' /> Predators
                </>
              ) : selectedType === 'Rainforest Animals' ? (
                <>
                  <img src='/image/rainforest.png' alt='Rainforest' /> Rainforest Animals
                </>
              ) : selectedType === 'Animals that Fly' ? (
                <>
                  <img src='/image/fly.png' alt='Fly' /> Animals That Fly
                </>
              ) : (
                selectedType
              )}
              <span className={style.arrowAll}></span>
            </div>
            {showTypeOptions && (
              <div className={`${style.optionsList} ${style.optionsListTwo}`}>
                <div onClick={() => handleTypeSelect('Select Category')} className={style.option}>
                  Select Category
                </div>
                <div onClick={() => handleTypeSelect('Land Animals')} className={style.option}>
                  <img src='/image/african.png' alt='African' />Land Animals
                </div>
               
                <div onClick={() => handleTypeSelect('Ocean Animals')} className={style.option}>
                  <img src='/image/ocean.png' alt='Ocean' /> Ocean Animals
                </div>
                {/* <div onClick={() => handleTypeSelect('African Animals')} className={style.option}>
                  <img src='/image/african.png' alt='African' /> African Animals
                </div>
                <div onClick={() => handleTypeSelect('Predators')} className={style.option}>
                  <img src='/image/predator.png' alt='Predator' /> Predators
                </div>
                <div onClick={() => handleTypeSelect('Rainforest Animals')} className={style.option}>
                  <img src='/image/rainforest.png' alt='Rainforest' /> Rainforest Animals
                </div> */}
                <div onClick={() => handleTypeSelect('Animals that Fly')} className={style.option}>
                  <img src='/image/fly.png' alt='Fly' /> Animals That Fly
                </div>
              </div>
            )}
          </div>

          <div className={style.searchIcon} onClick={handleSearchClick}>
            <img src="/image/search.svg" alt="Search" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
