import React, { useEffect, useRef, useState } from "react";
import { useTransition, animated } from "react-spring";
import Toggle from "./Toggle";
import Siblings from "./siblings";
import Addon from "./Addon";
import PlanToggle from "./planToggle";
import style from "./PlanToggle.module.css"
const StepToggle = (props) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    plan: "",
    siblings: "",
    // addOn: ""
  });

  const stepContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the top of the step container whenever step changes
    if (stepContainerRef.current) {
      stepContainerRef.current.scrollIntoView({ behavior: 'smooth' }) ;
    }
  }, [step]);

  const handleNextStep = (name, value) => {
    const newData = { ...data, [name]: value };
    setData(newData);
    setStep((prev) => prev + 1 + (data.plan === "Mini-zoo" ? 1 : 0));
    console.log("clicked")
  };

  const handlePickStep = (name, value, final = false) => {
    const newData = { ...data, [name]: value };
    setStep((prev) => prev -1);  
  };
  const stepPages = [

    <PlanToggle next={handleNextStep} data={data} box={props.box} header={props.header} boxTwo={props.boxTwo} gift={props.gift} giftPlans={props.giftPlans}/>,
    <Siblings next={handleNextStep} data={data} />,
    <Addon data={data} />,
  ];

  const transitions = useTransition(step, {
    from: { opacity: 0, position: "absolute", width: "100%", transform: "translate3d(100%, 0, 0)" },
    enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(-50%, 0, 0)" },
  });
console.log("data",data)
  return (
   <div  ref={stepContainerRef}>
          {stepPages[step]}
          {step === 0 ? "" :       <p onClick={handlePickStep} className={style.goBack}>Go Back</p>}
    
   </div>
  );
};

export default StepToggle;
