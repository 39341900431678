import React from "react";
import style from "./Experience.module.css"
import Button from "../button/button";
const       Experience = (props) =>{
    
    return(
        <div className={style.experienceBck}>
            <div className="subContainer">
            <div className={style.experienceHeader}>
                <h1>Choose Your Experience</h1>
                <p>Previewing sample EDventures</p>
            </div>

            <div className={style.experienceDis}>
                {props.experience.map((item =>{
                    return(
                        <div className={style.experienceBox}>
                        <div className={`${style.experienceClub} ${item.club === "Zoologist Club" ? style.changeColor : ""} `}>
                          <img src={item.icon}/>
                          <h2>{item.club}</h2>
                          <p>Ages {item.age}</p>
                        </div>
                        <img src={item.image} className={style.experienceClubImg}/>
                        <div className={style.exoerienceList}>
                             <p>Included Inside:</p>
                             <div>
                              <ul>
                                {item.listOne.map((item =>{
                                    return(
                                        <li>{item.list}</li>
                                    )
                                }))}
                                
                                  
                              </ul>
                              <ul>
                              {item.listTwo.map((item =>{
                                    return(
                                        <li>{item.list}</li>
                                    )
                                }))}
                                
                              </ul>
                             </div>
  
                             <Button dynamicClass={item.club === "Zoologist Club" ? "fillZooColor" : "fillCubColor"} text={item.button}  link={item.link}/>
                        </div>
                   </div>
                    )
                }))}
            </div>
            </div>
        </div>
    )
}

export default Experience