import React from "react";
import style from "./Error.module.css"
import Button from "../component/button/button";


const Errormsg = () =>{
    return(
        <div className={`container ${style.errorMsg}`}>
             <img src="/image/err2.svg" className={style.errorBanner}/>
             <img src="/image/moberror.svg" className={style.mobError}/>
             <div>

                <p>Are you lost? Maybe the link was a mirage.<br/> or you made an error typing it? Nothing to see here.</p>
                <Button dynamicClass="fillCubColor" text="Go to Homepage" link="/"/>
             </div>
        </div>
    )
}

export default Errormsg