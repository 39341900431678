import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styles from "./Empower.module.css";

const validationSchema = Yup.object({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    school: Yup.string(),
    phone: Yup.string().required('Phone is required'),
    studentName: Yup.string().required('Student’s Full Name is required'),
    projectDescription: Yup.string().required('Project Description is required'),
    materialsNeeded: Yup.string().required('Materials Needed is required'),
    budget: Yup.string().required('Budget is required'),
    empowerment: Yup.string().required('This field is required'),
    personalStatement: Yup.string().required('Personal Statement is required'),
});

const EmpowerForm = () => {
    const [activeTab, setActiveTab] = useState('');
    const [show, setShow] = useState(false);

    const handleTabClick = (label) => {
        setActiveTab(prevTab => (prevTab === label ? '' : label));
    };

    const handleFormSubmit = (values, { setSubmitting }) => {
        const formData = {
            ...values,
            school: activeTab,
        };

        const fullNameA = document.querySelector("._x83298085 #fullname");
        const emailA = document.querySelector("._x57745808 #email");
        const school = document.querySelector("._x37784492 input");
        const phoneA = document.querySelector("._x28555506 input");
        const student = document.querySelector("._x22087643 input");
        const project = document.querySelector("._x82474290 textarea");
        const materials = document.querySelector("._x75436140 textarea");
        const budget = document.querySelector("._x68649174 textarea");
        const empower = document.querySelector("._x06933833 textarea");
        const personal = document.querySelector("._x76133025 textarea");
        const buttonA = document.querySelector("#_form_27_submit");

        fullNameA.value = formData.fullName;
        emailA.value = formData.email;
        school.value = formData.school;
        phoneA.value = formData.phone;
        student.value = formData.studentName;
        project.value = formData.projectDescription;
        materials.value = formData.materialsNeeded;
        budget.value = formData.budget;
        empower.value = formData.empowerment;
        personal.value = formData.personalStatement;

        console.log('Form Data:', formData);

        setTimeout(() => {
            setSubmitting(false);
            buttonA.click();
            setShow(true);
        }, 3000);
    };

    return (
        <div className={styles.formContainer}>
            <Formik
                initialValues={{
                    fullName: '',
                    email: '',
                    school: '',
                    phone: '',
                    studentName: '',
                    projectDescription: '',
                    materialsNeeded: '',
                    budget: '',
                    empowerment: '',
                    personalStatement: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleFormSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label htmlFor="fullName">Your Full Name</label>
                                <Field type="text" name="fullName" placeholder="Your first and last name" />
                                <ErrorMessage name="fullName" component="div" className={styles.error} />
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="email">Your Email*</label>
                                <Field type="email" name="email" placeholder="Your Email" />
                                <ErrorMessage name="email" component="div" className={styles.error} />
                            </div>
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label>Name of school or organization (Optional)</label>
                                <div className={styles.tabs}>
                                    {['Individual Student', 'Teacher for my classroom', 'Classroom or Group'].map(label => (
                                        <div
                                            key={label}
                                            className={`${styles.tab} ${activeTab === label ? styles.active : ''}`}
                                            onClick={() => handleTabClick(label)}
                                        >
                                           <p>  {label}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="phone">Phone</label>
                                <Field type="text" name="phone" placeholder="Type your phone number" />
                                <ErrorMessage name="phone" component="div" className={styles.error} />
                            </div>
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label htmlFor="studentName">Student’s Full Name</label>
                                <Field type="text" name="studentName" placeholder="Student’s first and last name" />
                                <ErrorMessage name="studentName" component="div" className={styles.error} />
                            </div>
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label htmlFor="projectDescription">Project Description:</label>
                                <Field as="textarea" name="projectDescription" placeholder="Clearly explain the projects, its goals, and how it aligns with the EMPOWER Foundation’s mission." />
                                <ErrorMessage name="projectDescription" component="div" className={styles.error} />
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="materialsNeeded">Materials Needed:</label>
                                <Field as="textarea" name="materialsNeeded" placeholder="List the materials required to bring the project to life. This could include anything from art supplies and tools to plants and resources." />
                                <ErrorMessage name="materialsNeeded" component="div" className={styles.error} />
                            </div>
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label htmlFor="budget">Budget:</label>
                                <Field as="textarea" name="budget" placeholder="Provide an estimated budget for the project, showcasing how the micro-grant will be used effectively." />
                                <ErrorMessage name="budget" component="div" className={styles.error} />
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="empowerment">How will it Empower:</label>
                                <Field as="textarea" name="empowerment" placeholder="Describe how the project will empower the local community, promote awareness, and create a lasting impact." />
                                <ErrorMessage name="empowerment" component="div" className={styles.error} />
                            </div>
                        </div>

                        <div className={styles.formRow}>
                            <div className={styles.formGroup}>
                                <label htmlFor="personalStatement">Personal Statement:</label>
                                <Field as="textarea" name="personalStatement" placeholder="Encourage kids to share why this project is important to them and how they envision it making a positive change." />
                                <ErrorMessage name="personalStatement" component="div" className={styles.error} />
                            </div>
                        </div>
                        {show && <h3 className={styles.submit}><img src='/image/tf.svg' alt="tick" />Thank you for submitting your EMPOWER application! Our team will review it promptly and get back to you soon with the next steps. Stay tuned!</h3>}

                        <button type="submit" disabled={isSubmitting}>
                            Submit
                        </button>
                    </Form>
                )}
            </Formik>

            <div className="_form_27"></div>
        </div>
    );
};

export default EmpowerForm;
  