const productsData = [
  {
    id: 'Butterfly-EDventure',
    name: 'Butterfly EDventure',
    image:  '/image/but.png',
    price: '$39.95',
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/add/247032?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    photos: [ '/image/but1.png','/image/but2.png', '/image/but3.png', ],
    type: 'Animals that Fly',
    FanFavorite:"Yes",
    age:"5-12",
    category:"One-time Boxes",
    description:"Flutter into a colorful world with the My ZOO Box Butterfly EDventure! Explore the beauty of butterflies through fun art projects, hands-on activities, and fascinating facts about their life cycle and anatomy. Grow your own butterfly and cuddle up with the soft butterfly hugger as you dive into exciting projects and creative fun. It’s a magical way to learn about these delicate creatures while enjoying tons of fluttery fun!",

    // Add other product details as needed
  },
  {
    id: 'Alligator-EDventure-Zoologist',
    name: 'Alligator EDventure ',
    image: '/image/al.png',
    price: '$39.95',
    // stock:"In Stock!",
    photos: ['/image/al.png', '/image/aa1.png', '/image/aaa2.png'],
    type: 'Land Animals',
    link:"https://checkout.myzoobox.com/checkout/add/119419?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    age:"5-12",
    category:"One-time Boxes",
    description:"Chomp into fun with the My ZOO Box Alligator EDventure! Learn all about these snappy reptiles through exciting projects, creative art, and hands-on activities. Squeeze your own alligator toy, explore their anatomy, and make awesome alligator crafts. It’s an adventure that will leave you snapping for more fun and learning!    ",
    showUnit:"alligatorzoo",

    // Add other product details as needed
  },
  {
    id: 'Flamingo-EDventure',
    name: 'Flamingo EDventure',
    image: '/image/flamin.png',
    price: '$39.95',
    link:"https://checkout.myzoobox.com/checkout/add/119421",
    photos: [ '/image/flam1.png', '/image/flam2.png', '/image/flam3.png'],
    type:'Animals that Fly',
    age:"5-12",
    // stock:"In Stock!",
    category:"One-time Boxes",
    description:"Step into a world of pink with the My ZOO Box Flamingo EDventure! Discover fascinating flamingo facts, get creative with fun art projects, and fly into hands-on activities that will have you thinking like a flamingo expert. With a cuddly flamingo friend and exciting projects, you'll be standing tall as you explore the wonders of these graceful birds. It's a colorful adventure filled with feathery fun and learning!"

    // Add other product details as needed
  },
  {
    id: 'Anteater-EDventure',
    name: 'Anteater EDventure',
    image: '/image/antea.png',
    price: '$39.95',
    link:"https://checkout.myzoobox.com/checkout/add/119418",
    // stock:"In Stock!",
    photos: [ '/image/ante1.png', '/image/ante2.png'],
    type: 'Land Animals',
    FanFavorite:"Yes",
    age:"5-12",
    category:"One-time Boxes",
    description:"Get ready to explore with the My ZOO Box Anteater EDventure! Learn all about these fascinating, long-tongued creatures through fun facts, cool art project, and exciting activities. Spot the wild with your binocular toy, cuddle up with your anteater buddy, and dive deep into their world. This EDventure will have you crawling with excitement as you become an anteater expert!",

    // Add other product details as needed
  },
  {
    id: 'Sea-Star-EDventure',
    name: 'Sea Star EDventure',
    image: '/image/st.png',
    price: '$39.95',
    photos: [ '/image/star1.png', '/image/star2.png', '/image/star3.png', '/image/star4.png'],
    type: 'Ocean Animals',
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/add/255002?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    // stock:"In Stock!",
    category:"One-time Boxes",
    description:"Get ready to shine with the My ZOO Box Sea Star Edventure! Dive into the wonders of sea stars through fun projects, hands-on activities, and fascinating facts. Create your own sea star necklace, solve ocean puzzles, and explore their anatomy as you learn all about these incredible creatures. This EDventure is packed with ocean fun and sea star surprises, perfect for budding marine explorers!"

    // Add other product details as needed
  },
   
  {
    id: 'Monkey-EDventure',
    name: 'Monkey EDventure',
    image: '/image/monk.png',
    price: '$39.95',
    photos: [ '/image/mon1.png', '/image/mon2.png', '/image/mon3.png'],
    type: 'Land Animals',
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/add/119414?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    category:"One-time Boxes",
    description:"Swing into action with the My ZOO Box Monkey EDventure! Explore the playful world of monkeys through fun art projects, hands-on activities, and cool monkey facts. Cuddle up with your monkey hugger and climb into creative projects that will have you swinging with excitement. This EDventure is full of wild fun and perfect for little zoologists who love to monkey around!"

    // Add other product details as needed
  },
  {
    id: 'Beluga-EDventure',
    name: 'Beluga EDventure',
    image: '/image/belu.png',
    price: '$39.95',
    photos: [ '/image/belu1.png', '/image/belu2.png', '/image/belu3.png','/image/belu4.png'],
    type: 'Ocean Animals',
    // stock:"In Stock!",
    age:"5-12",
    category:"One-time Boxes",
    description:"Make a splash with the My ZOO Box Beluga Whale EDventure! Dive deep into the world of these gentle giants through fun projects, creative art, and hands-on activities. Dig into ocean adventures, learn about beluga anatomy, and create your own beluga-inspired art. This EDventure is packed with ocean fun and beluga knowledge, perfect for young marine enthusiasts!",

    // Add other product details as needed
  },
  {
    id: 'Elephant-EDventure',
    name: 'Elephant EDventure',
    image: '/image/elp.png',
    price: '$39.95',
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/add/119411?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    photos: [ '/image/elp.png', '/image/ell1.png', '/image/ell2.png', '/image/ell3.png'],
    type: 'Land Animals',
    age:"5-12",
    category:"One-time Boxes",
    description:"Stomp into fun with the My ZOO Box Elephant EDventure! Learn all about these gentle giants through exciting projects, creative art, and hands-on activities. Build your own mini elephant, wear your elephant ears headband, and explore fascinating facts about their anatomy. This EDventure is packed with jumbo-sized fun, perfect for budding elephant experts!",
    showUnit:"Elephant",

 
    // Add other product details as needed
  },
  // Add 6 more products here
  {
    id: 'Bee-EDventure',
    name: 'Bee EDventure',
    image: '/image/bi.png',
    price: '$39.95',
    link:"https://checkout.myzoobox.com/checkout/add/254994",
    // stock:"In Stock!",
    photos: [  '/image/bi.png','/image/bb1.png', '/image/bb2.png'],
    type: 'Animals that Fly',
    FanFavorite:"Yes",
    age:"5-12",
    category:"One-time Boxes",
    description:"Buzz into an exciting learning journey with the My ZOO Box Bee EDventure! Discover the incredible world of bees as you explore their life cycle, anatomy, and more through fun-filled STEM projects and activities. Get creative with bee-inspired art and dive into hands-on projects that will have you thinking like a busy bee. This EDventure is the sweetest way to learn all about these amazing pollinators!",

    // Add other product details as needed
  },
  {
    id: 'Bear-EDventure',
    name: 'Bear EDventure',
    image: '/image/bear.png',
    // stock:"In Stock!",
    price: '$39.95',
    link:"https://checkout.myzoobox.com/checkout/add/254996?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    photos: [ '/image/bear1.png', '/image/bear2.png','/image/bear3.png','/image/bear4.png'],
    type: 'Land Animals',
    FanFavorite:"Yes",
    age:"5-12",
    category:"One-time Boxes",
    description:"Get ready for a 'bear'y fun adventure with the My ZOO Box Bear EDventure! Discover the world of brown bears through exciting STEM projects, creative art, and hands-on activities. Learn all about bear anatomy, make fun bear-themed crafts, and cuddle up with your very own brown bear plush. It’s an EDventure packed with wild discoveries and cozy bear fun!",

    // Add other product details as needed
  },
  
  // {
  //   id: 'Lemur-EDventure',
  //   name: 'Lemur EDventure',
  //   image: '/image/lemu.png',
  //   price: '$39.95',
  //   photos: [ '/image/lem1.png', '/image/lem2.png', '/image/lem3.png'],
  //   type: 'Land Animals',
  //   // stock:"In Stock!",
  //   age:"5-12",
  //   link:"https://checkout.myzoobox.com/checkout/add/247020?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
  //   category:"One-time Boxes",
  //   description:"Leap into the Lemur EDventure, delving into the world of these intriguing primates native to Madagascar, known for their long tails and expressive faces. The box features a lemur stuffed animal, an educational science book, an anatomy poster & worksheet, a primate-focused STEM project, and a themed art project. Also included are an exclusive notebook, a lemur postcard, a collectible sticker, a Madagascar-inspired recipe, and QR printables."

    
  //   // Add other product details as needed
  // },
  {
    id: 'Hammerhead-Shark-EDventure',
    name: 'Hammerhead Shark EDventure',
    image: '/image/ham1.png',
    price: '$39.95',
    photos: [ '/image/ham1.png', '/image/ham2.png', '/image/ham3.png', '/image/ham4.png'],
    type: 'Ocean Animals',
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/add/257095?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
    // stock:"In Stock!",
    category:"One-time Boxes",
    description:"Get ready for a fin-tastic adventure with the My ZOO Box Hammerhead Shark EDventure! Dive deep into the world of hammerhead sharks as you explore fascinating facts, hands-on STEM projects, and creative activities. From building your own shark to learning all about their unique anatomy, this EDventure is packed with excitement. It’s the perfect way to unleash your inner shark expert while having oceans of fun!"

  
    // Add other product details as needed
  },
  {  id: 'Chameleon-EDventure',
    name: 'Chameleon EDventure',
    image: '/image/cha.png',
    price: '$39.95',
    photos: [ '/image/cham1.png','/image/cham2.png','/image/cham3.png','/image/cham4.png'],
    type: 'Land Animals',
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/247022",
    category:"One-time Boxes",
    description:"Get ready for a colorful adventure with the My ZOO Box Chameleon EDventure! Learn all about these amazing color-changing reptiles through hands-on STEM projects, creative art, and fascinating facts. Craft your own chameleon art, explore their anatomy, and have fun that will have you blending right in with chameleon knowledge. This EDventure is packed with vibrant fun and surprises for future chameleon experts!"
  },
  {
    id: 'Sea-Lion-EDventure',
    name: 'Sea Lion EDventure',
    image: '/image/li.png',
    price: '$39.95',
    photos: [ '/image/lio1.png', '/image/lio2.png', '/image/lio3.png'],
    type: 'Ocean Animals',
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/add/247026",
    // stock:"In Stock!",
    category:"One-time Boxes",
    description:"Dive into the Sea Lion EDventure, learning about these playful marine mammals known for their barking sounds and agility in water. The box comes with a wild republic sea lion plush, an exclusive comprehensive science book, an anatomy poster & worksheet, a marine mammal STEM project, and an aquatic art project. Extras include an exclusive sea lion puzzle, a sea lion postcard, a collectible sticker, a themed recipe, and QR printables for more discovery. "

    // Add other product details as needed
  },
  {
    id: 'Orangutan-EDventure',
    name: 'Orangutan EDventure',
    image: '/image/aro.png',
    price: '$39.95',
    photos: [ '/image/or1.png', '/image/or2.png'],
    type: 'Land Animals',
    link:"https://checkout.myzoobox.com/checkout/buy/294289",
    age:"5-12",
    category:"One-time Boxes",
    description:"Swing into action with the My ZOO Box Orangutan EDventure! Discover the fascinating world of orangutans through hands-on STEM projects, creative crafts, and exciting activities. Build with mini blocks, learn culture with a batik craft, and cuddle up with your orangutan hugger while learning all about their unique anatomy. This EDventure is packed with wild fun and orangutan facts, perfect for little zoologists!"

    // Add other product details as needed
  },
  {
    id: 'Skunk-EDventure',
    name: 'Skunk EDventure',
    image: '/image/sk.png',
    price: '$39.95',
    photos: [ '/image/sku1.png','/image/sku2.png','/image/sku3.png'],
    type: 'Land Animals',
    link:"https://checkout.myzoobox.com/checkout/buy/53539 ",
    age:"5-12",
    category:"One-time Boxes",
description:"Get ready to sniff out some fun with the My ZOO Box Skunk EDventure! Explore the world of these striped stinkers through exciting STEM projects, creative art, and fascinating facts. From learning all about skunk anatomy to hands-on activities and bonus surprises, this EDventure will make you a skunk expert in no time. It’s a wild and smelly good time you won’t forget!"
,
    showUnit:"skunkzoo",
    // Add other product details as needed
  },
  {
    id: 'Sea Horse EDventure',
    name: 'Sea Horse EDventure',
    image: '/image/seahors.png',
    price: '$39.95',
    photos: [ '/image/hors1.png','/image/hors2.png','/image/hors3.png'],

    type: 'Ocean Animals',
    link:"https://checkout.myzoobox.com/checkout/buy/294302 ",
    age:"5-12",
    category:"One-time Boxes",
description:"Swim into the magical world of the My ZOO Box Sea Horse EDventure! Learn all about these graceful sea creatures through hands-on crafts, exciting STEM projects, and fun-filled activities. Discover their unique anatomy, collect mini sea shells, and create your own sea horse art. This EDventure is packed with ocean fun, perfect for budding marine biologists ready to dive deep into the wonders of sea horses! ",
showUnit:"Sea Horse",
showUnit:"horsezoo"
    // Add other product details as needed
  },
  
  // {
  //   id: 'Fennec-Fox-EDventure',
  //   name: 'Fennec Fox EDventure',
  //   image: '/image/pr10.png',
  //   price: '$39.95',
  //   link:"https://checkout.myzoobox.com/checkout/add/255000?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
  //   photos: [ '/image/Fennecfox.webp', '/image/Fennecfox1.webp', '/image/Fennecfox2.webp'],
  //   type: 'African Animals',
  //   age:"5-12",
  //   // stock:"In Stock!",
  //   category:"One-time Boxes",
  //   description:"Venture into the desert with the Fennec Fox EDventure box, highlighting these small, adorable creatures with distinctive large ears. Inside, you'll find a wild republic fennec fox plush, a fascinating science book, an anatomy poster & worksheet, a desert-themed STEM project, and an art project with supplies. Also enjoy a geode from Morocco, a fennec fox postcard, a collectible sticker, a desert-inspired recipe, and QR printables."

   
  //   // Add other product details as needed
  // },

  
  // {
  //   id: 'Humpback-Whale-EDventure',
  //   name: 'Humpback Whale EDventure',
  //   image: '/image/pr13.png',
  //   price: '$39.95',
  //   photos: [ '/image/humpbackwhale.webp', '/image/humpbackwhale1.webp', '/image/humpbackwhale2.webp'],
  //   type: 'Ocean Animals',
  //   stock:"In Stock!",
  //   age:"5-12",
  //   category:"One-time Boxes",
  //   description:"Embark on a Humpback Whale EDventure to discover these magnificent marine mammals known for their impressive leaps and songs. Included are a plush humpback whale, an exclusive science book, an anatomy poster, and a themed craft project. Extras include a speak like a whale whistle, a whale postcard, a collectible sticker, and a fun themed recipe."

  //   // Add other product details as needed
  // },

 

  {
    id: 'Koala-EDventure',
    name: 'Koala EDventure',
    image: '/image/ko.png',
    price: '$39.95',
    photos: [ '/image/ko.png','/image/koo1.png'],
    type: 'Fan Favorites',
    // stock:"In Stock!",
    age:"5-12",
    link:" https://checkout.myzoobox.com/checkout/buy/247024",
    category:"One-time Boxes",
    description:"Join the Koala Kapers, diving into the cozy and captivating world of koalas, gentle marsupials known for their cuddly charm and eucalyptus diet. This box includes an adorable Wild Republic koala hugger, an engaging science book, an informative anatomy poster, and a creative art project. Plus, enjoy a delightful koala push puppet, a koala postcard, a collectible sticker, and a tasty recipe.",
showUnit:"koalazoo"
    // Add other product details as needed
  },
  {
    id: 'Moose-EDventure',
    name: 'Moose EDventure',
    image: '/image/moos.png',
    price: '$39.95',
    photos: [ '/image/moo1.png','/image/moo2.png','/image/moo3.png'],
    type: 'Land Animals',
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/310564",
    category:"One-time Boxes",
    description:"Get ready for a big adventure with the My ZOO Box Moose EDventure! Explore the world of moose through exciting STEM projects, creative watercolor crafts, and fascinating facts. Jot down your discoveries in your moose notebook, trot into hands-on activities, and learn all about moose anatomy. This EDventure is packed with antlered fun and is perfect for young wildlife enthusiasts!",
    showUnit:"moosezoo",

    // Add other product details as needed
  },

  {
    id: 'Hummingbird-EDventure',
    name: 'Hummingbird EDventure',
    image: '/image/bir.png',
    price: '$39.95',
    photos: [ '/image/hum1.png','/image/hum2.png','/image/hum3.png'],
    type: 'Animals that Fly',
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/247022",
    category:"One-time Boxes",
    description:"Hover into excitement with the My ZOO Box Hummingbird EDventure! Discover the wonders of these tiny birds through creative crafts, engaging STEM projects, and fun puzzles. Learn all about their fascinating anatomy, solve a hummingbird puzzle, and soar into hands-on activities that will make you feel like you're flying. This EDventure is perfect for little zoologists ready to zip through the world of hummingbirds!",
    showUnit:"hummingzoo",

    // Add other product details as needed
  },
  {
    id: 'Tree-Frog',
    name: 'Tree Frog EDventure',
    image: '/image/frog1.png',
    price: '$39.95',
    photos: [ '/image/frog1.png','/image/frog2.png','/image/frog3.png'],
    type: 'Land Animals',
    // stock:"In Stock!",
    age:"5-12",
    link:"https://checkout.myzoobox.com/checkout/buy/247022",
    category:"One-time Boxes",
    description:"Hover into excitement with the My ZOO Box Hummingbird EDventure! Discover the wonders of these tiny birds through creative crafts, engaging STEM projects, and fun puzzles. Learn all about their fascinating anatomy, solve a hummingbird puzzle, and soar into hands-on activities that will make you feel like you're flying. This EDventure is perfect for little zoologists ready to zip through the world of hummingbirds!",
    // showUnit:"Hummingbird",
showUnit:'frogzoo',

    // Add other product details as needed
  },

 

  // {
  //   id: 'Penguin-EDventure',
  //   name: 'Penguin EDventure',
  //   image: '/image/pr16.png',
  //   price: '$39.95',
  //   photos: [ '/image/pr16.png', '/image/Penguin.webp', '/image/Penguin1.webp', '/image/Penguin2.webp'],
  //   type: 'Fan Favorites',
  //   age:"5-12",
  //   stock:"Low Stock Less Than 5",
  //   link:"https://checkout.myzoobox.com/checkout/add/247022",
  //   category:"One-time Boxes",
  //   description:"Slide into the chilly world of penguins with the Penguin EDventure box, celebrating these charismatic birds known for their waddling gait and swimming prowess. Inside, you'll find a wild republic penguin stuffed toy, an informational science book, an anatomy poster, and an penguin-tastic art project. Also included are a hatch penguin egg, a penguin postcard, a collectible sticker, and a yummy recipe."

  //   // Add other product details as needed
  // },
 
  
  //  {
  //   id: 'Stingray-EDventure',
  //   name: 'Stingray EDventure',
  //   image: '/image/pr19.png',
  //   price: '$39.95',
  //   photos: [ '/image/Stingray.webp', '/image/Stingray1.webp', '/image/Stingray2.webp'],
  //   type: 'Ocean Animals',
  //   age:"5-12",
  //   link:"https://checkout.myzoobox.com/checkout/add/247024?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
  //   // stock:"In Stock!",
  //   category:"One-time Boxes",
  //   description:"Glide into the Stingray EDventure, discovering the graceful world of stingrays, flat-bodied rays known for their long, whip-like tails. The box contains a wild republic stingray plush, a detailed science book, an anatomy poster,and an ocean-inspired art project. Plus, enjoy a bucket of ocean figurines, a stingray postcard, a collectible sticker, a coral reef-themed recipe."

  //   // Add other product details as needed
  // },
  // {
  //   id: 'Jaguar-EDventure',
  //   name: 'Jaguar EDventure',
  //   image: '/image/pr20.png',
  //   price: '$39.95',
  //   photos: [ '/image/Jaguar.webp', '/image/Jaguar1.webp', '/image/Jaguar2.webp'],
  //   type: 'Predators',
  //   link:"https://checkout.myzoobox.com/checkout/add/247030?redirect_url=https%3A%2F%2Fmy-zoo-box-64b083bd8eaa8.subbly.me%2Fen%2Fthe-zoo-store%3FpreviewMode%3Dtrue",
  //   age:"5-12",
  //   // stock:"In Stock!",
  //   category:"One-time Boxes"

  //   // Add other product details as needed
  // },
  
  // {
  //   id: 'Kangaroo-EDventure',
  //   name: 'Kangaroo EDventure',
  //   image: '/image/pr22.png',
  //   price: '$39.95',
  //   photos: [ '/image/pr22.png'],
  //   type: 'Predators',
  //   stock:"Low Stock Less Than 10",
  //   link:"https://checkout.myzoobox.com/checkout/add/121029",
  //   age:"5-12",
  //   category:"One-time Boxes",
  //   description:"Discover the world of kangaroos, fascinating marsupials known for their powerful hind legs and unique pouches. Each Kangaroo EDventure box comes with a cuddly wild republic stuffed kangaroo, a book all about kangaroos, an anatomy poster, and a themed art project with all necessary supplies. Plus, enjoy an Australian Animals Tube, a postcard from a kangaroo, a collectible sticker, an authentic Australian recipe.",

  //   // Add other product details as needed
  // },


  {
    id: 'Alligator-Unit-Study-Cub',
    name: 'Alligator Unit Study',
    image: '/image/alc1.png',
    price: '$5.00',
    photos: [ '/image/alc1.png','/image/alc2.png','/image/alc3.png','/image/alc4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/283070",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"alligatorcub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Bee-Unit-Study',
    name: 'Bee Unit Study',
    image: '/image/beec1.png',
    price: '$5.00',
    photos: [ '/image/beec1.png','/image/beec2.png','/image/beec3.png','/image/beec4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/291861",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"beecub",
    unit:"true",
    type: 'Animals that Fly',
   
 
    // Add other product details as needed
  },
  {
    id: 'Koala-Unit-Study-Zoologist',
    name: 'Koala Unit Study ',
    image: '/image/koalzoo1.png',
    price: '$5.00',
    photos: [ '/image/koalzoo1.png', '/image/koalzoo2.png', '/image/koalzoo3.png', '/image/koalzoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:" https://checkout.myzoobox.com/checkout/buy/333275",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"koalazoo",
    unit:"true",
 
    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Luna-Moth-Unit-Study',
    name: 'Luna Moth Unit Study',
    image: '/image/lun1.png',
    price: '$5.00',
    photos: [ '/image/lun2.png', '/image/lun3.png', '/image/lun4.png', '/image/lun3.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333271",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"lunazoo",
    unit:"true",
    type: 'Animals that Fly',

    // Add other product details as needed
  },
  {
    id: 'Butterfly-Unit-Study',
    name: 'Butterfly Unit Study',
    image: '/image/butc1.png',
    price: '$5.00',
    photos: [ '/image/butc1.png','/image/butc2.png','/image/butc3.png','/image/butc4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333188",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"butterflycub",
    unit:"true",
    type: 'Animals that Fly',

    // Add other product details as needed
  },
  {
    id: 'Dolphin-Unit-Study',
    name: 'Dolphin Unit Study',
    image: '/image/dolc1.png',
    price: '$5.00',
    photos: [ '/image/dolc1.png','/image/dolc2.png','/image/dolc3.png','/image/dolc4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333190",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"dolphincub",
    unit:"true",

    type: 'Ocean Animals',

    // Add other product details as needed
  },
  {
    id: 'Macaw-Unit-Study',
    name: 'Macaw Unit Study',
    image: '/image/maczoo1.png',
    price: '$5.00',
    photos: [ '/image/maczoo1.png', '/image/maczoo2.png', '/image/maczoo3.png', '/image/maczoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333277",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"macawzoo",
    unit:"true",
    type: 'Animals that Fly',

    // Add other product details as needed
  },


  {
    id: 'Manatee-Unit-Study',
    name: 'Manatee Unit Study',
    image: '/image/man1.png',
    price: '$5.00',
    photos: [ '/image/man1.png', '/image/man2.png', '/image/man3.png', '/image/man4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333279",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"mantezoo",
    unit:"true",
    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Elephant-Unit-Study',
    name: 'Elephant Unit Study',
    image: '/image/elc1.png',
    price: '$5.00',
    photos: [ '/image/elc1.png','/image/elc2.png','/image/elc3.png','/image/elc4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/292188",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"elephantcub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Fennec-Fox-Unit-Study',
    name: 'Fennec Fox Unit Study',
    image: '/image/fenc1.png',
    price: '$5.00',
    photos: [ '/image/fenc1.png','/image/fenc2.png','/image/fenc3.png','/image/fenc4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333194",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"fenneccub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },
  
    
  {
    id: 'Mantis-Unit-Study',
    name: 'Mantis Unit Study',
    image: '/image/mat1.png',
    price: '$5.00',
    photos: [ '/image/mat1.png', '/image/mat2.png', '/image/mat3.png', '/image/mat4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333268",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"mantis",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },  

  {
    id: 'Meerkat-Unit-Study',
    name: 'Meerkat Unit Study',
    image: '/image/mer1.png',
    price: '$5.00',
    photos: [ '/image/mer1.png', '/image/mer2.png', '/image/mer3.png', '/image/mer4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333281",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"meerkatzoo",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },  
  {
    id: 'Giraffe-Unit-Study',
    name: 'Giraffe Unit Study',
    image: '/image/gir1.png',
    price: '$5.00',
    photos: [ '/image/gir1.png','/image/gir2.png','/image/gir3.png','/image/gir4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333213",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"giraffecub",
    unit:"true",
    
    type: 'Land Animals',
    // Add other product details as needed
  },
 

  

  
  {
    id: 'Flamingo-Unit-Study',
    name: 'Flamingo Unit Study',
    image: '/image/flamc1.png',
    price: '$5.00',
    photos: [ '/image/flamc1.png','/image/flamc2.png','/image/flamc3.png','/image/flamc4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333205",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"flamingcub",
    unit:"true",
    
    type: 'Land Animals',
    // Add other product details as needed
  },
 

  {
    id: 'Moose-Unit-Study',
    name: 'Moose Unit Study',
    image: '/image/moozoo1.png',
    price: '$5.00',
    photos: [ '/image/moozoo1.png', '/image/moozoo2.png', '/image/moozoo3.png', '/image/moozoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333283",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"moosezoo",
    unit:"true",
  
    type: 'Land Animals',
    // Add other product details as needed
  },  
  {
    id: 'Peacock-Unit-Study',
    name: 'Peacock Unit Study',
    image: '/image/pe1.png',
    price: '$5.00',
    photos: [ '/image/pe1.png', '/image/pe2.png', '/image/pe3.png', '/image/pe4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333285",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"peacockzoo",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },  
  {
    id: 'Hippopotamus-Unit-Study-Cub',
    name: 'Hippopotamus Unit Study',
    image: '/image/hip1.png',
    price: '$5.00',
    photos: [ '/image/hip1.png','/image/hip2.png','/image/hip3.png','/image/hip4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:" https://checkout.myzoobox.com/checkout/buy/333231",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"hippocub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Kangaroo-Unit-Study',
    name: 'Kangaroo Unit Study',
    image: '/image/kan1.png',
    price: '$5.00',
    photos: [ '/image/kan1.png','/image/kan2.png','/image/kan3.png','/image/kan4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333221",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"kangcub",
    unit:"true",
  
    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Koala-Unit-Study-Cub',
    name: 'Koala Unit Study ',
    image: '/image/ko1.png',
    price: '$5.00',
    photos: [ '/image/ko1.png','/image/ko2.png','/image/ko3.png','/image/ko4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333215",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"koalacub",
    unit:"true",
    
    type: 'Land Animals',
    // Add other product details as needed
  },
  
  {
    id: 'Porcupine-Unit-Study',
    name: 'Porcupine Unit Study',
    image: '/image/per1.png',
    price: '$5.00',
    photos: [ '/image/per1.png', '/image/per2.png', '/image/per3.png', '/image/per4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333287",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"porcupinezoo",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },  
  
  {
    id: 'Raindeer-Unit-Study',
    name: 'Raindeer Unit Study',
    image: '/image/rain1.png',
    price: '$5.00',
    photos: [ '/image/rain1.png', '/image/rain2.png', '/image/rain3.png', '/image/rain4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333289",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"rainderzoo",
    unit:"true",
  
    type: 'Land Animals',
    // Add other product details as needed
  },  
  {
    id: 'Lion-Unit-Study',
    name: 'Lion Unit Study',
    image: '/image/li1.png',
    price: '$5.00',
    photos: [ '/image/li1.png','/image/li2.png','/image/li3.png','/image/li4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333233",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"lioncub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Orca-Unit-Study',
    name: 'Orca Unit Study',
    image: '/image/orc1.png',
    price: '$5.00',
    photos: [ '/image/orc1.png','/image/orc2.png','/image/orc3.png','/image/orc4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333217",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"orcacub",
    unit:"true",
 
    type: 'Ocean Animals',
  
    // Add other product details as needed
  },

  {
    id: 'Sea-Horse-Unit-Study',
    name: 'Sea Horse Unit Study',
    image: '/image/sh1.png',
    price: '$5.00',
    photos: [ '/image/sh1.png', '/image/sh2.png', '/image/sh3.png', '/image/sh4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/291011",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"horsezoo",
    unit:"true",
 
    type: 'Ocean Animals',

    // Add other product details as needed
  },  
  {
    id: 'Skunk-Unit-Study',
    name: 'Skunk Unit Study',
    image: '/image/skzoo1.png',
    price: '$5.00',
    photos: [ '/image/skzoo1.png', '/image/skzoo2.png', '/image/skzoo3.png', '/image/skzoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/292217",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"skunkzoo",
    unit:"true",
  
    type: 'Land Animals',
    // Add other product details as needed
  },  
  {
    id: 'Panda-Unit-Study',
    name: 'Panda Unit Study',
    image: '/image/pand1.png',
    price: '$5.00',
    photos: [ '/image/pand1.png','/image/pand2.png','/image/pand3.png','/image/pand4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/293333",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"pandacub",
    unit:"true",
   
    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Pangolin-Unit-Study',
    name: 'Pangolin Unit Study',
    image: '/image/pang1.png',
    price: '$5.00',
    photos: [ '/image/pang1.png','/image/pang2.png','/image/pang3.png','/image/pang4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/283068",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"pangolincub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },

  {
    id: 'Tree-Frog-Unit-Study',
    name: 'Tree Frog Unit Study',
    image: '/image/frogzoo1.png',
    price: '$5.00',
    photos: [ '/image/frogzoo1.png', '/image/frogzoo2.png', '/image/frogzoo3.png', '/image/frogzoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333293",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"frogzoo",
    unit:"true",
    
    type: 'Land Animals',
    // Add other product details as needed
  },  
  {
    id: 'Zoology-Unit-Study',
    name: 'Zoology Unit Study',
    image: '/image/zool1.png',
    price: '$5.00',
    photos: [ '/image/zool1.png', '/image/zool2.png', '/image/zool3.png', '/image/zool4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333263",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"zoozoo",
    unit:"true"
    // Add other product details as needed
  },  
  {
    id: 'Penguin-Unit-Study',
    name: 'Penguin Unit Study',
    image: '/image/ping1.png',
    price: '$5.00',
    photos: [ '/image/ping1.png','/image/ping2.png','/image/ping3.png','/image/ping4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333227",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"penguincub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Polar-Bear-Unit-Study',
    name: 'Polar Bear Unit Study',
    image: '/image/pol1.png',
    price: '$5.00',
    photos: [ '/image/pol1.png','/image/pol2.png','/image/pol3.png','/image/pol4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333203",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"polarcub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },
 
  {
    id: 'Alligator-Unit-Study',
    name: 'Alligator Unit Study',
    image: '/image/allizoo1.png',
    price: '$5.00',
    photos: [ '/image/allizoo1.png', '/image/allizoo2.png', '/image/allizoo3.png', '/image/allizoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333243",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"alligatorzoo",

    type: 'Land Animals',
    unit:"true"
    // Add other product details as needed
  },

  {
    id: 'Anteater-Unit-Study',
    name: 'Anteater Unit Study',
    image: '/image/antezoo1.png',
    price: '$5.00',
    photos: [ '/image/antezoo1.png', '/image/antezoo2.png', '/image/antezoo3.png', '/image/antezoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333245",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"anteaterzoo",

    type: 'Land Animals',
    unit:"true"
    // Add other product details as needed
  },
  {
    id: 'Rhino-Unit-Study',
    name: 'Rhino Unit Study',
    image: '/image/rh1.png',
    price: '$5.00',
    photos: [ '/image/rh1.png','/image/rh2.png','/image/rh3.png','/image/rh4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333211",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"Pangolin",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },  
  {
    id: 'Sea-Turtle-Unit-Study',
    name: 'Sea Turtle Unit Study',
    image: '/image/turt1.png',
    price: '$5.00',
    photos: [ '/image/turt1.png','/image/turt2.png','/image/turt3.png','/image/turt4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333209",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"turtlecub",
    unit:"true",

    type: 'Ocean Animals',
  
    // Add other product details as needed
  },  
  
  {
    id: 'Arctic-Fox-Unit-Study',
    name: 'Arctic Fox Unit Study',
    image: '/image/artic1.png',
    price: '$5.00',
    photos: [ '/image/artic1.png', '/image/artic2.png', '/image/artic3.png', '/image/artic4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333247",

    age:"5-12",
    category:"Digital Unit Studies",

    type: 'Land Animals',
    showUnit:"articfoxzoo",
    unit:"true"
    // Add other product details as needed
  },

  {
    id: 'Beetles-Unit-Study',
    name: 'Beetles Unit Study',
    image: '/image/bet1.png',
    price: '$5.00',
    photos: [ '/image/bet1.png', '/image/bet2.png', '/image/bet3.png', '/image/bet4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333266",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"beetleszoo",


   
    type: 'Ocean Animals',
   
    unit:"true"
    // Add other product details as needed
  },

  {
    id: 'Sloth-Unit-Study',
    name: 'Sloth Unit Study',
    image: '/image/sloth1.png',
    price: '$5.00',
    photos: [ '/image/sloth1.png','/image/sloth2.png','/image/sloth3.png','/image/sloth4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333207",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"slothcub",
    unit:"true",

   
    type: 'Land Animals',
    // Add other product details as needed
  },  
  {
    id: 'Great-White-Shark-Unit-Study',
    name: 'Great-White Shark Unit Study',
    image: '/image/wh1.png',
    price: '$5.00',
    photos: [ '/image/wh1.png','/image/wh2.png','/image/wh3.png','/image/wh4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333229",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"sharkcub",

    type: 'Ocean Animals',
   
    unit:"true",
    // Add other product details as needed
  },  

  {
    id: 'Chameleon-Unit-Study',
    name: 'Chameleon Unit Study',
    image: '/image/chamzoo1.png',
    price: '$5.00',
    photos: [ '/image/chamzoo1.png', '/image/chamzoo2.png', '/image/chamzoo3.png', '/image/chamzoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:" https://checkout.myzoobox.com/checkout/buy/333249",

    age:"5-12",
    category:"Digital Unit Studies",

    type: 'Land Animals',
    showUnit:"chameleonzoo",
    unit:"true"
    // Add other product details as needed
  },
  {
    id: 'Chimpanzee-Unit-Study',
    name: 'Chimpanzee Unit Study',
    image: '/image/chim1.png',
    price: '$5.00',
    photos: [ '/image/chim1.png', '/image/chim2.png', '/image/chim3.png', '/image/chim4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:" https://checkout.myzoobox.com/checkout/buy/333251",

    age:"5-12",
    category:"Digital Unit Studies",
   
    type: 'Land Animals',
    showUnit:"chimpanzezoo",
    unit:"true"
    // Add other product details as needed
  },
  {
    id: 'Zebra-Unit-Study',
    name: 'Zebra Unit Study',
    image: '/image/ze1.png',
    price: '$5.00',
    photos: [ '/image/ze1.png','/image/ze2.png','/image/ze3.png','/image/ze4.png'],
    description:"Embark on an exciting educational journey tailored for preschoolers ages 3 to 5. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time. We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, preschoolers not only foster curiosity about animals, develop fine motor skills, and gain exposure to letter forms and sounds but also build math skills and number sense—all while having FUN! Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your preschooler is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities.Come explore with us and ignite a passion for learning that will last a lifetime!  🌿📚",    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333219",
    age:"3-5",
    category:"Digital Unit Studies",
    showUnit:"zebracub",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },  

  {
    id: 'Gorilla-Unit-Study',
    name: 'Gorilla Unit Study',
    image: '/image/gozoo1.png',
    price: '$5.00',
    photos: [ '/image/gozoo1.png', '/image/gozoo2.png', '/image/gozoo3.png', '/image/gozoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:" https://checkout.myzoobox.com/checkout/buy/333251",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"goriallazoo",

    type: 'Land Animals',
    unit:"true"
    // Add other product details as needed
  },
  {
    id: 'Hammerhead-Shark-Unit-Study',
    name: 'Hammerhead Shark Unit Study',
    image: '/image/hamzoo1.png',
    price: '$5.00',
    photos: [ '/image/hamzoo1.png', '/image/hamzoo2.png', '/image/hamzoo3.png', '/image/hamzoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333255",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"hammerheadzoo",
  
    type: 'Ocean Animals',

    unit:"true"
    // Add other product details as needed
  },
  {
    id: 'Hippopotamus-Unit-Study-Zoologist',
    name: 'Hippopotamus Unit Study',
    image: '/image/hipzoo1.png',
    price: '$5.00',
    photos: [ '/image/hipzoo1.png', '/image/hipzoo2.png', '/image/hipzoo3.png', '/image/hipzoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333257",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"hippozoo",
    unit:"true",

    type: 'Land Animals',
    // Add other product details as needed
  },
  {
    id: 'Hummingbird-Unit-Study',
    name: 'Hummingbird Unit Study',
    image: '/image/humzoo1.png',
    price: '$5.00',
    photos: [ '/image/humzoo1.png', '/image/humzoo2.png', '/image/humzoo3.png', '/image/humzoo4.png',],
    description:"Embark on an exciting educational journey tailored for young learners aged 5 to 11. Our multidisciplinary approach to learning takes children on a captivating exploration of the animal kingdom, one creature at a time.We dive deep into the fascinating world of a different animal species, offering a rich array of resources and activities designed to engage and inspire curious minds. From majestic lions to tiny insects, from soaring birds to mysterious sea creatures, our unit studies cover a diverse range of animals, ensuring there's always something new and intriguing to discover.Our curriculum seamlessly integrates various subjects, providing a holistic learning experience. Through interactive lessons, games, and hands-on projects, children not only expand their knowledge of biology and zoology but also strengthen their skills in literacy, mathematics, art, and more.Join us as we unravel the secrets of the animal kingdom, fostering a love for learning and a deep appreciation for the natural world. Whether your child is a budding biologist, an aspiring artist, or simply curious about the creatures with whom we share our planet, our animal unit studies offer an enriching educational adventure for learners of all interests and abilities. Come explore with us and ignite a passion for learning that will last a lifetime! 🌿📚",
    // stock:"In Stock!",
    link:"https://checkout.myzoobox.com/checkout/buy/333259",

    age:"5-12",
    category:"Digital Unit Studies",
    showUnit:"hummingzoo",
    unit:"true",
    type: 'Animals that Fly',
  
   
    // Add other product details as needed
  },
 

];

export default productsData;
