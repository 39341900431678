import React from 'react';
import ProductCard from './productCardV3';
import style from "./shopV3.module.css";

const ProductList = ({ products }) => {
  return (
    <div className={style.productList}>
      {/* Check if there are no matching products */}
      {products.length === 0 ? (
        <p className={style.noProductsMessage}>No products found. Please adjust your filters.</p>
      ) : (
        products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))
      )}
    </div>
  );
};

export default ProductList;
