import React from "react";
import style from "./KidTestimonials.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Navigation,Autoplay} from 'swiper/modules';

const KidsTestimonials = (props) =>{
    const group = [
        {
            imageOne:"/image/k1.webp",
            textOne:"/image/k2.webp" ,
            imageTwo:"/image/k3.webp",
            textTwo:"/image/k4.webp" ,
        }, 
        {
            imageOne:"/image/kt1.webp",
            textOne:"/image/kt2.webp" ,
            imageTwo:"/image/kt3.webp",
            textTwo:"/image/kt4.webp" ,
        }, 
        {
            imageOne:"/image/kt5.webp",
            textOne:"/image/kt6.webp" ,
            imageTwo:"/image/kt7.webp",
            textTwo:"/image/kt8.webp" ,
        }, 
        {
            imageOne:"/image/kt9.webp",
            textOne:"/image/kt10.webp" ,
            imageTwo:"/image/kt11.webp",
            textTwo:"/image/kt12.webp" ,
        }, 
        {
            imageOne:"/image/kt13.webp",
            textOne:"/image/kt14.webp" ,
            imageTwo:"/image/kt15.webp",
            textTwo:"/image/kt16.webp" ,
        }, 
        {
            imageOne:"/image/kt17.webp",
            textOne:"/image/kt18.webp" ,
            imageTwo:"/image/kt7.webp",
            textTwo:"/image/kt8.webp" ,
           }
    ]
    return(
        <div className={`pt50 ${style.kidTesti}`}>
          {props.removeTitle === "remove" ? "" : <h1><span>Over 100,000 EDventures</span><br/> shipped globally...and counting!</h1>}  
            {/* <p className="pb15">Play, learn, and explore while roaring with lions, swinging with monkeys, and discovering the depths of the ocean.</p> */}


            <Swiper
       
    //    spaceBetween={10}
       pagination={{   
         clickable: true,
       }}
       loop={true}
       autoplay={{
         delay: 2000, // set the autoplay delay in milliseconds
         disableOnInteraction: false, // enable autoplay even if user interacts with the slider
       }}
       modules={[Navigation,Autoplay]}
       navigation={true}
       className="mySwiper pt50"
       breakpoints={{
           1200:{
               slidesPerView:3.2,
               spaceBetween: 5,
           },
           768: {
             slidesPerView: 4, 
             spaceBetween: 0,
           },
          
           320: {
             slidesPerView: 1,
             spaceBetween: 0,
           },
         }}
     >
       {group.map(((item,i) =>{
           return(
               <SwiperSlide key={i}>
               <div className={style.kidTestimonialsBox}>
                    <div>
                    <img src={item.imageOne}/>
                   <img src={item.textOne}/>
                    </div>
                   <div>
                   <img src={item.imageTwo}/>
                   <img src={item.textTwo}/>
                   </div>
               </div>
           </SwiperSlide>
           )
       }))}
 
      
     </Swiper>
        </div>
    )
}

export default KidsTestimonials;