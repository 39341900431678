import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './shopV3.module.css';

const Filters = ({ filters, setFilters }) => {
  const [isAgeOpen, setIsAgeOpen] = useState(window.innerWidth <= 768);
  const [isCategoryAnimalsOpen, setIsCategoryAnimalsOpen] = useState(window.innerWidth <= 768);
  const [isCategoryTypeOpen, setIsCategoryTypeOpen] = useState(window.innerWidth <= 768);
  const [isOpen, setIsOpen] = useState(false); // Popup visibility for mobile

  const location = useLocation();

  // Toggle functions
  const toggleAge = () => setIsAgeOpen(!isAgeOpen);
  const toggleCategoryAnimals = () => setIsCategoryAnimalsOpen(!isCategoryAnimalsOpen);
  const toggleCategoryType = () => setIsCategoryTypeOpen(!isCategoryTypeOpen);

  // Handle filter changes
  const handleAgeChange = (age) => {
    if (filters.age.includes(age)) {
      setFilters({
        ...filters,
        age: filters.age.filter((a) => a !== age),
      });
    } else {
      setFilters({
        ...filters,
        age: [...filters.age, age],
      });
    }
  };

  const handleCategoryChange = (type) => {
    if (filters.type.includes(type)) {
      setFilters({
        ...filters,
        type: filters.type.filter((c) => c !== type),
      });
    } else {
      setFilters({
        ...filters,
        type: [...filters.type, type],
      });
    }
  };

  const handleTypeChange = (category) => {
    if (filters.category.includes(category)) {
      setFilters({
        ...filters,
        category: filters.category.filter((t) => t !== category),
      });
    } else {
      setFilters({
        ...filters,
        category: [...filters.category, category],
      });
    }
  };

  const toggleFilterPopup = () => {
    setIsOpen(!isOpen);
  
    // Add or remove the noScroll class to body when the popup is opened or closed
    if (!isOpen) {
      document.body.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
    }
  };
  
  // Clean up when component unmounts to ensure the noScroll class is removed
  useEffect(() => {
    return () => {
      document.body.classList.remove('noScroll');
    };
  }, []);
  // Function to update filters based on query params and open categories
  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const ageParam = query.get('age');
    if (ageParam) {
      const ages = ageParam.split(',');
      setFilters((prevFilters) => ({
        ...prevFilters,
        age: ages,
      }));
      setIsAgeOpen(true);
    }

    const typeParam = query.get('type');
    if (typeParam) {
      const types = typeParam.split(',');
      setFilters((prevFilters) => ({
        ...prevFilters,
        type: types,
      }));
      setIsCategoryAnimalsOpen(true);
    }

    const categoryParam = query.get('category');
    if (categoryParam) {
      const categories = categoryParam.split(',');
      setFilters((prevFilters) => ({
        ...prevFilters,
        category: categories,
      }));
      setIsCategoryTypeOpen(true);
    }
  }, [location.search, setFilters]);

  return (
    <>
      {/* Regular Filter Button */}
      <button className={styles.filterButton} onClick={toggleFilterPopup}>
       <img src="/image/edit.png"/> Filter
      </button>

      {/* Popup Content for Mobile */}
      <div className={`${styles.filtersPopup} ${isOpen ? styles.openPopup : ''}`}>
        {/* Close Button */}
        <button className={styles.closeButton} onClick={toggleFilterPopup}>✕</button>

        <div className={`${styles.filtersContent} ${styles.filters}`}>
          {/* Age Filter */}
          <div className={styles.filterSection}>
            <div className={styles.filterHeader} onClick={toggleAge}>
              <h3>Age</h3>
              <span className={`${styles.arrow} ${isAgeOpen ? styles.arrDown : styles.arrUp}`}>
                <img src='/image/arr.png' alt="toggle" />
              </span>
            </div>
            <div className={`${styles.filterOptions} ${isAgeOpen ? styles.open : styles.closed}`}>
              <label>
                <input
                  type="checkbox"
                  checked={filters.age.includes('3-5')}
                  onChange={() => handleAgeChange('3-5')}
                />
                <div className={styles.agesIcon}>
                  <p>3-5</p>
                  <img src='/image/ci.png' alt="icon" />
                </div>
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.age.includes('5-12')}
                  onChange={() => handleAgeChange('5-12')}
                />
                <div className={styles.agesIcon}>
                  <p>5-12</p>
                  <img src='/image/zi.png' alt="icon" />
                </div>
              </label>
            </div>
          </div>

          {/* Category: Animals Filter */}
          <div className={styles.filterSection}>
            <div className={styles.filterHeader} onClick={toggleCategoryAnimals}>
              <h3>Category</h3>
              <span className={`${styles.arrow} ${isCategoryAnimalsOpen ? styles.arrDown : styles.arrUp}`}>
                <img src='/image/arr.png' alt="toggle" />
              </span>
            </div>
            <div className={`${styles.filterOptions} ${isCategoryAnimalsOpen ? styles.open : styles.closed}`}>
            <label>
              <input
                type="checkbox"
                checked={filters.type.includes('Land Animals')}
                onChange={() => handleCategoryChange('Land Animals')}
              />
              Land Animals
            </label>
            {/* <label>
              <input
                type="checkbox"
                checked={filters.type.includes('Predators')}
                onChange={() => handleCategoryChange('Predators')}
              />
              Predators
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.type.includes('Rainforest Animals')}
                onChange={() => handleCategoryChange('Rainforest Animals')}
              />
              Rainforest Animals
            </label> */}
            <label>
              <input
                type="checkbox"
                checked={filters.type.includes('Animals that Fly')}
                onChange={() => handleCategoryChange('Animals that Fly')}
              />
              Animals that Fly
            </label>
            <label>
              <input
                type="checkbox"
                checked={filters.type.includes('Ocean Animals')}
                onChange={() => handleCategoryChange('Ocean Animals')}
              />
              Ocean Animals
            </label>
            </div>
          </div>

          {/* Category: Type Filter */}
          <div className={styles.filterSection}>
            <div className={styles.filterHeader} onClick={toggleCategoryType}>
              <h3>Type</h3>
              <span className={`${styles.arrow} ${isCategoryTypeOpen ? styles.arrDown : styles.arrUp}`}>
                <img src='/image/arr.png' alt="toggle" />
              </span>
            </div>
            <div className={`${styles.filterOptions} ${isCategoryTypeOpen ? styles.open : styles.closed}`}>
              <label>
                <input
                  type="checkbox"
                  checked={filters.category.includes('One-time Boxes')}
                  onChange={() => handleTypeChange('One-time Boxes')}
                />
                EDventure Boxes
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={filters.category.includes('Digital Unit Studies')}
                  onChange={() => handleTypeChange('Digital Unit Studies')}
                />
                Digital Unit Studies
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
