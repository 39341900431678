import React from "react";
import style from "./About.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { animateScroll } from "react-scroll";


// import required modules
import { Pagination ,Navigation,Autoplay} from 'swiper/modules';
import { Link } from "react-router-dom";
import Newsletter from "../../component/newsletter/Newsletter";
import { Helmet } from "react-helmet";
const About = () =>{
    const swiper = [
        {
            image:"/image/c1.webp"
        },{
            image:"/image/ab2.webp"
        },{
            image:"/image/ab3.webp"
        },{
            image:"/image/ab4.webp"
        }
    ]
    return(
          <>
           <Helmet>
        <title> Kids’ Zoology Subscription Boxes | My ZOO Box</title>
        <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
      </Helmet>
             <div className={style.aboutHeader}>

            
                <h1>Welcome to My ZOO Box</h1>
              <div className={style.aboutCollage}>
                  <img src="/image/a1.webp"/>
                  <img src="/image/aa2.png"/>
                  <img src="/image/aa3.png"/>
                  <img src="/image/aaa3.png"/>
                  
              </div>
             <div className={`container ${style.mobileAbout}`}>
             <Swiper
        slidesPerView={6.7}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation,Autoplay]}
        autoplay={{ // Configure autoplay options
          delay: 4000, // Autoplay delay in milliseconds
          disableOnInteraction: false, // Autoplay continues even when the user interacts with the swiper
        }}
        navigation={true}
        className="mySwiper"
        loop={true}
        
        breakpoints={{
          1200:{
            slidesPerView:2.4,
            spaceBetween: 10,
        },
          768: {
            slidesPerView: 4, 
            spaceBetween: 0,
          },
         
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          
        }}
        
      >
       
   
              <SwiperSlide  ><img src="/image/a1.webp"/></SwiperSlide>
              <SwiperSlide  ><img src="/image/aa2.png"/></SwiperSlide>
              <SwiperSlide  ><img src="/image/aa3.png"/></SwiperSlide>
              <SwiperSlide  ><img src="/image/aaa3.png"/></SwiperSlide>
         
      
  

      </Swiper>
             </div>
              </div>
     
          <div className={style.aboutBck}>
          <div className={`thirdContainer ${style.aboutContent}`}>
               <p>I'm Gracelyn, and my comfy pillow is my amazing mom, Sara. Together, we make the zoo-riffic team behind My ZOO Box.</p>
  <p>

The idea for My ZOO Box all started because I have a huge love for animals. I mean, they're just so cool! So, when my 5th birthday was coming up, I asked my mom for a special animal box. I thought it would be a box filled with all sorts of things about animals that I could explore and learn from. But guess what? My mom searched really hard, but she couldn't find a box like that anywhere. 
The idea for My ZOO Box all started because I have a huge love for animals. I mean, they're just so cool! So, when my 5th birthday was coming up, I asked my mom for a special animal box. I thought it would be a box filled with all sorts of things about animals that I could explore and learn from. But guess what? My mom searched really hard, but she couldn't find a box like that anywhere. </p>

<Swiper
        slidesPerView={6.7}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation,Autoplay]}
        autoplay={{ // Configure autoplay options
          delay: 4000, // Autoplay delay in milliseconds
          disableOnInteraction: false, // Autoplay continues even when the user interacts with the swiper
        }}
        navigation={true}
        className="mySwiper"
        loop={true}
        
        breakpoints={{
          1200:{
            slidesPerView:2.4,
            spaceBetween: 10,
        },
          768: {
            slidesPerView: 4, 
            spaceBetween: 0,
          },
         
          320: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          
        }}
        
      >
        {swiper.map(((item,i) =>{
          return(
              <SwiperSlide key={i} className={style.mobBox}><img src={item.image}/></SwiperSlide>
          )
        }))}
  

      </Swiper>
      <h3>Can you believe it?</h3>
      <p>
But here's the fun part. I had this big idea. I asked my mom to make me an animal box and thought we could share it with other kids too! And just like that, My ZOO Box was born!
</p>
<p>
My mom even gave me a special title, CEO. It stands for Chief Exploring Officer, which means I get to be in charge and make lots of important decisions. Isn't that cool? Together, my mom and I work as a team. I choose which animal we're going to learn about, and we design all the fun stuff that goes inside the box together. It's like going on an adventure every time we create a new box!
But wait, there's more. I have the best job ever. I get to be the box tester! That means I try out all the cool things in the box to make sure they're super fun and exciting. It's like being a scientist on a mission to find the most awesome animal discoveries!
</p>
<p>
Now, we can't wait to share all the amazing animal adventures with your little zoologist. We want them to have the most fantastic time exploring the animal kingdom, just like we do! So, get ready for a wild and wonderful journey together with My ZOO Box.
</p>
<p>
Let's make every learning moment an incredible, animal-filled experience!</p>
<div className={style.aboutButton}>
    <Link to="/contact-us" onClick={() => animateScroll.scrollToTop()}>Get In Touch <img src="/image/icon/arrow.svg"/></Link>
    <Link to="/select-plans" onClick={() => animateScroll.scrollToTop()}>Start Your EDventure <img src="/image/icon/arrow.svg"/></Link>
</div>
          </div>
          </div>
          <Newsletter/>
        </>
    )
}

export default About;