import React, { useRef } from "react";

import style from "./Empower.module.css"
import EmpowerForm from "./EmpowerForm";


const Empower = () =>{
   const section1Ref = useRef(null);
   const section2Ref = useRef(null);

   const scrollToSection = (sectionRef) => {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
   const circle = [
      {
         images:"/image/c1.png",
         title:"Empowering Preservation:",
         description:"We believe in instilling a sense of responsibility for our planet's health. Through EMPOWER, children will learn about conservation, sustainability, and the importance of preserving our natural world. Whether it's creating a butterfly garden, organizing a recycling initiative, or planting trees, we empower them to be stewards of the environment."
      },
      {
         images:"/image/c2.png",
         title:"Empowering Wildlife:",
         description:"Connecting with nature fosters empathy and understanding. EMPOWER encourages children to take an active role in protecting wildlife habitats, supporting animal shelters, and advocating for endangered species. By learning about the intricate balance of our ecosystems, they become empowered ambassadors for the animal kingdom.",
         class:"reverse"
      },
      {
         images:"/image/c3.png",
         title:"Empowering Education:",
         description:"Knowledge is a powerful tool for change. Through EMPOWER, we provide resources, learning experiences, and platforms for children to educate themselves and others. Whether it's creating educational materials, hosting workshops, or initiating awareness campaigns, we empower them to be educators and change agents.",
       
      },
      {
         images:"/image/c4.png",
         title:"Empowering Restoration:",
         description:"When challenges arise, it's essential to take action. EMPOWER teaches children the value of restoration, be it revitalizing a community park, cleaning up local waterways, or participating in habitat restoration projects. By actively contributing to the betterment of their surroundings, they become empowered healers of their environment.",
         class:"reverse"
      },
   ]

   const application = [
      {
         image:"/image/a1.svg",
         title:"1. Project Description:",
         description:"Clearly explain the project, its goals, and how it aligns with the EMPOWER Foundation's mission."
      },
      {
         image:"/image/a2.svg",
         title:"2. Materials Needed:",
         description:"List the materials required to bring the project to life. This could include anything from art supplies and tools to plants and resources."
      },
      {
         image:"/image/a3.svg",
         title:"3. Budget:",
         description:"Provide an estimated budget for the project, showcasing how the micro-grant will be used effectively."
      },
      {
         image:"/image/a4.svg",
         title:"4. How it Empowers:",
         description:"Describe how the project will empower the local community, promote awareness, and create a lasting impact."
      },
      {
         image:"/image/a5.svg",
         title:"5. Personal Statement:",
         description:"Encourage kids to share why this project is important to them and how they envision it making a positive change."
      },
   ]


 
    return(
           <>
              <div className={`container ${style.empower}`}>
               <div className={style.empowerBanner}>
                  <img src="/image/emp.png" className={style.desE}/>
                  <img src="/image/emm.png" className={style.desM}/>
                  <div  className={style.empowerPosition}>
                     <h1>EMPOWER Foundation</h1>
                     <div className={style.empowerBtn}>
                     <p  onClick={() => scrollToSection(section1Ref)}>Learn More <img src="/image/down.png"/></p>
                     <p  onClick={() => scrollToSection(section2Ref)} className={style.secondBtn}>Apply <img src="/image/down.png"/></p>
                     </div>
                  </div>
               </div>

               <div className={style.empowerMinds} ref={section1Ref}>
      
                     <h2>Empowering Minds for Preservation, Opportunity, Wildlife, Education, and Restoration</h2>
                     <p>At My ZOO Box, we've always believed in the power of play and education to shape the minds of future generations. We're thrilled and ready to take our commitment to a higher level - the EMPOWER Foundation. This initiative is born from a deep desire to empower kids to make a positive impact in their local environments.</p>
                  
               </div>

               <div className={style.emopwerKids} >
                  <div>
                     <h2>Empowering Kids to Make a Difference:</h2>
                     <p>The EMPOWER Foundation is a testament to our belief that young minds hold the key to a better future. It all started with a simple yet profound idea - to provide children with the tools they need to bring about meaningful change in their communities. We understand that young minds are full of creativity, passion, and a desire to do good. Our mission is to channel that energy into real-world action, enabling them to take charge and make a difference right where they live.</p>
                  </div>
                  <div>
                     <img src="/image/ki.png"/>
                     </div>
               </div>


               <div className={style.empowerGrants}>
                  <h2>Micro-Grants for Local Projects:</h2>
                  <p>The cornerstone of the EMPOWER Foundation is our micro-grant program. We firmly believe that even the smallest projects can have a big impact. That's why we're inviting kids to submit project proposals outlining their ideas for positive change. These proposals should detail what materials they need and how their project will align with the EMPOWER mission.</p>
                  <p>
EMPOWER is more than just a word; it's a guiding principle that defines our commitment to fostering a brighter, more sustainable future through the actions of our young leaders. Let's break down what EMPOWER stands for and how we are going to empower our children around these core values:</p>
              {circle.map((item) =>{
                     return(
                        <div className={`${style[item.class]} ${style.empowerCircle}`}>
                 
                   <div className={style.circleImg}>
                     <img src={item.images} />
                   </div>
                   <div className={style.circeTxt}>
                       <h2>{item.title}</h2>
                       <p>{item.description}</p>
                   </div>
                </div>
                     )
                  })}
                <p className={style.empowerBottom}>At the heart of EMPOWER is a belief in the innate potential of every child to make a difference. We're dedicated to nurturing their passion, creativity, and leadership skills, providing them with the support and resources needed to turn their dreams of positive change into reality. Through micro-grants, mentorship, and a strong community, we empower children to take meaningful steps towards a better world. EMPOWER isn't just a foundation; it's a movement, and we invite you to join us on this incredible journey of igniting change through empowerment.</p>
               </div>

               <div className={style.empowerApplication}>
                  <h2>The Application Process:</h2>
                  <p>To apply for an EMPOWER micro-grant, children age 5 to 14 can submit a project proposal to our email <a href="mailto:support@myzoobox.com">empower@myzoobox.com</a>. The proposal should include:</p>


                  <div className={style.application}>
                     {application.map(item =>{
                        return(
                           <div className={style.applicationSteps}>
                           <img src={item.image} alt="icon"/>
                           <div>
                              <h3>{item.title}</h3>
                              <p>{item.description}</p>
                              </div>
                        </div>
                        )
                     })}
                    
                  </div>
                  <p>The application can be written, drawn, or even in video form. Adult helpers will fill out a form to go along with their submission but the main application should be completed by the grant applicant in whatever creative form they decide.</p>
               </div>
        </div>

        <div className={style.empowerForm} ref={section2Ref}>
           <div className="container">
              <h1>APPLICATIONS ARE <span>NOW OPEN!</span></h1>

              <EmpowerForm/>  
              

              <div className={style.formBottom}>
               <h2>Empowering Tomorrow's Leaders:</h2>
               <p>The EMPOWER Foundation isn't just about providing financial support. It's about nurturing a sense of responsibility, empathy, and leadership in our young participants. We want them to experience the joy of turning their ideas into reality, learning valuable life skills, and feeling the satisfaction of contributing to a cause greater than themselves.
</p>
               <p>
The EMPOWER Foundation represents a new era for My ZOO Box, where play and purpose come together to shape the future. We invite parents, teachers, and mentors to encourage the kids in their lives to dream big, think creatively, and take action for a better world. Together, we can ignite the spark of change and create a generation of empowered, compassionate, and socially-conscious individuals.
</p>
<p>
Join us on this incredible journey as we empower young minds to become the change-makers our world needs. Together, we can make a lasting impact, one micro-grant at a time.`</p>
              </div>
           </div>

         
        </div>
           </>
    )
}

export default Empower