import React from "react";
import style from "./Toggle.module.css"
import {useLocation} from "react-router-dom";
import { animateScroll } from "react-scroll";
const Siblings = (props) =>{
    const location = useLocation();
    const handleSibClick = (sib) =>{
        // animateScroll.scrollToTop()
        props.data.siblings = sib;
    
        const urlMappings = {
            "Monthly-cub": {
                "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/272422",
                "No thanks": "https://checkout.myzoobox.com/checkout/buy/264413"
            },
            "6Months-cub": {
                "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/272420",
                "No thanks": "https://checkout.myzoobox.com/checkout/buy/264636"
            },
            "12Months-cub": {
                "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/272424",
                "No thanks": "https://checkout.myzoobox.com/checkout/buy/264640"
            }, 
             "6Prepaid-cubGift": {
                "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/286908",
                "No thanks": "https://checkout.myzoobox.com/checkout/buy/286906"
            },
                "6Prepaid-cub": {
                "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/286908",
                "No thanks": "https://checkout.myzoobox.com/checkout/buy/286906"
            }, 
            "12Prepaid-cubGift": {
                "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/286898",
                "No thanks": "https://checkout.myzoobox.com/checkout/buy/286900"
           },
               "12Prepaid-cub": {
               "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/286898",
               "No thanks": "https://checkout.myzoobox.com/checkout/buy/286900"
           }
        };
        const url = urlMappings[props.data.plan]?.[sib];
    
        if (url) {
            window.location.href = url;
        }else{
            props.next()

        }
    
   }
    return(
        <div >
            <div className={style.siblings}>
                <div className={style.sibContent}>
                    <h1>Have more than one <br/>Little   {location.pathname === "/cub-plan" ?"Cub": "Zoologist"}?</h1>
                    <p>Keep the entire family engaged in the EDventures <b>for just $24.00 per sibling pack.</b>  {location.pathname === "/cub-plan" ?"Every sibling pack contains our signature stuffed animal, sticker, anatomy coloring sheet and dramatic play item!": "Every sibling pack contains our signature stuffed animal, sticker, craft, anatomy coloring sheet and the bonus surprise item!"}</p>
                    <p>Sibling packs are designed for little zoologists within the same household and come packed inside your My ZOO Box.</p>
                   <div  className={style.sibButton}>
                   <div onClick={() => handleSibClick("1 Sibling")}>
                        <h3>YES! Add 1 Sibling Pack</h3>
                        <p class="greenColor">
                                        Perfect for 2 Little  {location.pathname === "/cub-plan" ?"Cubs": "Zoologists"}
                                    </p>
                                    <p>+$24/month</p>
                    </div>
                    {location.pathname !== "/cub-plan" && (<div onClick={() => handleSibClick("2 Sibling")}>
                        <h3>YES! Add 2 Sibling Pack</h3>
                        <p class="greenColor">
                                        Perfect for 4 Little Zoologists
                                    </p>
                                    <p>+$48/month</p>
                    </div>)}
                    
                    <p className={style.noThanks} onClick={() => handleSibClick("No thanks")}>No thanks</p>
                   </div>
                </div>
                <div className={style.sibImage}>
                    <img src="https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/rectangle-383-1-16940029428642.png?1694002942538"/>
                </div>
            </div> 
        </div>
    )
}


export default Siblings