import React from "react";
import { Link } from "react-router-dom";
import style from "./MainShop.module.css"
import productsData from './ProductsData';
import { animateScroll } from "react-scroll";

const FanFavorite = (props) =>{
    return (
           <>
           <div className={style.fanHeader}>
            <h2>{props.title}</h2>
            <Link to='/product-list'  onClick={() => animateScroll.scrollToTop()}>View All</Link>
           </div>
               <div className={style.filterBoxDis}>
              {productsData.map(product => (
              
              <>
              {product.FanFavorite &&  <Link to={`/product/${product.id}` } key={product.id} className={style.filterBox}  onClick={() => animateScroll.scrollToTop()}>
              <span className={style.productStock}>{product.stock}</span>
              <span className={`${style.productAge} ${product.age === '3-5' ? style.cubAge : ""}`}>{product.age === '3-5' ? <img src="/image/icon/cubb.svg" /> : <img src="/image/icon/zooo.svg" />}Ages {product.age}</span>

    <img src={product.image} alt={product.name} />
     <div className={style.priceDis}>
     <h3>{product.name}</h3>
    <p>{product.price}</p>
     </div>
     <button className={style.boxView}>
      Learn More
     </button>
  </Link> }
               
              </>
          
          ))}
             </div>
           </>
    )
}

export default FanFavorite;