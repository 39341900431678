import React from "react";
import style from "./StepForm.module.css"

const StepSiblings = (props) =>{
    const handleSibClick =(sib) =>{
        props.data.siblings = sib
       props.next()
    }
    return(
        <div >
        <div className={style.siblings}>
            <div className={style.sibContent}>
                <h1>Have more than one <br/>Little   {props.data.clubSelect === "cubclub" ?"Cub": "Zoologist"}?</h1>
                <p>Keep the entire family engaged in the EDventures <b>for just $24.00 per sibling pack.</b>  {props.data.clubSelect === "cubclub"  ?"Every sibling pack contains our signature stuffed animal, sticker, anatomy coloring sheet and dramatic play item!": "Every sibling pack contains our signature stuffed animal, sticker, craft, anatomy coloring sheet and the bonus surprise item!"}</p>
                <p>Sibling packs are designed for little zoologists within the same household and come packed inside your My ZOO Box.</p>
               <div  className={style.sibButton}>
               <div onClick={() => handleSibClick("1 Sibling")}>
                    <h3>YES! Add 1 Sibling Pack</h3>
                    <p class="greenColor">
                                    Perfect for 2 Little  {props.data.clubSelect === "cubclub" ?"Cubs": "Zoologists"}
                                </p>
                                <p>+$24/month</p>
                </div>
                {props.data.clubSelect === "cubclub" ?
               <div onClick={() => handleSibClick("2 Sibling")}>
                    <h3>YES! Add 2 Sibling Pack</h3>
                    <p class="greenColor">
                                    Perfect for 4 Little Zoologists
                                </p>
                                <p>+$48/month</p>
                </div>
                 : ""}
                <p className={style.noThanks} onClick={() => handleSibClick("No thanks")}>No thanks</p>
               </div>
            </div>
            <div className={style.sibImage}>
                <img src="https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/rectangle-383-1-16940029428642.png?1694002942538"/>
            </div>
        </div> 
    </div>
    )
}

export default StepSiblings