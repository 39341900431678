import React from "react";
import Plans from "./Plans";
import { Helmet } from "react-helmet";


const CubPlans = () =>{
    const box = [
        {
            ribbonOne:"Flexible",
            ribbonTwo:"Save $9.95",
            plan:"Monthly EDventures",
            text:"All the flexibility and all the fun included. Paid Monthly.",
            price:"$37.95/month",
            checkout:"Monthly-cub",
            button:"Start a monthly EDventure",
            code:"",
            info:"Didn’t love it? You can cancel anytime.",
           cartImg:"/image/cubm.png"
          
          },
          {
            ribbonOne:"Most Popular",
            colorBck:"colorOne",
            border:"colorBorder",
            ribbonTwo:"Save $65.70",
            plan:"6 Months of EDventures",
            text:"Make learning a fun & educational journey for your preschooler. Paid Monthly.",
            price:"$36.95/month",
            checkout:"6Months-cub",
            button:"Start a 6 month EDventure",
            code:`First Box Free with code `,
            info:"Continues to renew monthly after the initial 6 months of EDventures",
            infoShow:"treu",
            cartImg:"/image/cub6.png"
          },
          {
            ribbonOne:"Best Value",
            ribbonTwo:"Save $143.40",
            colorBck:"colorTwo",
            border:"colorBorder",
            plan:"12 Months of EDventures",
            text:"Bring the full learning experience home with year-worth of hands-on learning activities!",
            price:"$35.95/month",
            checkout:"12Months-cub",
            button:"Start a 12 month EDventure",
            code:`First Box Free with code `,
            info:"Continues to renew monthly after the initial 12 months of EDventures",
            infoShow:"treu",
            cartImg:"/image/cub12.png"
          },
       
       ]
       const giftPlans = [
        
        {
          ribbonOne:"Most Popular",
          colorBck:"colorOne",
          border:"colorBorder",
          ribbonTwo:"Save $65.70",
          plan:"6 Months of EDventures",
          text:"Add fun to your kids science, biology, zoology and reading classes. ",
          price:"$36.95/month",
          checkout:"6Prepaid-cubGift",
          button:"Start a 6 month EDventure",
          code:`First Box Free with code `,
          totalPrice:"$221.70 paid upfront",
          info:"Does not Renew. Cancellation email sent after purchase",
          prepaid:"prepaid6"
        },          
        {
          ribbonOne:"Best Value",
          ribbonTwo:"Save $143.40",
          colorBck:"colorTwo",
          border:"colorBorder",
          plan:"12 Months of EDventures",
          text:"Use My ZOO Box as a core part of your kids curriculum.",
          price:"$35.95/month",
          checkout:"12Prepaid-cubGift",
          button:"Start a 12 month EDventure",
          code:`First Box Free with code `,
          totalPrice:"$431.40 paid upfront",
          info:"Does not Renew. Cancellation email sent after purchase",
          prepaid:"prepaid12"
        },
      
     ]
       const header ={
        title:"Cub Club Plan ",
        text:'Shipping is only charged for the for your first box when using promo code "FREEBOX". There are no shipping fees for other EDventures. ',
        textTwo:' Your first box ships the first two weeks of each month if the order is placed before the 1st of said month',
        icon:"/image/icon/cub.svg",
        remove:""
      }
      const boxTwo = [
        
        {
          ribbonOne:"Most Popular",
          colorBck:"colorOne",
          border:"colorBorder",
          ribbonTwo:"Save $65.70",
          plan:"6 Months of EDventures",
          text:"Add fun to your kids science, biology, zoology and reading classes. ",
          price:"$36.95/month",
          checkout:"6Prepaid-cub",
          button:"Start a 6 month EDventure",
          code:`First Box Free with code `,
          totalPrice:"$221.70 paid upfront",
          info:"Does not Renew. Cancellation email sent after purchase",
          prepaid:"prepaid6",
          cartImg:"/image/cubp.png"
        },          
        {
          ribbonOne:"Best Value",
          ribbonTwo:"Save $143.40",
          colorBck:"colorTwo",
          border:"colorBorder",
          plan:"12 Months of EDventures",
          text:"Use My ZOO Box as a core part of your kids curriculum.",
          price:"$35.95/month",
          checkout:"12Prepaid-cub",
          button:"Start a 12 month EDventure",
          code:`First Box Free with code `,
         totalPrice:"$431.40 paid upfront",
         prepaid:"prepaid12",
         info:"Does not Renew. Cancellation email sent after purchase",
         cartImg:"/image/cubp12.png"
        },
      
     ]


      const plansImage = {
        bigImage:"/image/b1.webp",
        small:[
          {
            image:"/image/shirt.webp"
          },
          {
            image:"/image/s2.webp"
          },
          {
            image:"/image/s3.webp"
          },
          {
            image:"/image/s4.webp"
          }
        ]
      }

    return(
        <>
          <Helmet>
        <title>Preschool in a Box: Zoology Subscription for the Youngest Learners</title>
        <meta name="description" content="Our Cub Club Boxes Blend Animals and Learning into the Perfect Preschool Adventure. Seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month!" />
      </Helmet>
        <Plans box={box} header={header} image={plansImage}  boxTwo={boxTwo} gift={giftPlans}/>
        </>
    )
}

export default CubPlans;