import React, { useState } from "react";
import StepName from "./StepName";
import StepEmail from "./StepEmail";
import StepPlans from "./StepPlans";
import StepSiblings from "./StepSiblings";
import StepAddon from "./StepAddon";
import style from "./StepForm.module.css"
import { animateScroll } from "react-scroll";
const StepForm = () =>{
    const [step,setStep] = useState(0);
    const [data, setData] = useState({
        childName: "",
        childSurname: "",
        clubSelect: "",
        email: "",
        plans: "",
        siblings: "",
        addon: "",
       
      
    });
    const [addName, setAddName] = useState("");

        console.log("dataaaa",data)
  const handleNextStep = (name, value, final = false) => {
    // setData((prev) => ({ ...prev, [name]: value }));
    const newData = { ...data, [name]: value };
    // if (final) {
    //   // makeRequest(newData);
    //   return;
    // }
    // saveFormDataToLocalStorage(newData);
    animateScroll.scrollToTop()
    setStep((prev) => prev + 1);


  };
  const handlePickStep = (name, value, final = false) => {
    // setData((prev) => ({ ...prev, [name]: value }));
    const newData = { ...data, [name]: value };
    // if (final) {
    //   // makeRequest(newData);
    //   return;
    // }
    animateScroll.scrollToTop()
    setStep((prev) => prev -1);   
    //  saveFormDataToLocalStorage(newData);
  };

  const updateAddName = (name) => {
    setAddName(name);
  };
  const currentStep =[
    <StepName next={handleNextStep}  data={data}  updateAddName={updateAddName}/>,
    <StepEmail next={handleNextStep}  data={data}/>,
    <StepPlans next={handleNextStep}  data={data}/>,
    <StepSiblings next={handleNextStep}  data={data}/>,
    <StepAddon next={handleNextStep}  data={data}/>
]
 
    return(
        <div className={style.formBck}>
      
        <div className={style.stepNum}>
        <div>{step === 0 ? <img src="/image/eAc.svg" /> : <img src="/image/eacf.svg" />}</div>
          <div>{step <= 0 ? <img src="/image/ln.svg" /> : step === 1 ? <img src="/image/lna.svg" />  : <img src="/image/lnaf.svg" /> } </div>
          <div>{step <= 1 ? <img src="/image/sh.svg" /> : step === 2 ? <img src="/image/sha.svg" />  : <img src="/image/shaf.svg" /> } </div>
          <div>{step <= 2 ? <img src="/image/k.svg" /> : step === 3 ? <img src="/image/ka.svg" />  : <img src="/image/kaf.svg" /> } </div>
          <div>{step <= 3 ? <img src="/image/b.svg" /> : step === 4 ? <img src="/image/ba.svg" />  : <img src="/image/ba.svg" /> } </div>
        </div>
         <div className="container">
   
         <div className={style.stepsContainer}>
         {step > 1 ? "" :   <div className={style.boxPos}>
          <div className={style.boxName}>
            <img src="/image/box.png"/>
            <div>
                <p>Special delivery for:</p>
                <h3 className={style.addName}>{addName}</h3>
            </div>
           </div>
          </div>    }
            {currentStep[step]}
            {step > 0 &&   <p className={style.back} onClick={handlePickStep}>Go back</p>}
            </div>
         </div>
        </div>
    )
}

export default StepForm