import React, { useState } from "react";
import style from "./MainShop.module.css"
import Newsletter from "../../component/newsletter/Newsletter";
import KidsTestimonials from "../../component/kidTestimonials/KidTestimonials";
import FanFavorite from "./FanFavorite";
import productsData from './ProductsData';
import { Link } from "react-router-dom";
import ProductType from "./ProductType";
import { animateScroll } from "react-scroll";
import Button from "../../component/button/button";

const MainShop = () =>{
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
  
    const handleChange = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      if (value === '') {
        setSearchResults([]);
        setShowDropdown(false);
        return;
      }
      const results = productsData.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(results);
      setShowDropdown(true);
    };
  
    const toggleDropdown = () => {
      if (!showDropdown && searchTerm === '') {
        setSearchResults(productsData); // Show all results if search term is empty
      }
      setShowDropdown(!showDropdown); // Toggle dropdown visibility
    };
  
    return(
           <>
             <div className="container">
             <Link to="/product-list"  onClick={() => animateScroll.scrollToTop()} className={style.shopStem}>
              {/* <div>
                <h2>Shop STEM, Zoology, Art, and FUN!</h2>
                <p>Discover Past EDventures from the ZOO Store.</p>
                <Button dynamicClass="fillCubColor" text="Shop All" link="/shop"/>

              </div> */}
            <img src="/image/des.webp" className={style.shopDes}/>
            <img src="/image/mob.webp" className={style.shopMob}/>

            {/* <Link to="/product-list">Shop All</Link> */}
            </Link>
           
          

           
             </div>
             <div className={style.searchProduct}>
                <div className="container">
              <div className={style.searchDis}>
              <input
        type="text"
        placeholder="Search Past EDventures..."
        value={searchTerm}
        onChange={handleChange}
      />
      <div  onClick={toggleDropdown}>
        <img src="/image/search.svg"/>
      </div>
              </div>
              {showDropdown && (
        <div className={style.dropdown}>
          {searchResults.length > 0 ? (
            searchResults.map((product) => (
              <div key={product.id} className="dropdown-item">
                <Link to={`/product/${product.id}`} onClick={() => animateScroll.scrollToTop()}>{product.name}</Link>
              </div>
            ))
          ) : (
            <div className="dropdown-item">
              No results found for "{searchTerm}"
            </div>
          )}
        </div>
      )}
                </div>
             </div>
             <div className="container">
             <FanFavorite title='Almost Gone'/>
                <ProductType/>
             <div className={style.wildLife}>
              <div>
                <h2>Explore Our Wildlife Subscription Box</h2>
                <p>A monthly delivery of animal EDventures, delivered straight to your door. </p>
                <Button dynamicClass="fillCubColor" text="Learn More" link="/select-plans"/>

              </div>
              <img src="/image/seconf.png" className={style.shopDes}/>
              <img src="/image/stem.png" className={style.shopMob}/>

             </div>
             </div>
             
            <KidsTestimonials/>
           <div className={style.shopLetter}>
           <Newsletter/>
           </div>
           </>
    )
}

export default MainShop