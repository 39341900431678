import React, { useState } from 'react';

import styles from './MainCart.module.css';
import Product from './products';
import ShoppingCart from './shoopingCart';

const MainCart = () => {
  const [cart, setCart] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const addToCart = (product) => {
    const existingItem = cart.find(item => item.id === product.id);
    if (existingItem) {
      setCart(cart.map(item => 
        item.id === product.id ? {...item, quantity: item.quantity + 1} : item
      ));
    } else {
      setCart([...cart, {...product, quantity: 1}]);
    }
  };

  // Define your products array here
  const products = [
    {
      id: 1,
      name: 'Product 1',
      image: 'https://via.placeholder.com/150',
      price: '$10.00'
    },
    {
      id: 2,
      name: 'Product 2',
      image: 'https://via.placeholder.com/150',
      price: '$15.00'
    },
    {
      id: 1,
      name: 'Product 3',
      image: 'https://via.placeholder.com/150',
      price: '$10.00'
    },
    {
      id: 2,
      name: 'Product 4',
      image: 'https://via.placeholder.com/150',
      price: '$15.00'
    },
    
    // Add more products as needed
  ];

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.productList}>
        {products.map(product => (
          <Product
            key={product.id}
            name={product.name}
            image={product.image}
            price={product.price}
            addToCart={() => {
              setSelectedProduct(product);
            }}
          />
        ))}
      </div>
      <div className={`${styles.addToCart} ${!selectedProduct && styles.hidden}`}>
        {selectedProduct && (
            <>
            <div className={styles.cartHeading}>
              <img src='/image/add.svg' onClick={() => setSelectedProduct(!selectedProduct)}/>
              <h2>My Cart</h2>
            </div>
               <div>
                <div className={styles.selectedBox}>
                 <div className={styles.selectedImg}>
                 <img src={selectedProduct.image} alt={selectedProduct.name} className={styles.selectedImage} />
                 </div>
                 <div className={styles.selectedName}>
                 <h2>{selectedProduct.name}</h2>
               <p>  Add fun to your kids science, biology, zoology and reading classes. Paid Monthly.</p>
                
                 </div>
                 <div className={styles.selectedPrice}>
                 <p>{selectedProduct.price}</p>
                 <span>1 box per month</span>
                 </div>
                </div>
               
          </div>

          <h3>Customize Your Subscription</h3>
          <div className={styles.cartSib}>
          <div className={styles.sibImage}>
              <img src='https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/rectangle-383-1-16940029428642.png?1694002942538'/>
              </div>
              <div className={styles.sibContent}>
                <h3>Have more than one Little Cub?</h3>
                <p>Keep the entire family engaged in the EDventures for just $24.00 per sibling pack. 1 Sibling Pack is perfect for up to 2 little Zoologists, whereas the 2 sibling pack can accommodate up to 4!</p>
                <div className={styles.cartSibBtn}>
                  <p>Add 1 Sibling Pack</p>
                  <p>Add 1 Sibling Pack</p>
                </div>
              </div>
          </div>


          <div className={styles.cartSib}>
              <div className={styles.sibImage}>
              <img src='https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/new-book-addon-image-16962812168155.png?1696281222449'/>
              </div>
              <div  className={styles.sibContent}>
                <h3>Boost the learning!</h3>
                <p>Add a SECOND BOOK to your monthly EDventure! An easy reader all about the animal of the month to encourage your new reader to love reading and learning for only $7.95.</p>
                <div className={styles.cartSibBtn}>
         
                      <p>Easy Reader Added</p>              
                </div>
              </div>
          </div>
          </>
        )}
      </div>
      {/* <div className={styles.shoppingCart}>
        <ShoppingCart cart={cart} />
      </div> */}
    </div>
  );
};

export default MainCart;
