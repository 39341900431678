import React, { useEffect, useRef, useState } from "react";
import style from "./PlanToggle.module.css"

import { useLocation} from "react-router-dom";
import disableScroll from "disable-scroll";

const PlanToggle = (props) =>{
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isTabClicked, setIsTabClicked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
    const [isToggled, setToggled] = useState(null);
    const [isOpen,setOpen] = useState(false)
    const [time, setTime] = useState({ minutes: 7, seconds: 0 });
    const [expired, setExpired] = useState(false);
    const [selectedData, setSelectedData] = useState('kids');
    const [info,setInfo] = useState(false)
    const ref = useRef(null);
      useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const isGift = urlParams.get('gift');
    
        if (isGift === 'true') {
          setSelectedData('gift');
        }
        const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target) && event.target.tagName !== "IMG") {
            setInfo(false);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref]); // empty dependency array ensures this effect runs only once when the component mounts
      const [selectedTab, setSelectedTab] = useState(null);
  const [tabData, setTabData] = useState('No thanks');
  const [addOn, setAddOn] = useState("No");
      // Your component code here
      const location = useLocation();
    const handleToggle = () => {
        setToggled(!isToggled);
      };
      const handleClick = (selected) => {
        setIsClicked(!isClicked);
          if(isClicked){
    
            setAddOn("No")
          }else{
            setAddOn(selected)
          }
      };
      const handleTabClick = (tab, data) => {
        if (selectedTab === tab) {
          setSelectedTab(null);
          setTabData(null);
        } else {
          setSelectedTab(tab);
          setTabData(data);
        }
      };

      const handlePlanClick = (select,index) =>{
        props.data.plan = select
        console.log("aaaa",props.data)
        setOpen((prevIndex) => (prevIndex === index ? null : index))
        // setOpen(!isOpen)\
        // setInfo(prevInfo => !prevInfo);
    
   }
   useEffect(() => {
    if (selectedProduct) {
      const timer = setInterval(() => {
        setTime(prevTime => {
          if (prevTime.minutes === 0 && prevTime.seconds === 0) {
            clearInterval(timer);
            setExpired(true); // Set expired to true when countdown finishes
            return prevTime;
          } else if (prevTime.seconds === 0) {
            return { minutes: prevTime.minutes - 1, seconds: 59 };
          } else {
            return { ...prevTime, seconds: prevTime.seconds - 1 };
          }
        });
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setTime({ minutes: 7, seconds: 0 });
      setExpired(false); // Reset expired state when countdown is reset
    }
  }, [selectedProduct]);

  const displayText = expired ? "Expired" : `${time.minutes}:${time.seconds < 10 ? '0' : ''}${time.seconds}`;

   const handleGiftToggle = (text) => {
    setSelectedData(text);
    
  };
 
  const handleCheckout = () =>{
 
    const urlMappings = {
      "Monthly-cub": {
        "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/272422",
        "No thanks": "https://checkout.myzoobox.com/checkout/buy/264413"
    },
    "6Months-cub": {
        "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/272420",
        "No thanks": "https://checkout.myzoobox.com/checkout/buy/264636"
    },
    "12Months-cub": {
        "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/272424",
        "No thanks": "https://checkout.myzoobox.com/checkout/buy/264640"
    }, 
     "6Prepaid-cubGift": {
        "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/331978",
        "No thanks": "https://checkout.myzoobox.com/checkout/buy/321987"
    },
        "6Prepaid-cub": {
        "1 Sibling": " https://checkout.myzoobox.com/checkout/buy/331978",
        "No thanks": "https://checkout.myzoobox.com/checkout/buy/321987"
    }, 
    "12Prepaid-cubGift": {
        "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/331983",
        "No thanks": "https://checkout.myzoobox.com/checkout/buy/321989"
   },
       "12Prepaid-cub": {
       "1 Sibling": "https://checkout.myzoobox.com/checkout/buy/331983",
       "No thanks": "https://checkout.myzoobox.com/checkout/buy/321989"
   },
        "Monthly-zoo": {
            "1 Sibling": {
                "No": "https://checkout.myzoobox.com/checkout/buy/260475",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/260495"
            },
            "2 Sibling": {
                "No": "https://checkout.myzoobox.com/checkout/buy/260485",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/260491"
            },
            "No thanks": {
                "No": "https://checkout.myzoobox.com/checkout/buy/261977",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/260388"
            }
        },
        "6Months-zoo": {
            "1 Sibling": {
                "No": "https://checkout.myzoobox.com/checkout/buy/264722",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/264730"
            },
            "2 Sibling": {
                "No": "https://checkout.myzoobox.com/checkout/buy/264714",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/264706"
            },
            "No thanks": {
                "No": "https://checkout.myzoobox.com/checkout/buy/264698",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/264688"
            }
        },
        "12Months-zoo": {
            "1 Sibling": {
                "No": "https://checkout.myzoobox.com/checkout/buy/264726",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/264734"
            },
            "2 Sibling": {
                "No": "https://checkout.myzoobox.com/checkout/buy/264718",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/264710"
            },
            "No thanks": {
                "No": "https://checkout.myzoobox.com/checkout/buy/264702",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/264692"
            }
        },
        "6Prepaid-zoo": {
            "1 Sibling": {
                "No": "https://checkout.myzoobox.com/checkout/buy/331998",
                "Yes": " https://checkout.myzoobox.com/checkout/buy/331995 "
            },
            "2 Sibling": {
                "No": " https://checkout.myzoobox.com/checkout/buy/331992 ",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/331988"
            },
            "No thanks": {
                "No": " https://checkout.myzoobox.com/checkout/buy/321492 ",
                "Yes": " https://checkout.myzoobox.com/checkout/buy/332001"
            }
        },
        "12Prepaid-zoo": {
            "1 Sibling": {
                "No": " https://checkout.myzoobox.com/checkout/buy/332020",
                "Yes": " https://checkout.myzoobox.com/checkout/buy/332017"
            },
            "2 Sibling": {
                "No": "https://checkout.myzoobox.com/checkout/buy/332013",
                "Yes": "https://checkout.myzoobox.com/checkout/buy/332007 "
            },
            "No thanks": {
                "No": "https://checkout.myzoobox.com/checkout/buy/321488 ",
                "Yes": " https://checkout.myzoobox.com/checkout/buy/332023 "
            }
        },
        "6Prepaid-zooGift": {
          "1 Sibling": {
            "No": "https://checkout.myzoobox.com/checkout/buy/331998",
            "Yes": " https://checkout.myzoobox.com/checkout/buy/331995 "
        },
        "2 Sibling": {
            "No": " https://checkout.myzoobox.com/checkout/buy/331992 ",
            "Yes": "https://checkout.myzoobox.com/checkout/buy/331988"
        },
        "No thanks": {
            "No": " https://checkout.myzoobox.com/checkout/buy/321492 ",
            "Yes": " https://checkout.myzoobox.com/checkout/buy/332001"
        }
        },
        "12Prepaid-zooGift": {
          "1 Sibling": {
            "No": " https://checkout.myzoobox.com/checkout/buy/332020",
            "Yes": " https://checkout.myzoobox.com/checkout/buy/332017"
        },
        "2 Sibling": {
            "No": "https://checkout.myzoobox.com/checkout/buy/332013",
            "Yes": "https://checkout.myzoobox.com/checkout/buy/332007 "
        },
        "No thanks": {
            "No": "https://checkout.myzoobox.com/checkout/buy/321488 ",
            "Yes": " https://checkout.myzoobox.com/checkout/buy/332023 "
        }
        },
       
    };
  const urlMappingsMini = {
    "Mini-zoo": {
        "No": "https://checkout.myzoobox.com/checkout/buy/327969",
        "Yes": "https://checkout.myzoobox.com/checkout/buy/329150"
}
  }
    if(location.pathname === "/cub-plan" || location.pathname === "/gifting-cub-club"){
      const url = urlMappings[props.data.plan]?.[tabData];
      if (url) {
        window.location.href = url;
    }
    }else{
      const url = urlMappings[props.data.plan]?.[tabData]?.[addOn];
     const urlTwo =  urlMappingsMini[props.data.plan]?.[addOn];
     if (url) {
      window.location.href = url;
  }else if(urlTwo){
      window.location.href = urlTwo;
  }
    }
    
  


  



   
}

   console.log("aaaaaaaaaa",props.data.plan,tabData,addOn,selectedProduct)

   const showInfo = () => {
    setInfo(prevInfo => !prevInfo); // Toggle the state based on its previous value
    setOpen(0)
  
  };

  //  const addToCart = (product) => {
  //   setSelectedProduct(product);
  // }
const removeSelectedTabs = () =>{
  setSelectedProduct(!selectedProduct);
  setAddOn('No')
  setTabData("No thanks")
  setSelectedTab(null)
  setIsClicked(false)
}

// selectedProduct ? disableScroll.on() : disableScroll.off();
 
    return(
      
             <>
  {/* {selectedProduct &&
               <div className={style.overlay} onClick={ removeSelectedTabs}></div>
} */}
<div className={`${style.planToggle}`} >

    <div className={style.planHeading}>
    <h1>{props.giftPlans === "giftPlans" ? "Gift" : "Pick"} a  <span className={props.header.title === "Zoologist Club Plan " ? style.addBck : ""}><img src={props.header.icon}/>{props.header.title}</span></h1>
    <p>{props.header.text}</p>
    <p className="pt20">{props.header.textTwo}</p>
    </div>
 
    {/*Plans Toggle */}
    {props.giftPlans === "giftPlans" ? 
    
    <div className={style.planToggDis}>
    {props.gift.map(((item,index) =>{
        return(
        <div className={`${style.toggleBoxes} ${style[item.border]}`} onClick={() => handlePlanClick(item.checkout,index)} >
          {item.code ?     <span className={style.code}>First Box Free with code <span>FREEBOX</span></span>: ""}
        
         <div className={`${style.toggleContent} ${style[item.colorBck]}`}>
           <div>
            {item.ribbonOne && <> <span>{item.ribbonOne}</span>
              <span>{item.ribbonTwo}</span></>}
          
           </div>
           <h3>{item.plan}</h3>
           <p className={`${ index === isOpen ? style.showText : style.hideText}`}>{item.text}</p>
           <h2>{item.price}</h2>
           <p className={style.totalPrice}>{item.totalPrice}</p>
           <p className={style.free}>+ Free Shipping</p>
           <p className={style.oneBox}>1 box per month</p>
        </div>
        <div  className={`${style.openOption} ${ index === isOpen ? style.showList : style.hideList}`}>
        <div className={style.insideInfo} ref={ref}><p>{item.info}{item.infoShow && <><img onClick={showInfo} src="/image/icon/info.png"/> </>}</p></div>
        
        <button onClick={() => setSelectedProduct(item)}>Add to Cart</button>
         </div>
      </div>
         )
         }))} 
    </div>
    : 
    
    <>
    {/* {props.header.remove === "remove"  ? "" :    
      <div className={style.mainToggle}>
        <h3>Choose your billing plan</h3>
       <div className={style.toggleContainer}>
      <div className={style.choice}> <p>Monthly</p></div>
    <button className={`${style.toggleButton} ${isToggled ? style.toggled : ''}`} onClick={handleToggle}>
    <div className={style.circle}></div>
    </button>
    <div className={style.choice}> <p>Prepaid</p></div>
    </div>
    </div>
    } */}

    {/*End*/}
    <div className={style.giftToggle}>
      <h3>Choose your plan</h3>
      <div>
      <p className={selectedData === 'kids' ? style.activeGift : ''}   onClick={() => handleGiftToggle('kids')}>Monthly</p>
      <p className={selectedData === 'gift' ? style.activeGift : ''}  onClick={() => handleGiftToggle('gift')}>Prepaid</p>
      </div>
    </div>
    <div className={style.planToggDis}>
    {(selectedData === 'kids' ? props.box : props.boxTwo).map(((item,index) =>{
        return(
        <div className={`${style.toggleBoxes} ${style[item.border]}`} onClick={() => handlePlanClick(item.checkout,index)} >
          {item.code ?     <span className={style.code}>First Box Free with code <span>FREEBOX</span></span>: ""}
        
         <div className={`${style.toggleContent} ${style[item.colorBck]}`}>
           <div>
           {item.ribbonOne && <> <span>{item.ribbonOne}</span>
              <span>{item.ribbonTwo}</span></>}
           </div>
           <h3>{item.plan}</h3>
           <p className={`${ index === isOpen ? style.showText : style.hideText}`}>{item.text}</p>
           <h2>{item.price}</h2>
           <p className={style.totalPrice}>{item.totalPrice}</p>
           <p className={style.free}>+ Free Shipping</p>
           <p className={style.oneBox}>1 box per month</p>
           
        </div>{index === isOpen ? <>{item.infoShow && <>{info && <span className={style.infoText}>Cancel anytime: Commitment subscriptions cannot be canceled mid-term but subsequent auto-renewals can be turned off at any time. Non-renewing subscriptions are not eligible for a refund.</span>}</>}</> : ""}
        <div  className={`${style.openOption} ${ index === isOpen ? style.showList : style.hideList}`}>
        <div className={style.insideInfo} ref={ref}><p>{item.info}{item.infoShow && <><img onClick={showInfo} src="/image/icon/info.png"/> </>}</p></div>
        
        <button onClick={() => setSelectedProduct(item)}>Add to Cart</button>
         </div>
      </div>
         )
         }))} 
    </div>
    
    
    </>}
    
 <div className={style.planFooter}>
   <p>Prices are in USD. {selectedData === 'kids' ? " Plans renew automatically." : ""}</p>

   <span>*Compared to Store price ($39.95 + $7.95 shipping/delivery)</span>
   <p></p>
 </div>
 {selectedProduct && (
<div className={style.overlay} onClick={ removeSelectedTabs}  ></div>)}
 <div className={`${style.addToCart} ${!selectedProduct && style.hidden}`}>
{selectedProduct && (
<>
<div className={style.cartHeading}>
<img src='/image/add.svg' onClick={ removeSelectedTabs}/>
  <h2>My Cart</h2>
</div>
{ selectedProduct.prepaid === "prepaid6" || selectedProduct.prepaid === "prepaid12" ?  
<div className={style.badge}>
  <img src="/image/badge.svg"/>
  <p>Your order <span>QUALIFIES</span> for the free box promotion. 
Use code <span>FREEBOX</span> at checkout!</p>
</div>

:""}
   <div className={style.cartContent}>

   <div>
    <div className={style.selectedBox}>
     <div className={style.selectedImg}>
     <img src={selectedProduct.cartImg} alt={selectedProduct.name} className={style.selectedImage} />
     </div>
     <div className={style.selectedName}>
     <h2>{selectedProduct.plan}</h2>
   <p>  {selectedProduct.text}</p>
    
     </div>
     <div className={style.selectedPrice}>
     <p >${( selectedProduct.prepaid === "prepaid6" ? 6 * parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) : selectedProduct.prepaid === "prepaid12" ? 12 * parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) : parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) ).toFixed(2)  }{selectedProduct.prepaid === "prepaid6" ? "" :  selectedProduct.prepaid === "prepaid12" ? "" : "/month"}</p>
     <span className={style.totalBox}>{selectedProduct.prepaid === "prepaid6" ? "paid upfront" :  selectedProduct.prepaid === "prepaid12" ? "paid upfront" : ""}</span>
     <span>1 box per month</span>
     </div>
    </div>
   
</div>

<h3>Customize Your Subscription</h3>
{selectedProduct.plan === "Monthly Mini Zoo" ? "" :
<div className={style.cartSib}>

<div className={style.sibImage}>
  <img src='https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/rectangle-383-1-16940029428642.png?1694002942538'/>
  </div>
 

  <div className={style.sibContent}>
    <h3>Have more than one Little {location.pathname === "/cub-plan" || location.pathname === "/gifting-cub-club" ? "Cub":"Zoologist"}?</h3>
    {location.pathname === "/cub-plan" || location.pathname === "/gifting-cub-club" ?
    <p>Keep the entire family engaged in the EDventures for just $24 per sibling pack. Each sibling pack includes an extra stuffed animal friend, dramatic play surprise, and sticker. The perfect addition for families with 2 little cubs!</p> : <p>Keep the entire family engaged in the EDventures for just $24 per sibling pack. Each sibling pack includes an extra stuffed animal friend, craft, sticker, and special surprise. 1 sibling pack is the perfect addition for families with 2 little zoologists and 2 sibling packs are perfect for families with 3 little zoologists!</p>  }
    <div className={style.cartSibBtn}>
      <p  className={selectedTab === 1 ? style.selectedTab : ''} onClick={() => handleTabClick(1, '1 Sibling')}>{selectedTab === 1 ?<img src="/image/tick.svg"/>:""}{selectedTab === 1 ? " 1 Sibling Pack Added" : "Add 1 Sibling Pack"}</p>
      {location.pathname === "/cub-plan" || location.pathname === "/gifting-cub-club" ? "" :
      <p className={selectedTab === 2 ? style.selectedTab : ''} onClick={() => handleTabClick(2, '2 Sibling')}>{selectedTab === 2 ?<img src="/image/tick.svg"/>:""}{selectedTab === 2 ? " 2 Sibling Packs Added" : "Add 2 Sibling Packs"}</p>}
    </div>
  </div>

</div>
}
{  location.pathname === "/cub-plan" || location.pathname === "/gifting-cub-club" ? "" :
<div className={style.cartSib}>
  <div className={style.sibImage}>
  <img src='https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/new-book-addon-image-16962812168155.png?1696281222449'/>
  </div>
  <div  className={style.sibContent}>
    <h3>Boost the learning!</h3>
    <p>Add a SECOND BOOK to your monthly EDventure! An easy reader all about the animal of the month to encourage your new reader to love reading and learning for only $7.95.</p>
    <div className={style.cartSibBtn}>

          <p onClick={() => handleClick("Yes")} style={{ cursor: 'pointer', backgroundColor: isClicked ? '#0b9c7f' : 'transparent' ,color: isClicked ? 'white' : '#0b9c7f' }}>{isClicked ?<img src="/image/tick.svg"/>:""} {!isClicked ? "Add Easy Reader" : "Easy Reader Added"} </p>              
    </div>
  </div>

</div>
   }
   </div>


<div className={style.checkoutBtn}>
<div className={style.totalPrice}>
  <h3>Sub Total</h3>
  <p>${( selectedProduct.prepaid === "prepaid6" ? 6 * parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) : selectedProduct.prepaid === "prepaid12" ? 12 * parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) : parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) ).toFixed(2)}{selectedProduct.prepaid === "prepaid6" ? "" :  selectedProduct.prepaid === "prepaid12" ? "" : " /month"}</p>
 </div>
 
 {tabData === "2 Sibling" ? 
<div  className={`${style.totalPrice} ${style.cartAddon}`}>
 
  <h4>2 Sibling Packs</h4>
  <p>+ ${ selectedProduct.prepaid === "prepaid6" ? 6 * 48 : selectedProduct.prepaid === "prepaid12" ? 12 * 48 : 48 }{selectedProduct.prepaid === "prepaid6" ? "" :  selectedProduct.prepaid === "prepaid12" ? "" : "/month"}</p>
  </div>
  : ""}
       {tabData === "1 Sibling" ? 
<div  className={`${style.totalPrice} ${style.cartAddon}`}>
 
  <h4>{selectedProduct.prepaid === "prepaid6" ? "1 Sibling Pack for 6 months" :  selectedProduct.prepaid === "prepaid12" ? "1 Sibling Pack for 12 months" : "1 Sibling Pack"}</h4>
  <p>+ ${ selectedProduct.prepaid === "prepaid6" ? 6 * 24 : selectedProduct.prepaid === "prepaid12" ? 12 * 24 : 24 } {selectedProduct.prepaid === "prepaid6" ? "" :  selectedProduct.prepaid === "prepaid12" ? "" : "/month"}</p>
  </div>
  : ""}
 {addOn === "Yes" ?    <div  className={`${style.totalPrice} ${style.cartAddon}`}>
  <h4>Easy Reader</h4>
  <p>+ ${ selectedProduct.prepaid === "prepaid6" ? 6 * 7.95 : selectedProduct.prepaid === "prepaid12" ? 12 * 7.95 : 7.95 }{selectedProduct.prepaid === "prepaid6" ? "" :  selectedProduct.prepaid === "prepaid12" ? "" : "/month"}</p>
  </div> : ""}
  <div className={style.totalPrice}>
  <h3> Total</h3>
  <p>${
  (( selectedProduct.prepaid === "prepaid6" ? 6 * parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) : selectedProduct.prepaid === "prepaid12" ? 12 * parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) : parseFloat(selectedProduct.price.replace(/[^\d.]/g, '')) )  + 
  (tabData === "1 Sibling" ? ( selectedProduct.prepaid === "prepaid6" ? 6 * 24 : selectedProduct.prepaid === "prepaid12" ? 12 * 24 : 24 ):0 ) + 
  (tabData === "2 Sibling" ?( selectedProduct.prepaid === "prepaid6" ? 6 * 48 : selectedProduct.prepaid === "prepaid12" ? 12 * 48 : 48 ):0 ) +
  (addOn === "Yes" ?( selectedProduct.prepaid === "prepaid6" ? 6 * 7.95 : selectedProduct.prepaid === "prepaid12" ? 12 * 7.95 : 7.95 ):0)).toFixed(2)
}{selectedProduct.prepaid === "prepaid6" ? "" :  selectedProduct.prepaid === "prepaid12" ? "" : "/month"}</p>
  </div>
   
   

    
    <button onClick={handleCheckout}>
      {/* <img src="/image/lock-circle.svg"/> */}
      Continue to Secure Checkout</button>
    { selectedProduct.prepaid === "prepaid6" || selectedProduct.prepaid === "prepaid12" ?  

    <div className={style.cartFooter}>
      <img src="/image/clock.svg"/>
      <p>Your order <span>FREEBOX</span> code expires in </p>
      <p className={style.countDown}>
      {displayText}
    </p>  
    </div>
    :""}
  </div>
</>
)}
</div>
</div>
             </>
   
    )
}

export default PlanToggle;