import React from "react";
import style from "./Program.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';



// import required modules
import { Pagination ,Navigation,Autoplay} from 'swiper/modules';
import Newsletter from "../../component/newsletter/Newsletter";
const Program = () =>{
    const box = [
        {
            image:"/image/p1.png"
        },
        {
            image:"/image/p2.png"
        },
        {
            image:"/image/p3.png"
        },
        {
            image:"/image/p4.png"
        },
        {
            image:"/image/p5.png"
        },
        {
            image:"/image/p6.png"
        },
        {
            image:"/image/p7.png"
        },
        {
            image:"/image/p8.png"
        },
    ]
    return(
          <>
              <div className={style.programBck}>
             <div className="container">
                  <div className={style.programHeading}>
                  <h1>My ZOO Box Affiliate Program</h1>
                  <p>Do you want to monetize your existing website, mobile app, or social media traffic by promoting My ZOO Box and getting paid commissions for your My ZOO Box sales?</p>
                  </div>

                   <div className={style.programSwiper}>
                   <Swiper
        slidesPerView={6.7}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation,Autoplay]}
        autoplay={{ // Configure autoplay options
          delay: 4000, // Autoplay delay in milliseconds
          disableOnInteraction: false, // Autoplay continues even when the user interacts with the swiper
        }}
        navigation={true}
        className="mySwiper"
        loop={true}
        
        breakpoints={{
          1200:{
            slidesPerView:3.2,
            spaceBetween: 10,
        },
          768: {
            slidesPerView: 2, 
            spaceBetween: 10,
          },
         
          320: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          
        }}
        
      >
        {box.map(((item,i) =>{
          return(
              <SwiperSlide key={i} className={style.mobBox}><img src={item.image}/></SwiperSlide>
          )
        }))}
  

      </Swiper>
                   </div>
             </div>
        </div>
        <div className={style.programContent}>
        <div className="container">
        <div className={style.programBox}>
            <h2>Affiliate Perks:</h2>
            <ul>
                <li>Earn 10% commission for every order</li>
                <li>30-day cookie</li>
                <li>Access to My ZOO Box Assets</li>
                <li>Special discounts/seasonal promotional opportunities</li>
                <li>Bonus on each sale in the 1st 90 days</li>

            </ul>
            <a href="https://www.shareasale.com/join/145532">Apply Today <img src="/image/icon/arrow.svg"/></a>
        </div>


        <div className={`pt50 ${style.programBox}`}>
            <h2>Content creators and influencers</h2>
            <ul>
                <li>(FYI: You can be an affiliate too!)</li>
                <li>Are you a content creator or influencer on Instagram or TikTok that wants to be</li>
                <li> considered for a content campaign?</li>
                <li>Are you a YouTube creator that wants to be considered for sponsored content?</li>
           

            </ul>
            <a href="mailto:support@myzoobox.com">Email Us <img src="/image/icon/arrow.svg"/></a>
        </div>
        </div>
        </div>
       <Newsletter/>
        </>
    )
}

export default Program;