import React from "react";
import style from "./StepForm.module.css"

const StepAddon = (props) =>{
    const handleAddonClick = (addon) =>{
        props.data.addon = addon
    }
    return(
        <div>
        <div className={style.addon}>
       <div className={style.sibImage}>
        <img src="https://static.subbly.me/fs/subbly/userFiles/my-zoo-box-64b083bd8eaa8/media/new-book-addon-image-16962812168155.png?1696281222449"/>
       </div>
       <div className={style.sibContent}>
          <h1>Boost the learning!</h1>
          <p>Add a SECOND BOOK to your monthly EDventure! An easy reader all about the animal of the month to encourage your new reader to love reading and learning for $7.95</p>
          <div className={style.buttonDis}>
          <button onClick={() => handleAddonClick("Yes")}>YES! Add the Book</button>     
          <button onClick={() => handleAddonClick("No")}>No Thanks</button></div>           
       </div>
  </div>
  </div>
    )
}

export default StepAddon