import React, { useState } from "react";
import style from "./Store.module.css"
const Store = () =>{
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [show,setShow] = useState(false)
    const acEmail = document.querySelector("._x49959923 input")
    const acButton = document.querySelector("#_form_25_submit")
    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email) {
            setError('Email is required!');
            return;
        }
        if (!validateEmail(email)) {
            setError('Please enter a valid email address');
            return;
        }else{
            setShow(true)
            console.log("Aaa")
            acEmail.value = email
            acButton.click()
        }

        // If validation passes, you can submit the form
        // For now, let's just log the email
        console.log(email);

        // Clear the email field and error message
        setEmail('');
        setError('');
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };
    return(
         <>
          <div className={style.storeBck}>
           
           </div>
           <div className={style.storeSecondBck}>
           <div className="container">
            <div className={` ${style.store}`}>
                <h2>The ZOO Store is being updated!</h2>
                <p className={style.storeText}>We’re working to improve our store experience for all parents looking to bring our animal-fun EDventures to their homes!</p>
                {/* <p className={style.storeText}>Due to popular demand, we have decided to bring back the old ZOO Store while we work on the improved version over the coming weeks.</p>
                <a href="https://my-zoo-box-64b083bd8eaa8.subbly.me/the-zoo-store" >Load the Zoo Store 1.0</a> */}
                <p className={style.storeText}>If you want to be notified once the new store goes live, please enter your email address to join the waitlist!</p>
                <form  onSubmit={handleSubmit}  className={show ? style.showMessage : style.hideMessage }>
                    <div>
                    <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    placeholder="Enter your email address"
                />
                        <button>Notify me!</button>
                    </div>
                    {error && <p style={{ color: 'white',textAlign:"left" }}>{error}</p>}
                  </form>
                  <div  className={`${style.submitMessage} ${show ?  style.hideMessage :style.showMessage}`}>
                    <h1>Yay! You're all signed up.😊</h1>
                  </div>
                  <div class="_form_25"></div>
                  {/* <h3>
                  Placing a school order?   </h3>
                  <p className={style.storeText}>Please contact us at <a href="mailto:support@myzoobox.com">support@myzoobox.com </a>and our team will assist you with your request!</p> */}
               
            </div>
            </div></div></>
    )
}

export default Store