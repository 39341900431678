import React, { useState } from "react";
import style from "./homepage.module.css"
import Button from "../../component/button/button";
import Clubs from "../../component/clubs/Clubs";
import Curiosity from "../../component/curiosity/Curiosity";
import { Link } from "react-router-dom";
import KidLove from "../../component/kidLove/kidLove";
import Testimonials from "../../component/testimonials/Testimonials";
import Trusted from "../../component/trusted/Trusted";
import FAQ from "../../component/faq/Faq";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Helmet } from 'react-helmet';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
import Newsletter from "../../component/newsletter/Newsletter";
const HomeDup = (props) =>{
  // const [open,setOpen] = useState(false)
    const faqData = [
        {
          question: 'What is the recommended age range for My ZOO Box?',
          answer: "My ZOO Box is thoughtfully designed to cater to various developmental levels. We offer specialized clubs for different age groups, starting from ages 3 and up. Each club is tailored to provide age-appropriate content and activities that align with your child's cognitive and motor skills, ensuring an engaging and enriching experience at every stage of their learning journey. Whether your child is a curious preschooler or an inquisitive young mind aged 10 and above, we have a club designed to captivate their imagination and promote their educational growth.",
        },
        {
          question: 'Can I give My ZOO Box as a gift?',
          answer: 'Of course! Our subscriptions make wonderful gifts! Simply visit our website and make your gift selection (1, 6 or 12-month subscription). Then, provide the details such as the child’s name and shipping address. You can also send other one-time ZOO Shop items as gifts too! Be sure to check the “Are you giving this as a gift?” box during checkout so that you can enter a gift message.',
        },
        {
            question: 'When will my box ship?',
            answer: "Monthly EDventures ship the first 2 weeks of each month. Once an EDventure ships please allow 3-10 business days for delivery to U.S. addresses and 7-14 business days for addresses outside the U.S. Orders placed by the 25th of the month will receive the next month's box. Orders placed the 26th and after will receive the month after that. Orders placed the 25th of May will receive July as their first box. Orders placed the 26st of May will receive July as their first box. ",
          },
          {
            question: 'How much does shipping cost?',
            answer: (
              <p>All subscriptions include free shipping to anywhere in the United States, including Hawaii, Alaska, and Military (APO, FPO, DPO) addresses.<br/>
              Monthly subscription EDventures can also be shipped to Canada with an additional shipping fee.<br/>
              Shipping for The ZOO Store $7.95 for USA customers and $12.95 for Canadian customers.<br/>
              You can get free shipping in our online ZOO Store on orders of $75 or more for the U.S. and $200 CAD or more for Canada.<br/>
            
              All Monthly Club EDventures ship the first 2 weeks of each month.<br/>
              Once an EDventure ships please allow 3-10 business days for delivery to U.S. addresses and 7-14 business days for addresses outside the U.S.<br/>
              Orders placed by the last day of the month will receive the next month's box.<br/>
              Orders placed the 1st and after will receive the month after that.<br/>
              Orders placed the 31st of May will receive July as their first box.<br/>
              Orders placed the 1st of July will receive July as their first box.
              </p>
            )
          },
          {
            question: 'How much does My ZOO Box cost?',
            answer: 'Monthly subscriptions are $37.95 per month for most clubs. The Zoologist Club Mini Zoo is $23.95. You can receive a discount on the per-EDventure price by signing up for a 6, or 12-month subscription - these are paid in-full at checkout; savings are calculated based on the price of a single EDventure (EDventure + shipping). We offer free shipping to addresses in the U.S. Shipping costs for International countries vary. To order, visit www.myzoobox.com and click "Join Club".        ',
          },
          {
            question: "I haven't received my shipment, what do I do?",
            answer: "If you haven't received a shipment:   After an EDventure or order is shipped, you will receive a tracking email from support@myzoobox.com. You may need to check that these emails are not sent to your Spam folder.      Please click the tracking link in your shipping confirmation email to check the status of your package and see if there were any carrier issues or delays.    Please note, due to increased mail volume USPS is reporting shipping delays and that some shipments are not being scanned which will lead to inaccurate tracking information. We apologize for the inconvenience this may cause and appreciate your patience as the USPS works to deliver your shipment. Please allow an extra 3-10 business days for packages to arrive to U.S. addresses. Additionally, due to COVID-19 related safety measures, domestic and international packages may be delayed 5-14 days. If your EDventure didn’t arrive in time and you would like to give the recipient a gift note, please click here for some printable options.",
          },
          {
            question: 'Is it hard to cancel my subscription?',
            answer: "We hate to see you go! Did you know you can pause your subscription or receive every other month if you need a short break? To set up every other month, please email support@myzoobox.com.To cancel, log into your My Account page, click the “Subscription” tab, scroll down and click 'Manage', then click “Cancel subscription”.",
          },
        // Add more FAQ items as needed
      ];
      const kids = [
        {
          image:"/image/lov1.png"
        },
        {
          image:"/image/lov2.png"
        },
        {
          image:"/image/lov3.png"
        },
        {
          image:"/image/lov4.png"
        },
        {
          image:"/image/lov5.png"
        },
        {
          image:"/image/lov6.png"
        },
        {
          image:"/image/lov7.png"
        },
        {
          image:"/image/lov8.png"
        },
        {
          image:"/image/lov9.png"
        },
        {
          image:"/image/lov10.png"
        },
        {
          image:"/image/lov11.png"
        },
        {
          image:"/image/lov12.png"
        },
      ]

    // const openFunction  = () =>{
    //   setOpen(true)

    // }
    return(
        <>
          <Helmet>
        <title> Kids’ Zoology Subscription Boxes | My ZOO Box</title>
        <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
      </Helmet>
          <div className={style.homebck}>
          <div className="container">
            <div className={style.banner}>
             <div className={style.bannerContent}>
              <h1>
                 Explore the <br/>World of Animals
              </h1>
              <p>
                 Where Learning meets<br/> Conservation.
              </p>
              <div className={style.buttonDis}>
              <Button dynamicClass="fillColor" text="Get Started" link="/select-plans"/>
              <Button dynamicClass="borderColor" text="Gift it" yellow="yellow" link="/choose-gift-club"/>
              </div>
            </div>
            <div className={style.homeDes}>
                <img src="/image/banner1.webp"/>
                <img src="/image/banner2.webp"/>
           
            </div>
            <div className={style.homeMob}>
            <Swiper
                    navigation={true}
                    pagination={{   
                      clickable: true,
                    }}
        // navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        <SwiperSlide> <img src="/image/banner1.webp"/></SwiperSlide>
        <SwiperSlide> <img src="/image/banner2.webp"/></SwiperSlide>
      
      
      </Swiper>
            </div>
         </div>
     
        </div>
          </div>
         <div className={style.trustedBck}>
         <Trusted/>
         </div>
        <Clubs />
        <Curiosity/>
        <div className={style.learn}>
            <div className="thirdContainer pt50">
               <div className={style.learnHeading}>
               <h1>Start the BEST learning EDventure today!</h1>
                <p>Receive <span>FREE SHIPPING</span> in the United States for all subscriptions!<br/><br/>
                   Subscriptions ship within the first two weeks of each month. Orders placed on or after the 1st will be processed in the following month’s shipment.
                </p>
          
                <Button dynamicClass="fillCubColor" text="Get Started" link="/select-plans"                               />
                </div>
                <div className={`pt50 pb50 ${style.learnDis}`}>
                    <div className={style.learnBoxes}>
                    <div className={style.learnBox}>
                        <span>Delivering Animal Fun Since 2020</span>
                        <h3>Ready for an explosion<br/> of excitement?</h3>
                        <p>Do your kids get THIS excited to learn?</p>
                        <p>Join now and see just how captivating screen-free learning can be!</p>
                        <Button dynamicClass="fillColor" text="Get Started" link="/select-plans"/>
                    </div>
                    <iframe   width="560" height="315" src="https://www.youtube.com/embed/eVSQQpWuyf8?si=kO8vyMxzZnSDDeuJ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen autoPlay></iframe>
                  {/* {!open &&  <div className={style.learnBck}>
                
                <div onClick={openFunction}>
                  <img src="/image/icon/play.svg"/>
                <p>Play Video</p>
                </div>
           
                 <img src="/image/tik.webp" />
              </div>} */}
                   
                    </div>
                    <div className={style.learnBoxes}>
                    {/* <div className={style.learnBckTwo}>
                       <img src="/image/trees.webp"/>
                    </div>
                    <div className={style.learnBoxTwo}>
                        <span>One Tree Planted Partnership</span>
                        <h3>Since May 1, We have planted 8,000 + Trees</h3>
                        <p>At My ZOO Box we know trees benefit every single living creature on this planet. As such, we decided the way we could make the most impact for our animal friends is to work on reforestation efforts with One Tree Planted! </p>
                   
                        <Button dynamicClass="fillColor" text="Get Started"/>
                    </div> */}
                    </div>
                 
                </div>
            </div>
         </div>
           <KidLove kid={kids} title="Kids love us..." titleTwo="Over 100,000 boxes shipped globally...and counting!"/>

            <Testimonials/>
     
            <div className="pt50 pb50 subContainer">
              <FAQ data={faqData} section={props.section}/>
            </div>
            <Newsletter/>
            </>
    )
}
export default HomeDup