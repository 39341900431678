import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/home/homepage";

import CubClub from "../pages/cubClub/CubClub";
import ZooClub from "../pages/zooClub/ZooClub";
import Gift from "../pages/gift/Gift";
import Policy from "../pages/policy/Policy";
import Service from "../pages/service/Service";
import Cookie from "../pages/cookie/Cookie";
import Plans from "../pages/plans/Plans";
import PlanToggle from "../component/toggle/planToggle";
import CubPlans from "../pages/plans/cubPlans";
import ZooPlans from "../pages/plans/zooPlans";
import Errormsg from "../pages/Error";
import SelectPlans from "../pages/selectPlans/SelectPlans";
import Missing from "../pages/missing/Missing";
import Faqs from "../pages/faqs/Faqs";
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import Program from "../pages/program/Program";
import Store from "../pages/store/Store";
import PlansGiftZoo from "../pages/plans/plansGiftZoo";
import CubGiftPlans from "../pages/plans/cubGiftPlans";
import SelectGiftPlans from "../pages/selectPlans/selectedGiftPlans";
import ZooGiftClub from "../pages/zooClub/ZooGiftClub";
import CubGiftClub from "../pages/cubClub/CubGiftClub";
import Promotion from "../pages/promotion/Promotion";
import CopyStore from "../pages/copyStore/CopyStore";
import NextEdventure from "../pages/nextEdventure/NextEdventure";
import FirstEdventure from "../pages/nextEdventure/FirstEdventure";
import MainCart from "../pages/product/mainCart";
import HomeDup from "../pages/home/homepageDuplicate";
import ProductDetail from "../pages/mainShop/ProductDetail";
import ProductList from "../pages/mainShop/productList";
import MainShop from "../pages/mainShop/MianShop";
import SubblyApi from "../pages/mainShop/subblyApi";
import LandingPage from "../pages/home/LandingPage";
import StepForm from "../pages/stepForm/StepForm";
import Summer from "../pages/summer/Summer";
import Rific from "../pages/rific/Rific";
import Empower from "../pages/empower/Empower";
import MainStore from "../pages/copyStore/MainStore";
import UnitStudy from "../pages/copyStore/UnitStudy";
import ShopV3 from "../pages/shopDesignV3/shopV3";
import AllShopV3 from "../pages/shopDesignV3/allShopV3";

const RouteConfig = (props) =>{
    return(
        <Routes>
        <Route path="/" element={<Home  section={props.section}/>} />
        <Route path="/home" element={<HomeDup  section={props.section}/>} />
        <Route path="/v2" element={<LandingPage  section={props.section} scroll={props.scroll}/>} />
        <Route path="/cub-club" element={<CubClub />} />
        <Route path="/zoologist-club" element={<ZooClub />} />
        <Route path="/join-the-zoologist-club" element={<Navigate to="/zoologist-club" replace />} />

        <Route path="/gifting" element={<Gift />} />
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/terms-of-service" element={<Service />} />
        <Route path="/cookie-policy" element={<Cookie />} />
        {/* <Route path="/plans" element={<Plans />} /> */}
        <Route path="/cub-plan" element={<CubPlans />} />    
        <Route path="/gift-cub-club" element={<CubGiftClub />} />    
        <Route path="/gifting-cub-club" element={<CubGiftPlans />}/> 
        <Route path="/zoologist-plan" element={<ZooPlans />}/>
        <Route path="/gift-zoologist-club" element={<ZooGiftClub />}/>
        <Route path="/gifting-zoologist-club" element={<PlansGiftZoo />}/>
        <Route path="/PlanToggle" element={<PlanToggle />} />
        <Route path="/select-plans" element={<SelectPlans />} />
        <Route path="/choose-gift-club" element={<SelectGiftPlans />} />
        <Route path="/in-progress" element={<Missing />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/faq" element={<Faqs />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/partnership" element={<Program />} />
        <Route path="/the-zoo-store" element={<Store />} />
        {/* <Route path="/zoo-store" element={<MainStore />} /> */}
        <Route path="/zoo-store/digital-studies" element={<UnitStudy />} />
        <Route path="/first-box-free-promotion" element={<Promotion />} />
        <Route path="/zoo-store/one-time-boxes" element={<CopyStore />} />
        <Route path="/your-next-edventure" element={<NextEdventure />} />
        <Route path="/your-first-edventure" element={<FirstEdventure />} />
        <Route path="/MainCart" element={<MainCart />} />
        <Route path="/zoo-store-two" element={<MainShop />} /> 
        <Route path="/product-list" element={<ProductList />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/SubblyApi" element={<SubblyApi />} />
        <Route path="/subscription" element={<StepForm />} />
        <Route path="/summer" element={<Summer />} />
        <Route path="/schools" element={<Rific />} />  
        <Route path="/empower-foundation" element={<Empower />} />        
        <Route path="/zoo-store/all" element={<ShopV3 />} />     
        <Route path="/zoo-store" element={<AllShopV3 />} />     
        <Route path="/*" element={<Errormsg />} />
    
        </Routes>
    )
}
 
export default RouteConfig;