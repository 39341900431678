import React from "react";
import PrivacyHeading from "../../component/privacyHeading/PrivacyHeading";
import Cart from "../../layout/cart/Cart";
import style from "./Policy.module.css"
import { Helmet } from "react-helmet";

const Policy = () =>{
    return(
        <div className={style.policyBck}>
             <Helmet>
        <title> My ZOO Box | Privacy Policy</title>
        <meta name="description" content="Our monthly zoology boxes & digital memberships make learning a blast! Seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month!" />
      </Helmet>
        <PrivacyHeading title="Privacy Policy" text="Last updated February 01, 2024"/>
        <Cart>
        <p>Your privacy is important to us. It is My ZOO Box's policy to respect your privacy regarding any information we may collect from you across our website, https://www.myzoobox.com, and other sites we own and operate.</p>
        <h3>Information we collect</h3>
        <h4>Log data</h4>
        <p>When you visit our website, our servers may automatically log the standard data provided by your web browser. This data is considered “non-identifying information”, as it does not personally identify you on its own. It may include your computer’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.</p>
        <p>We may also collect data about the device you are using to access our website. This data may include the device type, operating system, unique device identifiers, device settings, and geo-location data. What we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.</p>
        <h4>Personal information</h4>
        <p>We may ask for personal information, such as your name, email address, physical address, and payment details. This data is considered “identifying information”, as it can personally identify you.</p>
        <p>We only request this information when you interact with our site services—like shopping and transacting on our site, creating a user account, or subscribing to our newsletter. We may also ask for personal information belonging to someone else when you buy goods and services as gifts intended for direct delivery to the recipient. We only use collected personal information in the context of providing or improving these services.</p>

        <h3>How we collect information</h3>
        <p>We collect information by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used. You are free to refuse our request for this information, with the understanding that we may be unable to provide you with some of your desired services without it.</p>
     
        <h3>Use of information</h3>
        <p>We may use a combination of identifying and non-identifying information to understand who our visitors are, how they use our services, and how we may improve their experience of our website in future. We also use this information to gain insights into demographics, product and service popularity, and the effectiveness of our marketing activity. We do not disclose the specifics of this information publicly, but may share aggregated and anonymised versions of this information, for example, in website and customer usage trend reports.</p>
        <p>We primarily use your personal information for the purposes of processing your orders of products and services, delivering those products and services to you (or your nominated recipient), and granting you access to engage with certain areas of our site.</p>
        <p>We may use your personal details to contact you with updates about our website and services, along with promotional content that we believe may be of interest to you. If you wish to opt out of receiving promotional content, you can follow the “unsubscribe” instructions provided alongside any promotional correspondence from us.</p>
        <h3>Data processing and storage</h3>
        <p>The personal information we collect is stored and processed in , or where we or our partners, affiliates and third-party providers maintain facilities. We only transfer data within jurisdictions subject to data protection laws that reflect our commitment to protecting the privacy of our users.</p>
        <p>
        We only retain personal information for as long as necessary to provide a service, or to improve our services in future. While we retain this data, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure, and cannot guarantee absolute data security.</p>
<p>If you request your personal information be deleted, or where your personal information becomes no longer relevant to our operations, we will erase it from our system within a reasonable timeframe.
</p>
<h3>Cookies</h3>
<p>We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more information.
</p>
<h3>Third-party access to information</h3>
<p>We use third-party services for:
</p>
<ul>
    <li>Analytics tracking</li>
    <li>User authentication</li>
    <li>Advertising and promotion</li>
    <li>Content marketing</li>
    <li>Email marketing</li>
    <li>Payment processing</li>
</ul>
<p>These services may access our data solely for the purpose of performing specific tasks on our behalf. We do not share any personally identifying information with them without your explicit consent. We do not give them permission to disclose or use any of our data for any other purpose.
    </p>
    <p>We may, from time to time, allow limited access to our data by external consultants and agencies for the purpose of analysis and service improvement. This access is only permitted for as long as necessary to perform a specific function. We only work with external agencies whose privacy policies align with ours.
</p>
<p>We will refuse government and law enforcement requests for data if we believe a request is too broad or unrelated to its stated purpose. However, we may cooperate if we believe the requested information is necessary and appropriate to comply with legal process, to protect our own rights and property, to protect the safety of the public and any person, to prevent a crime, or to prevent what we reasonably believe to be illegal, legally actionable, or unethical activity.
</p>
<p>We do not otherwise share or supply personal information to third parties. We do not sell or rent your personal information to marketers or third parties.
</p>
<h3>Children’s Privacy</h3>
<p>We are committed to protecting the privacy of children who use our services. We encourage parents to engage with us in protecting their children’s privacy, ensuring a safe and enjoyable online experience. Please refer to our <b>Children’s Privacy Policy</b> for more information.
</p>
<h3>Limits of our policy</h3>
<p>This privacy policy only covers My ZOO Box's own collecting and handling of data. We only work with partners, affiliates and third-party providers whose privacy policies align with ours, however we cannot accept responsibility or liability for their respective privacy practices.
</p>
<p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.
</p>
<h3>Changes to this policy
</h3>
<p>At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website. If you are a registered user on https://www.myzoobox.com, we will notify you using the contact details saved in your account. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information.
</p>
<h3>Your rights and responsibilities
</h3>
<p>As our user, you have the right to be informed about how your data is collected and used. You are entitled to know what data we collect about you, and how it is processed. You are entitled to correct and update any personal information about you, and to request this information be deleted. You may amend your account information at any time, using the tools provided in your account control panel.
</p>
<p>You are entitled to restrict or object to our use of your data, while retaining the right to use your personal information for your own purposes. You have the right to opt out of data about you being used in decisions based solely on automated processing.
</p>
<p>Feel free to contact us if you have any concerns or questions about how we handle your data and personal information.
</p>
<p>support@myzoobox.com
</p>
<p>This policy is effective as of 31 October 2019.</p>
        </Cart>
        <PrivacyHeading title="Children's Privacy Policy" text="Last updated February 01, 2024"/>
        <Cart>
        <p>We are committed to protecting the privacy of children who use https://www.myzoobox.com and its services. This children’s privacy policy is part of our approach to privacy, and covers the way we collect, store and manage information provided by children under the age of 13, in accordance with the US Children’s Online Privacy Protection Act (COPPA). </p>
        <p>We encourage parents to engage with us in protecting their children’s privacy, ensuring a safe and enjoyable online experience.</p>
        <h3>Collecting information from children</h3>
        <p>At times, we may require information from children to enable participation in certain activities, for example, registering an account, customising a profile, or receiving notifications. When collecting non-personal information, we encourage children never to provide any details that may personally identify them or reveal their location. We do not require children to provide more information than is necessary to participate in an activity.</p>
        <p>We do not use children’s contact details for marketing purposes, though we may use them for our internal marketing and research in order to improve the quality of products and services offered across our site.</p>
        
        <h3>Parental consent</h3>
        <p>In accordance with COPPA, if an activity does require any personal information (such as first name, last name, or email address), we will provide notice to and seek consent from a parent or guardian prior to collecting the information. We only retain collected information for as long as necessary to enable participation in the requested activity.</p>
        <p>In the event we discover we have collected personal information in a manner non-compliant with COPPA, we will either delete the information or seek parental consent.</p>
        <p>As a parent/guardian, if you believe your child is participating in an activity that collects personal information, and you have not received a notification or request for consent, please feel free to get in touch via our contact page https://www.myzoobox.com. We do not use parent contact details for marketing purposes unless marketing materials are explicitly requested.</p>
     
        <h3>Safeguarding children’s privacy</h3>
        <p>We take security seriously, and do what we can within commercially acceptable means to protect your child’s personal information from loss or theft, as well as unauthorised access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure, and cannot guarantee absolute data security.</p>
        <h3>Information abuse and community misconduct</h3>
        <p>We do not tolerate doxing (publishing of private or personal information about an individual without their consent), cyberbullying, or other forms of information abuse on https://www.myzoobox.com. </p>
        <p>If we discover that a child’s personal information has been disclosed on our site without the express consent of their parent/guardian, we will remove the content in question as quickly as possible and effect disciplinary measures (a warning, suspension or ban) on the offending account.</p>
        <h3>Third-party access to information</h3>
        <p>We do not knowingly disclose any personally identifying information or personal information provided by children to third parties. We may, however, disclose anonymised and aggregated versions of this information (analytics and statistics) for business, marketing or public relations purposes.</p>
        <h3>Parental controls and intervention</h3>
        <p>As a parent/guardian, you may at any time refuse to let us collect further information from your children for a particular activity or account. If you have given us consent previously, you may log in to your child’s account to review, edit or delete the information, or contact us via to request the removal of the information from our records.</p>
        <p>Please be aware that the removal of certain information may result in the termination of the associated account, or withdrawal from the associated activity.</p>
        </Cart>
        </div>
    )
}

export default Policy