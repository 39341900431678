import React from "react";
import style from "./Curiosity.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import {  Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

// import required modules
import { Pagination } from 'swiper/modules';
const Curiosity = () =>{
    const box = [
      {
        profile:"/image/pang22.png",
        image:"/image/3pango.png",
        name:"Pangolin",
   
        club: "Cub"
        ,
       icon:"/image/icon/cubb.svg"
       },
       {
        profile:"/image/l.png",
        image:"/image/biglemur.webp",
        name:"Lemur",
           club: "Zoologist",
        icon:"/image/icon/zooo.svg"
       },
       {
        profile:"/image/bee22.png",
        image:"/image/1Bee.png",
        name:"Bee"
        ,
         club: "Cub"
         ,
        icon:"/image/icon/cubb.svg"
       },
       {
        profile:"/image/cham.webp",
        image:"/image/bigcham.webp",
        name:"Chameleon",
         club: "Zoologist"
         ,
        icon:"/image/icon/zooo.svg"
       },
       {
        profile:"/image/el22.png",
        image:"/image/2elephant.png",
        name:"Elephant",
        club: "Cub"
        ,
       icon:"/image/icon/cubb.svg"
       },
       {
        profile:"/image/fox.webp",
        image:"/image/bigfox.webp",
        name:"Arctic Fox",
        club: "Zoologist"
        ,
        icon:"/image/icon/zooo.svg"
       },
   
      ]
    return(
        <div className={`pt50 ${style.curiosity}`}>
             <div className="thirdContainer">
            <p className="ptb5">Expertly designed to</p>
            <h1>Spark Your Child’s Curiosity</h1>
            <p className="ptb5">Take a peek at past EDVENTURES!</p>
            <div className={`pt50 ${style.curiosityDis} ${style.desktop}`}>    
                    {box.map((item,i) =>{
                        return(
                         <div className={`${style.curiosityBox} ${style[item.club]}`} key={i}>
                          <div className={style.curiosContent}>
                            <img src={item.profile} alt="#"/>
                            <p>{item.name}</p>
                            <span>EDventure</span>
                          </div>
                          <div  className={style.mainboxImage}>
                            <img src={item.image} alt="#"/>
                          </div>
                           {/* <div className={style.clubSelect}>
                            <img src={item.icon}/>
                            <p>{item.club}</p>
                            </div> */}
                          </div>
                        )
                    })}                 
            </div>

           
        </div>
        <div className={`pt50 ${style.curiosityDis} ${style.mobile}`}> 
            <Swiper
        slidesPerView={6.7}
        spaceBetween={0}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
        breakpoints={{
          1200:{
            slidesPerView:6.7,
            spaceBetween: 0,
        },
          768: {
            slidesPerView: 4, 
            spaceBetween: 0,
          },
         
          320: {
            slidesPerView: 1.1,
            spaceBetween: 0,
          },
        }}
      >
     
   {box.map((item,i) =>{
                        return(
                          <SwiperSlide key={i}>
                         <div className={style.curiosityBox}>
                          <div className={style.curiosContent}>
                            <img src={item.profile} alt="#"/>
                            <p>{item.name}</p>
                            <span>EDventure</span>
                          </div>
                          <div  className={style.mainboxImage}>
                            <img src={item.image} alt="#"/>
                          </div>
                          <div className={style.clubSelect}>
                            <img src={item.icon}/>
                            <p>{item.club}</p>
                            </div>
                          </div>
                          </SwiperSlide>
                        )
                    })}       

      </Swiper>   
                             
            </div>
        </div>
    )
}

export default Curiosity;