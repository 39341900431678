import React from "react";
import FAQ from "../../component/faq/Faq";
import Trusted from "../../component/trusted/Trusted";
import Testimonials from "../../component/testimonials/Testimonials";
import KidLove from "../../component/kidLove/kidLove";
import style from "./CubClub.module.css"
import Button from "../../component/button/button";
import Learn from "../../component/learn/Learn";
import Tabs from "../../component/tabs/Tabs";
import Helps from "../../component/helps/Helps";
import Toggle from "../../component/toggle/Toggle";
import StepToggle from "../../component/toggle/stepToggle";
import { Helmet } from "react-helmet";
import Newsletter from "../../component/newsletter/Newsletter";

const CubGiftClub = () =>{
  const box = [
    {
        image:"/image/cub1.webp",
        title:"Stuffed Animal",
        description:"Plays a vital role in  promoting emotional development, nurturing empathy and compassion, and provide a sense of security and comfort for children."
    },
    {
        image:"/image/cc1.png",
        title:"Exclusive Book",
        description:"Provides the perfect blend of non-fiction knowledge and preschool fun. Written by the founding team at My ZOO Box, approved by real-life Zoologists."
    },
    {
        image:"/image/cc2.png",
        title:"Anatomy Poster & Coloring Sheet",
        description:"Writing, art, biology are all subjects infused within the most-popular item of the Cub Club EDventure."
    },
    {
        image:"/image/tb44.webp",
        title:"Math + Literacy Games & Activities",
        description:"The games and activities packed within each EDventure provide a well-rounded comprehensive learning experience."
    },
    {
        image:"/image/cc3.png",
        title:"Dramatic Play Surprise",
        description:"Dramatic play is essential for a child’s development, and at Cub Club, we celebrate it!In every box, your child will find a delightful dramatic play surprise, sparking their imagination and creativity in exciting new ways."
    },
    {
        image:"/image/cc4.png",
        title:"Continent Map",
        description:"Your first Cub Club EDventure will include a dual-sided continent map that the cubs get to fill with the stickers they collect from each new EDventure."
    },
    {
        image:"/image/cc5.png",
        title:"Social Emotional Learning Postcard",
        description:"The postcard from the animal of the month adds social emotional learning that go beyond the classroom studies."
    },
    {
      image:"/image/cc6.png",
      title:"Collectible Sticker",
      description:"The collectible sticker complements the continent map and makes learning about the world an immersive experience. Each EDventure will feature a unique sticker that the cub can stick on to the map."
  },
  {
    image:"/image/cc7.png",
    title:"Process Art & STEM Idea Extension",
    description:"Spark a love for science & art in your kids with animal of the month themed extension activities."
},
{
    image:"/image/cc8.png",
    title:"Snack & Sensory Recipe",
    description:"We believe the kitchen is the ultimate classroom for life skills and practical math. That's why we include two delectable snack recipes in every box."
},
{
  image:"/image/cc9.png",
  title:"QR Printables",
  description:"To add-on to the learning, each EDventure comes with additional activities to extend the learning for the curious cubs."
},
]
    const faqData = [
        {
          question: 'What is Cub Club',
          answer: `Cub Club is the ultimate zoology subscription for preschoolers, designed to nurture your child's natural curiosity and creativity while providing busy parents with a comprehensive preschool learning EDventure. Each month, our carefully curated EDventure arrives at your doorstep, packed with everything your child needs for exciting learning about the world of animals.
          We believe in the power of play-based learning, and Cub Club is tailor-made for kids aged 3 to 4. Our EDventures encompass a wide range of activities, including:
          Animal STEM: Dive into the world of animals with science activities that encourage kids to explore and discover fascinating facts about creatures from around the globe.
          Arts and Crafts: Let your child's artistic talents shine through with engaging craft projects that spark their imagination and creativity.
          Imaginative Play: Unleash your child's inner explorer as they embark on imaginative journeys with animal-themed dramatic play surprises   
          More Surprises: Beyond zoology, Cub Club surprises your child with exciting bonus activities that make learning fun and memorable.
          With Cub Club, you'll foster a love for zoology and encourage your child to connect with the natural world. Our mission is to bring hands-on fun and educational experiences right to your door, so you can watch your child's love for animals and learning flourish with each exciting crate. Join Cub Club today and inspire a lifelong passion for zoology!`,
        },
        {
          question: 'How is each Cub Club EDventure designed?',
          answer: `At Cub Club, our commitment to providing your child with the best zoology subscription experience goes beyond just delivering a box of fun – it's about fostering growth and curiosity. Our EDventures are meticulously designed by a team of multi-disciplinary experts, which includes educators, child development professionals, and zoologists. Their expertise helps us ensure that each EDventure is developmentally appropriate and packed with enriching projects that spark your child's curiosity and love of learning.
          This design process is our commitment to you, to make sure your child receives the most innovative and exciting zoology learning month after month. With Cub Club, you can trust that your child's curiosity will flourish with every EDventure that arrives at your doorstep. Join us on this EDventure and watch your child's love for zoology grow!
          
          `,
        },
        {
            question: 'What concepts does Cub Club cover',
            answer: `Cub Club is all about igniting your preschooler's passion for learning through hands-on, play-based activities that focus on foundational skills. We believe in providing a solid educational foundation while having loads of fun. Our EDventures are thoughtfully designed to enrich key preschool learning areas such as:




            Numbers and Early Math: Dive into the exciting world of numbers and basic math concepts through interactive activities that make learning math a joyful adventure.
            
            
            
            
            Sensory Art: Encourage your child's sensory exploration and creativity with art projects that engage their senses and enhance fine motor skills.
            
            
            
            
            Literacy Skills: Foster early literacy skills, including vocabulary and language development, through engaging activities that encourage reading, storytelling, and more.
            
            
            
            
            Pretend Play: Fuel imaginative play, social development, and language skills through themed pretend play experiences that transport your child to new worlds, enhancing their storytelling abilities.
            
            
            
            
            Nature and Animals: Connect with the natural world and introduce your child to the wonders of zoology as they learn about animals and their habitats, building vocabulary and early literacy skills through captivating exploration.
            
            
            
            
            With Cub Club, your child will develop these essential preschool learning skills, while engaging in activities designed to make learning both educational and entertaining. Join us on this exciting educational journey and watch your preschooler's skills and confidence flourish with each Cub Club EDventure!
            
            `,
          },
          {
            question: 'What animals are covered in Cub Club?',
            answer: `Every Cub Club Subscription starts with the Pangolin EDventure.


 


            Alligator, Elephant, Bee, Panda, Shark, Lion, Otter, Hippo, Owl, Dolphin, Koala, Polar Bear, Flamingo, Sloth, Butterfly, Sea Turtle, Tiger, Rhino, Giraffe, Orca, Bat, Zebra, Kangaroo, Penguin 
            
            
             
            
            
            These are all the animal EDventure that we will cover in the 2 year cycle of Cub Club. (Animals are not listed in any particular order)`,
          }
      ];
    const learn = 
        {
            image:"/image/learn.webp",
            title:"Children learn through play and exploration.",
            description:"Cub Club, designed especially for preschoolers, is committed to fostering their love for zoology and the world around them.",
            tick:"/image/icon/cubtick.svg",
            options:[
                {
                    option:"Foster Curiosity About Animals"
                },
                {
                    option:"Develop Fine Motor Skills"
                },
                {
                    option:"Exposure to Letter Forms and Letter Sounds"
                },
                {
                    option:"Build Math Skills and Number Sense "
                },
                {
                    option:"Have FUN! "
                }
            ]
        }
        const help = [
            {
              title:"Educator-Approved Learning Activities",
              description:"Each EDventure is crafted to engage preschoolers, introducing them to foundational preschool concepts with hands-on activities and FUN!",
              image:"/image/helps11.webp"
            },
            {
              class:"reverse",
              title:"Parent-approved learning materials to support early development",
              description:"As parents, it can be challenging to find quality age-appropriate learning experiences, and we are here to make it easy and convenient to discover, explore, and learn together.",
              image:"/image/helps22.webp"
            }
          ]
          const kids = [
            {
              image:"/image/lov1.png"
            },
            {
              image:"/image/lov2.png"
            },
            {
              image:"/image/lov3.png"
            },
            {
              image:"/image/lov4.png"
            },
            {
              image:"/image/lov5.png"
            },
            {
              image:"/image/lov6.png"
            },
            {
              image:"/image/lov7.png"
            },
            {
              image:"/image/lov8.png"
            },
            {
              image:"/image/lov9.png"
            },
            {
              image:"/image/lov10.png"
            },
            {
              image:"/image/lov11.png"
            },
            {
              image:"/image/lov12.png"
            },
          ]
          const secondTab ={
            image:"/image/book.svg",
            heading:"Parent’s Guide",
            headingTab:"Peek Inside a Cub Club EDventure",
            description:"Parenting can be challenging, which is why we've crafted educational guides to make the learning experience with your child easy, seamless, and enriching.",
          guide : [ {
                icon:"/image/icon/ti1.svg",
                title:"Expert Insights",
                desciption:"Discover valuable insights from experts in science and education to enhance intentional play."
            },
            {
                icon:"/image/icon/ti2.svg",
                title:"Home-Based Fun",
                desciption:"Explore simple and effective methods to keep your child engaged in educational play from the comfort of your home."
            },
            {
                icon:"/image/icon/ti3.svg",
                title:"Growth Expectations",
                desciption:"Get a better understanding of what each play kit is supposed to help progress."
            },
        ]}
    return(
        <>
          <Helmet>
        <title>Preschool in a Box: Zoology Subscription for the Youngest Learners</title>
        <meta name="description" content="Our Cub Club Boxes Blend Animals and Learning into the Perfect Preschool Adventure. Seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month!" />
      </Helmet>
           <div className="container">
         <div className={style.banner}>
            <div>
                <img src="/image/icon/cubb.svg" className={style.cubIcon}/>
              <h1>
              Spark a Love for Learning at a Young Age! 
              </h1>
              <p>
              Your preschooler will learn about ABCs, number sense, social skills, life skills and a lot about animals through PLAY!
              </p>
              <div className={style.buttonDis}>
              <Button dynamicClass="fillColor" text="Gift it" link="/gifting-cub-club"/>
             
              </div>
            </div>
            <div>
                <img src="/image/cubban2.webp"/>
        
            </div>
         </div>
     
        </div>
      <div className="container">
      <Learn image={learn.image} title={learn.title} text="Start Gifting" description={learn.description} tick={learn.tick} options={learn.options} color="fillCubColor" link="/gifting-cub-club"/>
      <Tabs box={box} boxTwo={secondTab}/>
      </div>
      <Helps title={
                     <>
                       How <span className={style.cubTitle}>Cub Club</span> helps 3-5 year old children learn
                     </>
                   }
       box={help}/>

        <KidLove color="#EA5443" headerColor="white" kid={kids} title="Kids love us..." titleTwo="Over 100,000 boxes shipped globally...and counting!"/>
        <Testimonials color="#FFE0DD"/>
       
        {/* <StepToggle box={toggleMonthly} plan="planDis" heading={heading} remove="remove"/> */}
        <Trusted/>
        <div className="pt50 pb50 subContainer">
           <FAQ data={faqData}/>
        </div>
        <Newsletter/>
        </>
    )
}

export default CubGiftClub