import React, { useEffect, useRef, useState } from "react";
import style from "./Summer.module.css"
import Newsletter from "../../component/newsletter/Newsletter";
import FAQ from "../../component/faq/Faq";
import KidLove from "../../component/kidLove/kidLove";
import Trusted from "../../component/trusted/Trusted";
import Experience from "../../component/experience/Experience";
import Button from "../../component/button/button";
import KidsTestimonials from "../../component/kidTestimonials/KidTestimonials";
const Summer= () =>{
  const [showMessage, setShowMessage] = useState(false);
  const sectionRef = useRef(null)
  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const toggleMessage = () => {
    setShowMessage(!showMessage); // Toggle showMessage state
  };
  const kids = [
    {
      image:"/image/lov1.png"
    },
    {
      image:"/image/lov2.png"
    },
    {
      image:"/image/lov3.png"
    },
    {
      image:"/image/lov4.png"
    },
    {
      image:"/image/lov5.png"
    },
    {
      image:"/image/lov6.png"
    },
    {
      image:"/image/lov7.png"
    },
    {
      image:"/image/lov8.png"
    },
    {
      image:"/image/lov9.png"
    },
    {
      image:"/image/lov10.png"
    },
    {
      image:"/image/lov11.png"
    },
    {
      image:"/image/lov12.png"
    },
  ]
    const faqData = [
        {
          question: 'What is the recommended age range for My ZOO Box?',
          answer: "We have 2 clubs to meet the needs of a wide range of developmental levels. Cub Club for ages 3-5 focuses on foundational preschool skills in a fun way with animals. Zoologist Club for ages 5-12 focuses on learning about one animal per month and brings STEM, math, life skills, and more to your little zoologist.          ",
        },
        {
          question: 'Can I give My ZOO Box as a gift?',
          answer: 'Of course! Our subscriptions make wonderful gifts! Simply visit our website and make your gift selection (1, 6 or 12-month subscription). Then, provide the details such as the child’s name and shipping address. You can also send other one-time ZOO Shop items as gifts too!',
        },
        {
            question: 'When will my box ship?',
            answer: "Monthly EDventures ship the first 2 weeks of each month. Once an EDventure ships please allow 3-10 business days for delivery to U.S. addresses and 7-14 business days for addresses outside the U.S. Orders placed by the last day of the month will receive the next month's box. Orders placed in February are for March's Box, etc.",
          },
          {
            question: 'How much does shipping cost?',
            answer: (
              <p>All subscriptions include free shipping to anywhere in the United States, including Hawaii, Alaska, and Military (APO, FPO, DPO) addresses.
              <br/>
              Monthly subscriptions can also be shipped to Canada with an additional shipping fee.
  <br/>
  Shipping for The ZOO Store is $7.95 for USA customers and $12.95 for Canadian customers.
  <br/>
           
              </p>
            )
          },
          {
            question: 'How much does My ZOO Box cost?',
            answer: 'Monthly subscriptions are $37.95 per month for most clubs. The Zoologist Club Mini Zoo is $23.95. You can receive a discount on the per-EDventure price by signing up for a 6, or 12-month subscriptions.      ',
          },
        ];

        const plan = [
            {
                icon:"/image/iic1.svg",
                title:"Choose your expedition theme                ",
                description:"Pick from two AWESOME themes that consists of three unique EDventures that are tailor made for your Zoologist!"
            },      {
                icon:"/image/iic2.svg",
                title:"Delivered to YOU!",
                description:"Get your animal learning EDventures delivered to your doorstep in bulk! "
            },    {
                icon:"/image/iic3.svg",
                title:"Start your EDventure",
                description:"Build projects and explore more with DIYs, videos, and printables at home.  "
            },
        ]

        const experience = [
            {
                club:"Cub Club",
                icon:"/image/icon/cubb.svg",
                image:"/image/all.png",
                age:"3-5",
                listOne:[
                    {
                        list:'Zoo Quality Stuffed Animal',
    
                    },{
                        list:"Anatomy Poster & Coloring Sheet"
                    },
                    {
                        list:"Continent Map"
                    },{
                        list:"Social Emotional Learning Postcard"
                    },
                    {
                        list:"Process Art & STEM Idea Extensions"
                    },
                    {
                      list:"QR Preschool Math Printables"
                  }
                ]
                ,
                listTwo:[
                    {
                        list:'Exclusive Book',
    
                    },{
                        list:"Math + Literacy Games & Activities"
                    },
                    {
                        list:"Collectible Sticker"
                    },{
                        list:"Snack & Sensory Recipe"
                    },
                    {
                        list:"Dramatic Play Surprise"
                    },
                    {
                      list:"Parent’s Guide"
                  }
                ],
                button:"Get Cub Club",
                link:"/cub-plan"
            },
            {
                club:"Zoologist Club",
                icon:"/image/icon/zooo.svg",
                image:"/image/boxSk.png",
                age:"5-12",
                listOne:[
                    {
                        list:'Zoo Quality Stuffed Animal',
    
                    },{
                        list:"Anatomy Poster & Coloring Sheet"
                    },
                    {
                        list:"STEM Project and Extentions Ideas"
                    },{
                        list:"Postcard from the Animal"
                    },  {
                        list:"QR Unit Study Printables"
                    },
                    {
                      list:"Informational Science Book"
                    }
                ]
                ,
                listTwo:[
                    {
                        list:"Themed Art Project with Supplies"
                    },
                    {
                      list:"Themed or Authentic Cultural Recipe"
                  },
                    {
                        list:"Collectible Sticker"
                    },{
                        list:"The Signature Zoo Passport"
                    },{
                        list:"Special Surprise Item"
                    }
                ],
                button:"Get Zoologist Club",
                link:"/zoologist-plan"
            }
        ]
        const box = [
          {
            image:"/image/vv1.png",
            title:"Stuffed Animal",
            description:"Each Zoologist Club EDventure brings a stuffy that will promote emotional development, social interaction and provide a sense of security to your little Zoologist."
        },
        {
            image:"/image/vv2.png",
            title:"Exclusive Science Book",
            description:"Provides the perfect blend of non-fiction knowledge and fun. Written by the founding team at My ZOO Box, and approved by real-life Zoologists."
        },
        {
            image:"/image/vv3.png",
            title:"Anatomy Poster & Worksheet",
            description:"Writing, art, biology are all subjects infused within the Anatomy poster & worksheep - it’s one of the most-popular items of the Zoologist Club EDventure."
        },
        {
            image:"/image/vv4.png",
            title:"STEM Project",
            description:"Science is an integral part of learning for children of this age group. Each box comes with a hands-on STEM project that revolves around the animal of the month."
        },
        {
            image:"/image/v5.png",
            title:"Themed Art Project with Supplies",
            description:"To add to the fun learning, each EDventure comes with a themed art project that adds the right amount of creativity to this learning experience."
        },
        {
            image:"/image/v6.png",
            title:"Surprise Item",
            description:"A Zoologist Club special that remains a surprise for all members. We like to keep this one a secret so that we don’t spoil the fun for our treasured community."
        },
        {
            image:"/image/vv88.png",
            title:"Postcard from the Animal",
            description:"The postcard, written from the perspective of animal, is a great way to help kids build a  connection to the animal, as well as to wildlife in general. "
        },
        {
          image:"/image/vv7.png",
          title:"Collectible Sticker",
          description:"The collectible sticker complements the Zoo Passport and makes learning about the animal kingdom an immersive experience. Each EDventure will feature a unique sticker that the little Zoologists can stick on to the passport."
      },
      {
        image:"/image/vv8.png",
        title:"The Zoo Passport",
        description:"Your first box will include the Zoo Passport which will turn into a memory book of all the EDventures that the little Zoologist has explored throughout their My ZOO Box journey!"
      },
      {
        image:"/image/v10.png",
        title:"Themed or Authentic Recipe",
        description:"We believe the kitchen is the ultimate classroom for life skills and practical math. That's why we include two delectable snack recipes in every box."
      },
      {
      image:"/image/vv11.png",
      title:"QR Printables",
      description:"To add-on to the learning, each EDventure comes with additional activities to extend the learning for the curious little Zoologists."
      },
      ]

      useEffect(() => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
          window.subblyConfig = {
              apiKey: '52d2a9b4-a884-42cb-83a9-cfb67f347926',
              settings: {
                  interceptProductLinks: true,
                  cartCounterEl: '.subbly-cart-product-count',
                  cartToggleEl: '.subbly-cart',
                  cartButton: true,
              },
          };
        `;
        document.body.appendChild(script);
    
        const subblyScript = document.createElement('script');
        subblyScript.type = 'module';
        subblyScript.src = 'https://assets.subbly.co/cart/cart-widget.js';
        document.body.appendChild(subblyScript);
    
        return () => {
          document.body.removeChild(script);
          document.body.removeChild(subblyScript);
        };
      }, []);
    
    return(
        <>
      <div className={style.summerContainer}>
        
      <div className={`container ${style.summerBanner}`} onClick={scrollToSection}>
            <img src="/image/summerd.webp" alt="banner"/>
            <img src="/image/summerm.webp" alt="banner"/>
        
     
        </div>
       
            <div className={`container ${style.summerWorks}`}>
            {/* <h2>How it Works</h2> */}
             <div className={style.summerDis}>
             {plan.map((item =>{
                return(
                    <div>
                        <img src={item.icon} alt="icon"/>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                )
            }))}
             </div>
            </div>
            </div>
        <div className={style.expedition}>
            <div className="container">
              <div className={style.expeditionHeader} ref={sectionRef}>
              <h2>Choose your expedition</h2>
                <p>Select the theme you want your little Zoologist and prepare them for the BEST. SUMMER. EVER.</p>
                <div>
                    <img src="/image/flamee.svg" alt="#" className={style.campIcon}/>
                    <img src="/image/icon/zooo.svg" className={style.zooIcon}/>
                    <span>Designed for</span>
                    <p>Ages 5-12</p>
                </div>
              </div>

           
            </div>
            <div className={style.campContainer}>
             <div className={style.expeditionBoxes}>
                <div className={style.campDis}>
                  <div className={style.campImage}>
                    
                     <img src="/image/rainheading.png" className={style.campTitle}/>
                     <div className={style.campPrice}>
                          <p>Bundle Price</p>
                       <div>
                       <span>$119.97</span>
                       <h2>$99</h2>
                       </div>
                     </div>
                     <a href="https://checkout.myzoobox.com/checkout/buy/305871 " alt="#">Add to Cart <img src="/image/icon/arrow.svg" alt="arrow"/></a>
                   
                     {/* <img src="/image/rainbadge.png"  className={style.campBadge}/> */}
                     <img src="/image/lp.png" className={style.lmOut}/>
                  </div>
                  <div className={style.campBoxes}>
                    <h3>What EDventures to expect:</h3>
                    <div className={style.campBoxOne}>
                      <img src="/image/org.png"/>
                      <img src="/image/orantext.png"/>
                    </div>
                    <div className={style.campBoxTwo}>
                      <img src="/image/cml.png"/>
                      <img src="/image/chamtext.png"/>
                    </div>
                    <div className={style.campBoxThree}>
                      <img src="/image/lem.png"/>
                      <img src="/image/lemtext.png"/>
                    </div>
                    </div>
                </div>

                <div className={style.campDis}>
                  <div className={`${style.campImage } ${style.oceanImage }`}>
                     <img src="/image/oceanheading.png" className={style.campTitle}/>
                     <div className={style.campPrice}>
                          <p>Bundle Price</p>
                       <div>
                       <span>$119.97</span>
                       <h2>$99</h2>
                       </div>
                     </div>
                     <a href="https://checkout.myzoobox.com/checkout/buy/305869" alt="#">Add to Cart <img src="/image/icon/arrow.svg" alt="arrow"/></a>
                     {/* <img src="/image/oceanbadge.png"  className={style.campBadge}/> */}
                     <img src="/image/seal.png" className={style.lmOut}/>
                  </div>
                  <div className={`${style.campBoxes } ${style.oceanBoxes }`}>
                  <h3>What EDventures to expect:</h3>

                    <div className={style.campBoxOne}>
                      <img src="/image/hamm.png"/>
                      <img src="/image/hamt.png"/>
                    </div>
                    <div className={style.campBoxTwo}>
                      <img src="/image/se.png"/>
                      <img src="/image/seat.png"/>
                    </div>
                    <div className={style.campBoxThree}>
                      <img src="/image/ho.png"/>
                      <img src="/image/hot.png"/>
                    </div>
                    </div>
                </div>
             </div>
             <p className={style.bottomLabel}>Want to learn more about what each EDventure unfolds? Take a peek inside a sample EDventure from our subscription line!</p>

             </div>
        </div>
        <div className={style.showTab}>
        <div className={style.summerTab} onClick={toggleMessage}>
          <p>Peek Inside a Sample Zoologist Club EDventure</p>
          <img src="/image/icon/bl.svg" className={`${style.rotate} ${showMessage ? style.active :""}`}/>

          </div>
          {showMessage && (
          <div className="container">
          <div className={`pb50 pt50 ${style.edventure}`}>
            {box.map((item,i) =>{
                return(
                    <div className={style.edventureBox} key={i}>
                    <img src={item.image}/>
                    <div>
                        <h3 className="ptb5">{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                </div>
                )
            })}
      
      
        </div>
        
          </div>
           )}
        </div>
      <Trusted/>
      <KidsTestimonials />
      <div className={style.shopLetter}>
           <Newsletter/>
           </div>
<div className="pt50 pb50 subContainer">
              <FAQ data={faqData} />
            </div>
                

        </>
    )
}

export default Summer