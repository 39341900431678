import React from "react";
import style from "./button.module.css"
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
const Button = (props) => {
    return(
        <Link  onClick={() => animateScroll.scrollToTop()} to={props.link} className={style[props.dynamicClass]}>{props.text} {props.yellow === "yellow" ? <img src="/image/icon/yellow.svg" className={style.yellow}/> : <img src="/image/ra.png"/>}</Link>
    )
}

export default Button