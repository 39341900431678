import React from "react";
import style from "./GiftMyZoo.module.css"

const GiftMYzoo = () =>{
    const circleBox =[
        {
            image:"/image/educ.webp",
            title:"Educator-Approved Learning",
            description:'Hands-on STEM activities and open-ended play designed by educators and zoologists to reinforce learning.',
        },
        {
            image:"/image/ed3.webp",
            title:"Parent-Approved Activities",
            description:'All-in-one boxes with hours of fun activities to bond with your children over. Lots of multi-subject learning resources!',
        },
        {
            image:"/image/ed2.webp",
            title:"Kid-Approved Fun",
            description:'Kids love the games, toys, and activities they can do with their grown-ups. While all the activites have learning integrated, they feel like the play that kids love.',
        },
       
    ]
    return(
        <div className={`pt50 pb50 ${style.giftMyZoobck}`}>
            <div className="thirdContainer">
                <h1>Why Gift My ZOO Box?</h1>
                <p className="pb15">Play, learn, and explore while roaring with lions, swinging with monkeys,<br/> and discovering the depths of the ocean.</p>

                <div className={style.giftZooDis}>
                    {circleBox.map((item)=>{
                        return(
                            <div>
                            <img src={item.image}/>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                        )
                    })}
                
                </div>
            </div>
        </div>
    )
}


export default GiftMYzoo;