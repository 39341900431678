import React, { useRef } from "react";
import style from "./Clubs.module.css"
import Button from "../button/button";
const Clubs = (props) =>{
     // Get the current date
  const currentDate = new Date();
  
  // Add one month to the current date
  const nextMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

  // Get the name of the next month
  const nextMonth = nextMonthDate.toLocaleString('default', { month: 'long' });

  // const [open,setOpen] = useState(false)
    const sectionRef = useRef(null);
    return(
        <div className={style.clubsExplore} ref={props.section}>
             <div className={` subContainer ${style.clubsHeader}`}>
            <h1>Explore the <br/>My ZOO Box Clubs</h1>
  
            <p className="pb15">Start your EDventure by picking one of the clubs:</p>
            <div className={` ${style.clubs}`}>
                <div className={style.clubsBox}>
                    <div>
                        <img src="/image/prof1.webp" className={style.profile}/>
                        <span>3-5 Year Olds</span>
                        <h3>Cub Club</h3>
                        <p>Your preschooler will learn about ABCs, number sense, social skills, life skills and a lot about animals through PLAY!</p>
                        <Button dynamicClass="fillColor" text="Learn More" link="/cub-club" />
                    </div>
                </div>
                <div className={`${style.clubsBox} ${style.zooClub}`}>
                    <div>
                        <img src="/image/prof2.webp" className={style.profile}/>
                        <span>5-11 Year Olds</span>
                        <h3>Zoologist Club</h3>
                        <p>Fuel your little Zoologists love of animals and learning with STEM and life skill focused hands-on activities and FUN!</p>
                        <Button dynamicClass="fillColor" text="Learn More" link="/zoologist-club"/>
                    </div>
                </div>
            </div>
        </div>
        <h4 className={style.firstBox}>Your first box ships the first two weeks of  <span>{nextMonth}</span></h4>
        </div>
       
    )
}
export default Clubs