import React, { useState } from "react";
import style from "./Contact.module.css"
import { Helmet } from "react-helmet";

const Contact = () =>{
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    const [submit,setSubmit] = useState(true)
      const [errors, setErrors] = useState({});
    const firstName = document.querySelector("#fullname")
    const firstEmail = document.querySelector("._x49959923  input")
    const firstPhone = document.querySelector("._x08657742 input")
    const firstMessage = document.querySelector("._x86516003 textarea")
    const submitBtn = document.querySelector("#_form_23_submit")
      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.id]: e.target.value
        });
      };
    console.log("dasdasdasdaF",formData)
      const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
          // Form submission logic goes here
          console.log('Form submitted successfully');
          firstName.value = formData.name.trim()
          firstEmail.value = formData.email
          firstPhone.value = formData.phone
          firstMessage.value = formData.message.trim()
          submitBtn.click()
          setSubmit(false)
        } else {
          setErrors(errors);
        }
      };
    
      const validateForm = (data) => {
        const errors = {};
        if (!data.name.trim()) {
          errors.name = 'Name is required';
        }
        if (!data.email.trim()) {
          errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
          errors.email = 'Email is invalid';
        }
        if (!data.phone.trim()) {
          errors.phone = 'Phone is required';
        }
        if (!data.message.trim()) {
          errors.message = 'Message is required';
        }
        return errors;
      };
    
    return(
          <div className={style.contactFullBck}>
             <Helmet>
        <title> Kids’ Zoology Subscription Boxes | My ZOO Box</title>
        <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
      </Helmet>
            <div className={style.contactBck}>
            <div className="container">
                <div className={style.contactHeader}>
                    <img src="/image/rec.webp"/>
                    <h1>Contact Us</h1>
                </div>

                
            </div>
        </div>
         <div className={style.contactFormBck}>
         <div className={`container ${style.contactDis}`}>
            <div>
                <h3>Help us understand how we can help!</h3>
                <p>Please contact us for any reason. Our goal is to make
everyone happy! We always welcome ideas, suggestions and
feedback. Whether you're a subscriber, a partner, or simply 
interested in finding out more - get in touch!
</p>
<br/>
<p>EMAIL: support@myzoobox.com</p>
<div className={style.socials}>
    <h2>Follow us</h2>
               <div>
               <a href="https://www.facebook.com/zooboxfun" target="_blank"><img src="/image/icon/fac.svg"/></a>
                 <a href="https://www.instagram.com/zooboxfun/" target="_blank"> <img src="/image/icon/ins.svg"/></a>
               
                {/* <img src="/image/icon/twit.svg"/> */}
               <a href="https://www.youtube.com/channel/UCZ9u_73Dv9VJwL4gjzyBZlA" target="_blank">  <img src="/image/icon/you.svg"/></a>
               </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
      <div  className={submit ? style.showMessage : style.hideMessage}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <p className={style.error}>{errors.name}</p>}
      </div>
      <div  className={submit ? style.showMessage : style.hideMessage}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
        />
        {errors.email && <p className={style.error}>{errors.email}</p>}
      </div>
      <div  className={submit ? style.showMessage : style.hideMessage}>
        <label htmlFor="phone">Phone</label>
        <input
          type="text"
          id="phone"
          value={formData.phone}
          onChange={handleChange}
        />
        {errors.phone && <p className={style.error}>{errors.phone}</p>}
      </div>
      <div  className={submit ? style.showMessage : style.hideMessage}>
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          value={formData.message}
          onChange={handleChange}
        />
        {errors.message && <p className={style.error}>{errors.message}</p>}
      </div>
      <button type="submit"  className={submit ? style.showMessage : style.hideMessage}>Submit</button>
    <h1 className={`${style.submitMessage} ${submit ? style.hideMessage : style.showMessage}`}>Thank you for reaching out! We'll get back to you soon.😊</h1>
    </form>

    <div class="_form_23"></div>
         </div>
         </div>
        </div>
    )
}

export default Contact