import React, { useState } from "react";
import style from "./Tabs.module.css";
import EDventure from "./EDventure";
import Guide from "./Guide";

const Tabs = (props) => {
  const [active, setActive] = useState(0);

  const handleTabClick = (index) => {
    setActive(index);
  };

  const tabs = [
    <EDventure box={props.box}/>,
    <Guide boxTwo={props.boxTwo}/>
  ]
  return (
    <div className={style.tabs}>
      <div className={`${style.tabsDis} ${props.boxTwo.remove === "remove" ? style.zooColor : style.cubColor }`}>
        <p
          className={active === 0 ? style.active : ""}
          onClick={() => handleTabClick(0)}
        >
          {props.boxTwo.headingTab}
        </p>
        <p
          className={active === 1 ? style.active : ""}
          onClick={() => handleTabClick(1)}
        >
          Parent’s Guide
        </p>
        <span  className={active === 0 ? style.activeSpan : style.activeSpanTwo}
         ></span>
      </div>

      <div className={style.showTab}>{tabs[active]}</div>
    </div>
  );
};

export default Tabs;
