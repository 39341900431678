import React from "react";
import style from "./Tabs.module.css"

const EDventure = (props) =>{
  
    return(
        <div className={`pb50 pt50 ${style.edventure}`}>
            {props.box.map((item,i) =>{
                return(
                    <div className={style.edventureBox} key={i}>
                    <img src={item.image}/>
                    <div>
                        <h3 className="ptb5">{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                </div>
                )
            })}
      
        </div>
    )
}

export default EDventure;