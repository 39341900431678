import React from "react";
import style from "./Fan.module.css"
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
const Fan = (props) =>{
    return(
        <div className={style.fan}>
            <div className={style.fanHeading}>
                <span>Want More?</span>
                <h2>Discover the One-Time Boxes from the ZOO Store</h2>
                
            </div>
            <div className={style.fanBoxes}>
                <div className={style.boxesHeading}>
                  <h2>Fan Favorites</h2>
                  <Link to="/zoo-store"  onClick={() => animateScroll.scrollToTop()}>View All</Link>
                </div>
                {/* <div className={style.fanBoxDis}>
                    {props.boxes.map((item =>{
                        return(
                            <div className={style.box}>
                            <span className={style.stock}>In Stock!</span>
                            <img src={item.image}/>   
                            <div>
                                <h4>{item.name}</h4>
                                <p>{item.price}</p>
                            </div>
                            <Link to="/legacy-zoo-store"  onClick={() => animateScroll.scrollToTop()}>Add To Cart</Link>
                        </div>
                        )
                    }))}
                </div> */}
                  <div className={style.storeDis}>
          {props.boxes.map((item =>{
            return(
              <div>
              <img src={item.image}/>
              <h3>{item.name}</h3>
              <div className={style.dicount}>
              <p>$39.95</p>
              <i className={style.stock}>{item.stock}</i> 
                </div>
              {/* {item.stock ?
                 <div className={style.dicount}>
              <span>$39.95</span>
              <p>{item.price}</p>
              <i className={style.stock}>{item.stock}</i>             </div>:   
              <p>$39.95 <i>In Stock</i></p>
              
              } */}
              <a href={item.cart}>Add to Cart</a>
            </div>
            )
          }))}
          
        </div>
            </div>
        </div>
    )
}

export default Fan;