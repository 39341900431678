import React, { useEffect, useState } from "react";
import Subbly from '@subbly/sdk';







const SubblyApi = () =>{
    const[subbly, setSubbly] = useState()
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1)
  
    const fetchProducts = async (page, perPage=20, subbly) => {
      try {
        if(subbly){
          // Fetch products using the initialized subbly object
          const products = await subbly.products.list({
            type : "one_time",
            expand: ['pricings', 'variants', 'pricings.parent', 'variants.parent'],
            page: page,
            perPage: 100
          });
  
          console.log("++++++++++++++++")
          console.log(products)
          // Set the fetched products to state
          setProducts(products);
        }
        
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
  
    useEffect(() => {
      const initializeSubbly = async () => {
        try {
  
          const subbly = await Subbly.init({
            apiKey: "52d2a9b4-a884-42cb-83a9-cfb67f347926"
          });
          // You can access Subbly SDK methods using the 'subbly' object
          console.log('Subbly initialized:', subbly);
          setSubbly(subbly)
        } catch (error) {
          console.error('Subbly initialization error:', error);
        }
      };
  
      initializeSubbly();
    }, []);
  
    useEffect(() => {
      fetchProducts(page, 20, subbly);
    }, [subbly,page]);
    return(
        <>
        </>
    )
}

export default SubblyApi;