// ShippingInfo.js
import React from 'react';
import styles from './iconCards.module.css';

const ShippingInfo = () => {
  const cards = [
    {
      text: 'Free Shipping Over $99 for USA',
      bgColor: '#00aa5f',
      icon: '/image/i1.png',
    },
    {
      text: 'Returns in 30 Days',
      bgColor: '#fe3c57',
      icon: '/image/i22.png',
      
    },
    {
      text: 'Ships within 5 Business Days',
      bgColor: '#fce966',
      icon: '/image/i2.png',
      class:"changeColor"
    },
  ];

  return (
    <div className={styles.container}>
      {cards.map((card, index) => (
        <div
          key={index}
          className={`${styles.card} ${styles[card.class]}`}
          style={{ backgroundColor: card.bgColor }}
        >
          <div className={styles.iconContainer}>
            <img src={card.icon} alt="Icon" />
          </div>
          <p className={styles.text}>{card.text}</p>
        </div>
      ))}
    </div>
  );
};

export default ShippingInfo;
