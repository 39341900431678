import React from "react";
import migration from "../../assets/migration.gif"
import Button from "../../component/button/button";
import style from "./Missing.module.css"
import { Helmet } from "react-helmet";
const Missing = () =>{
    return(
        <div className="container">
               <Helmet>
        <title> Kids’ Zoology Subscription Boxes | My ZOO Box</title>
        <meta name="description" content="Discover the wonder of wildlife with My ZOO Box – your monthly subscription box filled seriously fun, engaging, & educational zoology adventures designed by professional educators and real-life zoologists every month! " />
      </Helmet>
                   <div className={style.missing}>
                   <img src={migration} alt="Example GIF"/>
                    <p>This page is currently under maintenance. Please check back later or continue browsing the other working pages on the website!</p>
                    <Button dynamicClass="fillCubColor" text="Get Started" link="/"                               />
                   </div>
        </div>
    )
}

export default Missing